import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import {LocalStorage} from "../localstorage/LocalStorage";

export class I18nUtil {
    static initI18n(resources: any) {
        i18n.use(initReactI18next)
            .init({
                resources: resources,
                lng: LocalStorage.getLangCode(),
                keySeparator: '.',
                interpolation: {
                    escapeValue: false
                }
            });
        return i18n;
    }

    static chgLang(langCode: string) {
        i18n.changeLanguage(langCode).then();
        LocalStorage.setLangCode(langCode);
    }

    static t(key: string): string {
        return i18n.t(key, {ns: 'common'});
    }

    static parse(origin: I18nResource) {
        const resource: any = {};
        const ns = "common";

        for (let nsKey in origin) {
            const namespace = origin[nsKey];

            for (let key in namespace) {
                const value = namespace[key];

                for (let langCode in value) {
                    const str = value[langCode];

                    if (!resource.hasOwnProperty(langCode)) {
                        resource[langCode] = {};
                    }

                    if (!resource[langCode].hasOwnProperty(ns)) {
                        resource[langCode][ns] = {};
                    }

                    if (!resource[langCode][ns].hasOwnProperty(nsKey)) {
                        resource[langCode][ns][nsKey] = {}
                    }

                    resource[langCode][ns][nsKey][key] = str;
                }
            }
        }



        return resource;
    }
}


export interface I18nResource {
    [namespace: string]: I18nNamespace
}

export interface I18nNamespace {
    [key: string]: I18nValue
}

export interface I18nValue {
    [langCode: string]: string
}
