import React, {Fragment} from "react";
import styles from './TelBookSummaryInputUserInfo.module.scss'
import classNames from "classnames";
import {BizPeriodPicker} from "../../../shares/bizPeriodPicker/BizPeriodPicker";
import {RequestInputValue} from "../TelBookSummary";
import {OnChangeValue, OnVoidFunction} from "../../../shares/interfaces/interfaces";

export const TelBookSummaryInputUserInfo = (props: {
    value: RequestInputValue,
    onChangeValue: OnChangeValue<RequestInputValue>,
    onFindUserId: OnVoidFunction,
    onRequestWithUserIdAndPeriod: OnVoidFunction,
}) => {
    return (
        <Fragment>
            <div className={classNames(styles.table, styles.tableUserInfo)}>
                <div className={classNames(styles.header, styles.colLabel)}>유저 아이디</div>
                <div className={classNames(styles.col, styles.colInput)}>
                    {props.value.userId || '이메일 검색을 실행하여 주십시오.'}
                </div>
            </div>

            <form
                onSubmit={ev => {
                    ev.preventDefault();
                    props.onFindUserId();
                }}
                className={classNames(styles.table, styles.tableUserInfo)}>
                <div className={classNames(styles.header, styles.colLabel)}>이메일</div>
                <div className={classNames(styles.colInput)}>
                    <input
                        value={props.value.email}
                        className='form-control form-control-sm'
                        onChange={ev => props.onChangeValue({...props.value, email: ev.target.value})}/>
                </div>
                <div className={styles.colSpace}>
                    <button
                        className='btn btn-sm btn-outline-success'>
                        찾기
                    </button>
                </div>
            </form>

            <div className={classNames(styles.table, styles.tableUserInfo)}>
                <div className={classNames(styles.header, styles.colLabel)}>기간</div>
                <div className={classNames(styles.colPeriodPicker)}>
                    <BizPeriodPicker
                        period={props.value.period}
                        onChange={value => props.onChangeValue({...props.value, period: value})}/>
                </div>
            </div>
            <div className={classNames(styles.table, styles.tableUserInfo)}>
                <div className={classNames(styles.colBtn)}>
                    <button
                        onClick={props.onRequestWithUserIdAndPeriod}
                        disabled={!Boolean(props.value.userId)}
                        className='btn btn-sm btn-success'>추출
                    </button>
                </div>
            </div>


        </Fragment>
    )
}
