import React from 'react';
import classNamesBind from "classnames/bind";
import styles from './BizMonthPicker.module.scss';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker, {registerLocale} from "react-datepicker";
import ko from 'date-fns/locale/ko';

registerLocale('ko', ko);

const cx = classNamesBind.bind(styles);

export const BizMonthPicker = (props: {
    date: Date,
    onChange: (date: Date) => void
}) => {
    return (
        <>
            <DatePicker
                className='form-control form-control-sm text-center'
                wrapperClassName={cx('date-picker')}
                locale={'ko'}
                dateFormat={"yyyy/MM"}
                selected={props.date}
                showMonthYearPicker={true}
                popperPlacement={"bottom-end"}
                onChange={date => {
                    if (date instanceof Date) {
                        props.onChange(date);
                    }
                }}/>
        </>
    )
}