import React, {useEffect, useState} from "react";
import classNamesBind from "classnames/bind";
import styles from './MngUserViewInfo.module.scss'
import {Title} from "../../../shares/title/Title";
import {BadgeIsActivate} from "../../../shares/badgeIsActivate/BadgeIsActivate";
import {Switch} from "@material-ui/core";
import {Pipe} from "../../../../utils/pipe/pipe";
import {User} from "../../../../graphql/types";
import {OnChangeValue} from "../../../shares/interfaces/interfaces";
import {CopyUtil} from "../../../../utils/copy/CopyUtil";

const cx = classNamesBind.bind(styles);

export const MngUserViewInfo = (props: {
    user: User,
    onChangeUserActivate: OnChangeValue<boolean>,
    onUpdateUserMemo: OnChangeValue<string>,
}) => {
    const [input, setInput] = useState<{
        memo: string
    }>({
        memo: props.user.memo,
    })

    useEffect(() => {
        setInput({memo: props.user.memo})
    }, [props.user]);

    const handler = {
        onUpdateUserMemo: () => {
            props.onUpdateUserMemo(input.memo);
        }
    }

    return (
        <>
            <Title>유저정보</Title>
            <div className='row m-0'>
                <div className='col-12 col-lg-6'>
                    <div className={cx('table')}>
                        <div className={cx('col', "itemCell")}>이메일</div>
                        <div className={cx('col', "valueCell")}>{props.user.email}</div>
                    </div>
                    <div className={cx('table')}>
                        <div className={cx('col', "itemCell")}>활성화</div>
                        <div className={cx('col', "valueCell")}>
                            <div className={cx("badgeCell")}>
                                <BadgeIsActivate isActivate={props.user.isActivate}/>
                            </div>
                            <div className={cx("activateCell")}>
                                <Switch
                                    checked={props.user.isActivate}
                                    onChange={(event, checked) => {
                                        props.onChangeUserActivate(checked);
                                    }}
                                    color="primary"
                                />
                            </div>
                        </div>
                    </div>

                    <div className={cx('table')}>
                        <div className={cx('col', "itemCell")}>잔고</div>
                        <div className={cx('col', "valueCell")}>
                            {Pipe.toSeparatorNumber(props.user.point)} P
                        </div>
                    </div>
                </div>
                <div className='col-12 col-lg-6'>
                    <div className={cx('table')}>
                        <div className={cx('col', "itemCell")}>메모</div>
                        <div className={cx('col', "valueCell")}>
                            <textarea
                                value={input.memo}
                                className={cx("textBox")}
                                style={{}}
                                onChange={event => {
                                    const copy = CopyUtil.copyAll(input);
                                    copy.memo = event.target.value;
                                    setInput(copy);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className={cx('table')}>
                <div className='text-right' style={{width: '100%'}}>
                    <button
                        className='btn btn-sm btn-success'
                        onClick={handler.onUpdateUserMemo}>저장
                    </button>
                </div>
            </div>

        </>

    )
}
