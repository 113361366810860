import React, {Fragment, ReactNode} from "react";
import {BoxCont} from "../boxCont/BoxCont";

export const BizCol12 = (props: {
    children: ReactNode
}) => {
    return (
        <div className='col-12' style={{marginBottom: 30}}>
            <BoxCont>
                {props.children}
            </BoxCont>
        </div>
    )
}
