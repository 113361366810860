import React, {useState} from 'react';
import {SwalUtil} from "../../../utils/swal/swalUtil";
import {LoadingViewUtil} from "../../App";
import {FB} from "../../../utils/fb/FB";
import gql from "graphql-tag";
import {useMutation} from "@apollo/client";
import {navigate} from "hookrouter";
import {AuthJoinView} from "./view/AuthJoinView";

export const AuthJoin = () => {
    const [state, setState] = useState<AuthJoinState>({
        email: "",
        pw: "",
        confirm: "",
    });
    const [mutJoin] = useMutation<{ join: boolean }>(gqlJoin);

    const func: AuthJoinFunc = {
        setState: s => {
            setState(s)
        },
        join: () => {
            if (!state.pw) {
                SwalUtil.ok({
                    msg: "비밀번호를 입력하여 주십시오.",
                    icon: "error"
                })
                return;
            }

            if (state.pw !== state.confirm) {
                SwalUtil.ok({
                    msg: "두 비밀번호가 틀립니다.",
                    icon: "error"
                })
                return;
            }

            LoadingViewUtil.loading(
                FB.join({email: state.email, password: state.pw})
                    .then(uid => {
                        if (!uid) {
                            SwalUtil.ok({
                                msg: '회원가입 실패',
                                icon: 'error'
                            })
                        }
                        return mutJoin({variables: {uid}})
                    })
                    .then(res => {
                        SwalUtil.ok({
                            msg: '회원가입 되었습니다.',
                            icon: 'success'
                        })
                        navigate('/');
                    })
                    .catch()
            );
        }
    }

    return (
        <AuthJoinView
            state={state}
            func={func}
        />
    )
}

export interface AuthJoinState {
    email: string;
    pw: string;
    confirm: string;
}

export interface AuthJoinFunc {
    setState: (state: AuthJoinState) => void;
    join: () => void;
}

const gqlJoin = gql`
    mutation Join($uid: String!) {
        join(uid: $uid)
    }
`
