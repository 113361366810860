import {I18nResource} from "../utils/i18n/i18n";
import {Dashboard} from "./dashbaord";
import {Layout} from "./layout";
import {Auth} from "./auth";
import {Swal} from "./swal";
import {SMS} from "./sms";
import {Result} from "./result";
import {Request} from "./request";
import {CS} from "./cs";

export const I18nRes: I18nResource = {
    dashboard: Dashboard,
    layout: Layout,
    auth: Auth,
    swal: Swal,
    sms: SMS,
    result: Result,
    request: Request,
    cs: CS,
}
