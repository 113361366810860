import {createActionCreators, createReducerFunction, ImmerReducer} from "immer-reducer";
import {Admin} from "../graphql/types";


export interface UserState {
    isLogin: boolean;
    admin: Admin
}

const initState: UserState = {
    isLogin: false,
    admin: {
        id: 0,
        email: ""
    },
};

class User extends ImmerReducer<typeof initState> {
    login(admin: Admin) {
        this.draftState.isLogin = true;
        this.draftState.admin = admin;
    }

    logout() {
        this.draftState.isLogin = false;
        this.draftState.admin = {
            id: 0,
            email: ""
        };
    }
}


export const UserAction = createActionCreators(User);
export const UserReducer = createReducerFunction(User, initState);

