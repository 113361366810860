import React, {useEffect, useState} from 'react';
import {gql, useMutation, useQuery} from "@apollo/client";
import {
    Mutation,
    MutationCreateUserCategoryArgs,
    MutationUpdateUserCategoryArgs,
    Query,
    UserCategory
} from "../../../graphql/types";
import {FadeInContainer} from "../../shares/fadeInContainer/FadeInContainer";
import {BizCol12} from "../../shares/bizCol12/BizCol12";
import {BizTitle} from "../../shares/title/Title";
import {TelBookCategoryList} from "./list/TelBookCategoryList";
import {SwalUtil} from "../../../utils/swal/swalUtil";
import {ApolloCatch} from "../../../utils/apollo/apolloExec";

export type InputUserCategory = (UserCategory & { isChanged: boolean })[];

export const TelBookCategory = (props: {}) => {
    const {data, loading, error, refetch} = useQuery<Query, {}>(gqlData);
    const [input, setInput] = useState<InputUserCategory>([]);
    const [mutUpdate] = useMutation<Mutation, MutationUpdateUserCategoryArgs>(gqlUpdate);
    const [mutCreate] = useMutation<Mutation, MutationCreateUserCategoryArgs>(gqlCreate);

    useEffect(() => {
        if (!data) {
            return;
        }

        const nextInput: InputUserCategory = [];
        for (let userCategory of data.userCategory) {
            nextInput.push({
                ...userCategory, isChanged: false,
            })
        }

        setInput(nextInput);
    }, [data])

    const handler = {
        onSave: () => {
            for (let elem of input) {
                if (elem.nm === "") {
                    SwalUtil.ok({
                        msg: '이름이 없는 데이터가 있습니다.',
                        icon: 'error'
                    });
                    return;
                }
            }

            handler.onTransfer()
                .then(() => {
                    return refetch()
                })
                .then(() => {
                    SwalUtil.ok({
                        msg: '저장 되었습니다.',
                        icon: 'success'
                    })
                })
                .catch(ApolloCatch({}));
        },
        onTransfer: async () => {
            for (let elem of input) {
                if (!elem.isChanged) {
                    continue;
                }

                if (elem.id === -1) {
                    await mutCreate({
                        variables: {
                            input: {
                                nm: elem.nm,
                                isActivate: elem.isActivate
                            }
                        }
                    })
                } else {
                    await mutUpdate({
                        variables: {
                            input: {
                                id: elem.id,
                                nm: elem.nm,
                                isActivate: elem.isActivate
                            }
                        }
                    })
                }
            }
        }
    };

    return (
        <>
            {data &&
            <FadeInContainer>
                <BizCol12>
                    <BizTitle title='카테고리 설정'>
                        <button
                            className='btn btn-sm btn-success'
                            onClick={handler.onSave}>저장
                        </button>
                    </BizTitle>
                    <TelBookCategoryList
                        value={input}
                        onChangeValue={setInput}/>
                </BizCol12>
            </FadeInContainer>
            }
        </>
    )
}

const gqlUpdate = gql`
    mutation UpdateUserCategory ($input: UpdateUserCategory!){
        updateUserCategory(input: $input)
    }
`;

const gqlCreate = gql`
    mutation CreateUserCategory($input: CreateUserCategory!) {
        createUserCategory(input: $input)
    }
`;

const gqlData = gql`
    query DATA {
        userCategory {
            id
            nm
            isActivate
        }
    }
`;
