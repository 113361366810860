import React, {useEffect, useState} from 'react';
import classNamesBind from "classnames/bind";
import styles from './MngUserView.module.scss';
import gql from "graphql-tag";
import {useLazyQuery, useMutation, useQuery} from "@apollo/client";
import {
    CreateClientPermission, IssuePoint, Mutation, MutationUpdateUserCouponArgs, Query,
    SendBulkSms,
    SendReport,
    SendTemplateSms,
    SmsLine,
    SmsLineDailyUsage,
    TelBook,
    TelNumber, TelNumberStatus,
    UpdateClientPermission, UpdateUserCoupon, UpdateUserPermission,
    UpdateUserPermissionSmsLine,
    User, UserClientSendReport,
    UserPermission,
} from "../../../graphql/types";
import {Title} from "../../shares/title/Title";
import {Pipe} from "../../../utils/pipe/pipe";
import {ApolloCatch} from "../../../utils/apollo/apolloExec";
import {SwalUtil} from "../../../utils/swal/swalUtil";
import {BizErrors} from "../../../graphql/errors";
import {ModalWeb} from "../../shares/modal/ModalWeb";
import {NumbUtil} from "../../../utils/numbUtil/NumbUtil";
import {LoadingViewUtil} from "../../App";
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar'
import moment from "moment";
import {saveAs} from 'file-saver';
import {ClientPermission} from "./clientPermission/ClientPermission";
import Decimal from "decimal.js";
import {MngUserViewApiKey} from "./apiKey/MngUserViewApiKey";
import {BizCol12} from "../../shares/bizCol12/BizCol12";
import {MngUserViewInfo} from "./info/MngUserViewInfo";
import {MngUserViewIssuePoint} from "./issuePoint/MngUserViewIssuePoint";
import {MngUserViewModalApiSmsLine} from "./modalApiSmsLine/MngUserViewModalApiSmsLine";
import {FadeInContainer} from "../../shares/fadeInContainer/FadeInContainer";
import {MngUserViewCoupon} from "./coupon/MngUserViewCoupon";
import ScrollArea from "react-scrollbar";
import {useSelector} from "react-redux";
import {LayoutState} from "../../../redux/Layout";

const cx = classNamesBind.bind(styles);

export const MngUserView = (props: {
    id: number
}) => {
    const {data, error, refetch} = useQuery<Query, {
        id: number
    }>(gqlUser, {
        variables: props
    });

    const [getTelbook, resTelbook] = useLazyQuery<{
        telbook: TelBook
    }, {
        requestId: string
    }>(gqlGetTelBook);

    const [getUserClientSendReport, resUserClientSendReport] =
        useLazyQuery<{ userClientSendReport: UserClientSendReport[] }, {
            userId: number,
            day: Date,
            clientEmail: string,
        }>(gqlUserClientSendReport);

    const [mutUpdateUserActivate] = useMutation<{
        updateUserActivate: boolean
    }, {
        userId: number,
        isActivate: boolean,
    }>(gqlUpdateUserActivate);

    const [mutUpdateUserMemo] = useMutation<{
        updateUserMemo: boolean,
    }, {
        userId: number,
        memo: string,
    }>(gqlUpdateUserMemo);

    const [mutCreateApiKey] = useMutation<{
        createApiKey: boolean
    }, {
        userId: number
    }>(gqlCreateApiKey);

    const [mutUpdateUserPermissionActivate] = useMutation<{
        updateUserPermissionActivate: boolean
    }, {
        apiKey: String,
        isActivate: boolean,
        testSend: boolean,
    }>(gqlUpdateUserPermissionActivate);

    const [mutUpdateUserPermissionSmsLine] = useMutation<{
        updateUserPermissionSmsLine: boolean
    }, {
        apiKey: string,
        input: UpdateUserPermissionSmsLine[]
    }>(gqlUpdateUserPermissionSmsLine);

    const [mutIssuePoint] = useMutation<{
        issuePoint: boolean
    }, {
        input: IssuePoint,
    }>(gqlIssuePoint);

    const [mutSendTemplate] = useMutation<{
        sendTemplateSms: boolean
    }, {
        input: SendTemplateSms
    }>(gqlSendTemplateSms);

    const [mutUpdateClientPermission] = useMutation<{
        updateClientPermission: boolean,
    }, {
        input: UpdateClientPermission
    }>(gqlUpdateClientPermission);

    const [mutCreateClientPermission] = useMutation<{
        createClientPermission: boolean,
    }, {
        input: CreateClientPermission
    }>(gqlCreateClientPermission);

    const [mutUpdateUserPermission] = useMutation<any, {
        input: UpdateUserPermission,
        otp: string
    }>(gqlUpdateUserPermission);

    const [mutUpdateUserCoupon] = useMutation<Mutation, MutationUpdateUserCouponArgs>(gqlUpdateUserCoupon);

    const [mutSendBulkSms] = useMutation<{ sendBulkSms: boolean }, { input: SendBulkSms }>(gqlSendBulkSms);

    const [modalSmsLine, setModalSmsLine] = useState(false);
    const [modalTelbook, setModalTelbook] = useState(false);
    const [telbook, setTelbook] = useState<TelBook | undefined>();
    const [selApiPermission, setSelApiPermission] = useState<UserPermission | undefined>();

    const handler = {
        onChangeUserActivate: (swt: boolean) => {
            if (!data) {
                return;
            }
            mutUpdateUserActivate({
                variables: {
                    userId: data.userWithId.id,
                    isActivate: swt
                }
            }).then(() => {
                return refetch()
            }).catch(ApolloCatch({
                [BizErrors.default]: SwalUtil.bizError()
            }))
        },
        onUpdateUserMemo: (memo: string) => {
            if (!data) {
                return;
            }

            mutUpdateUserMemo({
                variables: {
                    userId: data.userWithId.id,
                    memo
                }
            }).then(() => {
                SwalUtil.ok({
                    msg: '저장되었습니다.',
                    icon: 'success'
                })
                return refetch();
            }).catch(ApolloCatch({
                [BizErrors.default]: SwalUtil.bizError()
            }))
        },
        onIssuePoint: (input: IssuePoint) => {
            if (!data) {
                return;
            }

            if (!input.point) {
                SwalUtil.ok({
                    msg: '포인트는 0 이상의 값을 입력하여 주십시오.',
                    icon: 'warning'
                })
                return;
            }

            SwalUtil.yn({
                msg: '충전 / 회수 하시겠습니까?',
                icon: 'question',
                ok: () => {
                    mutIssuePoint({
                        variables: {input}
                    }).then(res => {
                        SwalUtil.ok({
                            msg: input.point < 0 ? '회수 되었습니다.' : '충전되었습니다.',
                            icon: 'success'
                        })
                        return refetch();
                    }).catch(ApolloCatch({
                        [BizErrors.notEnoughPoint]: SwalUtil.bizErrorMsg({
                            msg: '잔고가 부족합니다.',
                            icon: "error"
                        }),
                        [BizErrors.failVerifyOTP]: SwalUtil.bizErrorMsg({
                            msg: 'OTP 번호를 다시 입력하여 주십시오.',
                            icon: "warning"
                        })
                    }))

                }
            })
        },
        onCreateApiKey: () => {
            if (!data) {
                return;
            }

            mutCreateApiKey({
                variables: {
                    userId: data.userWithId.id
                }
            }).then(res => {
                SwalUtil.ok({
                    msg: '생성되었습니다.',
                    icon: 'success'
                })
                return refetch();
            }).catch(ApolloCatch({
                [BizErrors.default]: SwalUtil.bizError()
            }))
        },
        onUpdateUserPermissionActivate: (apiKey: string, isActivate: boolean, testSend: boolean) => {
            if (!data) {
                return;
            }

            mutUpdateUserPermissionActivate({
                variables: {
                    apiKey,
                    isActivate,
                    testSend,
                }
            }).then(() => {
                return refetch();
            }).catch(ApolloCatch({
                [BizErrors.default]: SwalUtil.bizError()
            }))
        },
        onUpdateUserPermission: (input: UpdateUserPermission) => {
            SwalUtil.otp(otp => {
                mutUpdateUserPermission({
                    variables: {input, otp}
                }).then(res => {
                    SwalUtil.ok({
                        msg: '수정 되었습니다.',
                        icon: 'success'
                    });
                    return refetch();
                }).catch(ApolloCatch({
                    [BizErrors.default]: SwalUtil.bizError(),
                }))
            });
        },
        onClickApiPermission: (userPermission: UserPermission) => {
            setTelbook(undefined);
            setSelApiPermission(userPermission);
            setModalSmsLine(true);
        },
        onLoadUser: () => {
            refetch({id: props.id})
                .catch(ApolloCatch({}));
        }
    }

    const onGetUserClientSendReport = (userId: number, day: Date, clientEmail: string) => {
        getUserClientSendReport({variables: {userId, day, clientEmail}});
    }

    const onUpdateUserPermissionSmsLine = (apiKey: string, input: UpdateUserPermissionSmsLine[]) => {
        SwalUtil.yn({
            msg: '통신사 설정을 저장하시겠습니까?',
            icon: "question",
            ok: () => {
                mutUpdateUserPermissionSmsLine({
                    variables: {apiKey, input}
                }).then(res => {
                    SwalUtil.ok({
                        msg: '저장되었습니다.',
                        icon: 'success'
                    })
                    setModalSmsLine(false);
                    return refetch();
                }).catch(ApolloCatch({
                    [BizErrors.default]: SwalUtil.bizError()
                }))

            }
        })
    }

    const onSendBulkSms = (apiKey: string, msg: string, numbers: string[]) => {
        LoadingViewUtil.loading(
            mutSendBulkSms({
                variables: {
                    input: {msg, numbers, apiKey}
                }
            }).then(res => {
                SwalUtil.ok({
                    msg: '전송되었습니다.',
                    icon: 'success'
                });
                return refetch();
            }).catch(ApolloCatch({
                [BizErrors.default]: SwalUtil.bizError(),
            }))
        );
    }

    const onLoadTelbook = (requestId: string) => {
        setModalTelbook(true);
        getTelbook({variables: {requestId}});
    }

    const onSendTemplateSms = (input: SendTemplateSms) => {
        if (input.numbers.length === 0) {
            SwalUtil.ok({
                msg: '전화번호 데이터를 입력하여 주십시오.',
                icon: 'error'
            });
            return;
        }

        if (input.msg === "") {
            SwalUtil.ok({
                msg: '문자 내용을 입력하여 주십시오.',
                icon: "error"
            });
            return;
        }

        if (input.apiKey === "") {
            SwalUtil.ok({
                msg: "Apikey를 선택하여 주십시오.",
                icon: "error"
            });
            return;
        }


        SwalUtil.yn({
            msg: '탬플릿 전송 하시겠습니까?',
            text: `${input.numbers.length} 개`,
            icon: "question",
            ok: () => {
                LoadingViewUtil.loading(
                    mutSendTemplate({
                        variables: {input}
                    }).then(res => {
                        SwalUtil.ok({
                            msg: '전송되었습니다.',
                            icon: 'success'
                        });
                        return refetch();
                    }).catch(ApolloCatch({
                        [BizErrors.default]: SwalUtil.bizError(),
                    }))
                );

            }
        })
    }

    const onUpdateClientPermission = (input: UpdateClientPermission) => {
        SwalUtil.yn({
            msg: '업데이트 하시겠습니까?',
            icon: "question",
            ok: () => {
                mutUpdateClientPermission({variables: {input}})
                    .then(res => {
                        SwalUtil.ok({
                            msg: '업데이트 되었습니다.',
                            icon: 'success'
                        });
                        return refetch();
                    }).catch(ApolloCatch({
                    [BizErrors.default]: SwalUtil.bizError(),
                }))
            }
        })
    }

    const onCreateClientPermission = (input: CreateClientPermission) => {
        SwalUtil.yn({
            msg: '생성 하시겠습니까?',
            icon: "question",
            ok: () => {
                mutCreateClientPermission({variables: {input}})
                    .then(res => {
                        SwalUtil.ok({
                            msg: '생성 되었습니다.',
                            icon: 'success'
                        });
                        return refetch();
                    }).catch(ApolloCatch({
                    [BizErrors.default]: SwalUtil.bizError(),
                }))
            }
        })
    }

    const onUpdateUserCoupon = (args: UpdateUserCoupon[]) => {
        SwalUtil.ynPromise({
            msg: '저장하시겠습니까?'
        }).then(() => {
            return mutUpdateUserCoupon({
                variables: {
                    userId: props.id,
                    values: args
                }
            })
        }).then(() => {
            return refetch();
        }).then(() => {
            SwalUtil.ok({
                msg: '저장 되었습니다.',
                icon: 'success'
            })
        }).catch(ApolloCatch({}))

    }
    return (
        <>
            {data && <>
                {/* SmsLineModal */}
                <ModalWeb
                    title={'통신사 설정'}
                    size={'sm'}
                    isShow={modalSmsLine}
                    onCancel={() => {
                        setModalSmsLine(false);
                    }}
                    onOk={() => {
                        setModalSmsLine(false);
                    }}>
                    {selApiPermission &&
                    <MngUserViewModalApiSmsLine
                        apiKey={selApiPermission.apiKey}
                        userSmsLine={selApiPermission.smsLine}
                        smsLine={data.smsLine}
                        onUpdateUserPermissionSmsLine={onUpdateUserPermissionSmsLine}/>
                    }
                </ModalWeb>

                {/* 전화번호부 */}
                <ModalWeb
                    title={'전화번호부'}
                    isShow={modalTelbook}
                    onCancel={() => {
                        setModalTelbook(false);
                    }}
                    onOk={() => {
                        setModalTelbook(false);
                    }}>
                    {resTelbook.data &&
                    <ModalTelbook telbook={resTelbook.data.telbook}/>
                    }

                    {!resTelbook.data &&
                    <div className={cx('box-fail')}>결과가 없습니다.</div>
                    }
                </ModalWeb>

                <FadeInContainer>
                    {/* 유저 기본정보 */}
                    <BizCol12>
                        <MngUserViewInfo
                            user={data.userWithId}
                            {...handler}/>
                    </BizCol12>

                    <BizCol12>
                        <MngUserViewCoupon
                            onUpdateUserCoupon={onUpdateUserCoupon}
                            list={data.userCouponWithId}
                        />

                    </BizCol12>


                    {/* 포인트 충전 회수 */}
                    <BizCol12>
                        <MngUserViewIssuePoint
                            user={data.userWithId}
                            {...handler}/>
                    </BizCol12>

                    <BizCol12>
                        <Title>API 관리</Title>
                        <MngUserViewApiKey
                            userPermission={data.userWithId.permission}
                            {...handler}/>

                    </BizCol12>


                    <BizCol12>
                        <Title>클라이언트 설정</Title>
                        <ClientPermission
                            onCreateClientPermission={onCreateClientPermission}
                            onUpdateClientPermission={onUpdateClientPermission}
                            clientPermission={data.userWithId.clientPermission}
                            userPermission={data.userWithId.permission}/>
                    </BizCol12>


                    <BizCol12>
                        <Title>대량 전송</Title>
                        <SendBulkSmsView
                            userPermission={data.userWithId.permission}
                            onSendBulkSms={onSendBulkSms}/>
                    </BizCol12>


                    {/* <BizCol12>
                        <Title>탬플릿 전송</Title>
                        <SenderTemplate
                            userPermission={user.userWithId.permission}
                            onSendTemplateSms={onSendTemplateSms}/>
                    </BizCol12>*/}

                    {/*<BizCol12>
                        <Title>전송내역 출력</Title>
                        <MngUserViewClientSendReport
                            user={user.userWithId}
                            onGetUserClientSendReport={onGetUserClientSendReport}
                            userClientSendReport={resUserClientSendReport.data ? resUserClientSendReport.data.userClientSendReport : []}/>

                    </BizCol12>*/}

                    <BizCol12>
                        <Title>전송상태</Title>
                        <UserSendReport
                            sendReport={data.userSendReport}
                            onLoadTelbook={onLoadTelbook}
                            {...handler}/>
                    </BizCol12>
                </FadeInContainer>
            </>
            }
        </>
    )
}


const UserSendReport = (props: {
    sendReport: SendReport[],
    onLoadTelbook: (requestId: string) => void,
    onLoadUser: () => void,
}) => {
    const [scrollAreaHeight, setScrollAreaHeight] = useState<any>();
    const [scrollAreaWidth, setScrollAreaWidth] = useState<any>();
    const [scrollAreaContent, setScrollAreaContent] = useState<any>();

    const layout = useSelector<any, LayoutState>(state => state.layout);

    useEffect(() => {
        if (layout.viewMode === 0) {
            setScrollAreaHeight("auto");
            setScrollAreaWidth("auto");
            setScrollAreaContent("auto");
        } else {
            setScrollAreaHeight(285);
            setScrollAreaWidth(315);
            setScrollAreaContent(styles.scrollTableWidthForMobile);
        }
    }, [layout.viewMode]);

    return (
        <>
            <div className={cx('text-right')}>
                <button className='btn btn-sm btn-success' onClick={props.onLoadUser}>갱신</button>
            </div>
            <div style={{height: 10}}/>

            <ScrollArea
                style={{height: scrollAreaHeight, width: scrollAreaWidth}}
                contentStyle={{width: scrollAreaContent}}
                smoothScrolling={true}
            >
                <div className={cx('table', "scrollTable")}>
                    <div className={cx("header", "statusCell")}>상태</div>
                    <div className={cx("header", "dateCell")}>시각</div>
                    <div className={cx("header", "memoCell")}>메모</div>
                    <div className={cx("header", "sendCell")}>전송</div>
                    <div className={cx("header", "messegeCell")}>메시지</div>
                </div>

                {props.sendReport.length === 0 &&
                <div className={cx("table")}>
                    <div className={cx("col")} style={{width: '100%'}}>데이터가 없습니다.</div>
                </div>
                }

                {props.sendReport.map((value, index) => {
                    const delay = moment(value.doneAt).toDate().getTime() - moment(value.createdAt).toDate().getTime();
                    const viewTime = Math.floor(delay / 1000);

                    return (
                        <div key={index} className={cx('table', 'hover', "scrollTable")} onClick={event => {
                            props.onLoadTelbook(value.id);
                        }}>
                            <div className={cx("col", "statusCell")}>{value.status}</div>
                            <div className={cx("col", "dateCell")}>
                                {moment(value.createdAt).format('YY.MM.DD - hh:mm')} ({viewTime} 초)
                            </div>
                            <div className={cx("col", "memoCell")}>
                                {value.memo}
                            </div>
                            <div className={cx("col", "sendCell")}>
                                {Pipe.toSeparatorNumber(value.total)} 개
                            </div>
                            <div className={cx("col", "messageCell", "textLeft")}
                                 ref={ref => {
                                     if (ref) {
                                         ref.innerText = value.msg;
                                     }
                                 }}/>
                        </div>
                    );
                })}
            </ScrollArea>
        </>
    )
}

const SendBulkSmsView = (props: {
    userPermission: UserPermission[],
    onSendBulkSms: (apikey: string, msg: string, numbers: string[]) => void,
}) => {
    const [apiKey, setApiKey] = useState("");
    const [msg, setMsg] = useState("");
    const [numb, setNumb] = useState("");
    const [numbCount, setNumbCount] = useState(0);

    const onCountNumbers = (value: string) => {
        let cleared = NumbUtil.countNumb(value);
        setNumbCount(cleared.count);
    }

    const onClearNumber = () => {
        let cleared = NumbUtil.countNumb(numb);
        cleared = NumbUtil.duplicated(cleared.numb);
        cleared = NumbUtil.onlyNumber(cleared.numb);
        cleared = NumbUtil.wrongContext(cleared.numb);
        setNumb(cleared.numb);
        setNumbCount(cleared.count);
    }

    const onSendBulkSms = () => {
        if (!apiKey) {
            SwalUtil.ok({
                msg: 'API 키를 선택하여 주십시오.',
                icon: 'warning'
            });
            return;
        }

        if (70 < msg.length) {
            SwalUtil.ok({
                msg: '문자 내용이 너무 많습니다.',
                icon: 'warning'
            });
            return;
        }

        const numbers = NumbUtil.clearAndAppendNationCode(numb);

        if (numbers.length === 0) {
            SwalUtil.ok({
                msg: '전화번호가 없습니다.',
                icon: 'warning'
            });
            return;
        }

        SwalUtil.yn({
            msg: '전송하시겠습니까?',
            text: `${numbers.length} 개`,
            icon: 'question',
            ok: () => {
                props.onSendBulkSms(apiKey, msg, numbers);
            }
        })
    }

    useEffect(() => {
        if (0 < props.userPermission.length) {
            for (let userPermission of props.userPermission) {
                if (userPermission.isActivate) {
                    setApiKey(userPermission.apiKey);
                    break;
                }
            }
        }
    }, [props.userPermission])

    return (
        <div className='row m-0'>
            <div className='col-12 col-lg-6'>
                <div className={cx('table-title')}>문자내용 <span style={{fontSize: '0.8rem'}}>({msg.length} / 70)</span>
                </div>
                <textarea
                    value={msg}
                    className='form-control'
                    style={{height: 300, marginBottom: 10, resize: 'none'}}
                    placeholder={'문자 내용을 입력하여 주십시오.'}
                    onChange={event => {
                        setMsg(event.target.value);
                    }}/>
                <select
                    value={apiKey}
                    className={cx("apiSelect")}
                    onChange={event => {
                        setApiKey(event.target.value);
                    }}>
                    <option value={""} disabled>API 키를 선택하여 주십시오.</option>
                    {props.userPermission.map((value, index) => {
                        return (
                            <option key={index} value={value.apiKey} disabled={!value.isActivate}>
                                {value.nm} : {value.apiKey}
                            </option>
                        )
                    })}
                </select>

            </div>
            <div className='col-12 col-lg-6'>
                <div className={cx('table-title')}>전화번호 <span
                    style={{fontSize: '0.8rem'}}>({Pipe.toSeparatorNumber(numbCount)} / 5000)</span></div>
                <textarea
                    value={numb}
                    className='form-control'
                    style={{height: 300, marginBottom: 10, resize: 'none'}}
                    placeholder={'번화번호를 입력하여 주십시오.'}
                    onChange={event => {
                        setNumb(event.target.value);
                        onCountNumbers(event.target.value);
                    }}/>
                <button
                    style={{marginRight: 20, width: 'calc(50% - 10px)'}}
                    className='btn btn-sm btn-outline-success'
                    onClick={onClearNumber}>전화번호 정리
                </button>

                <button
                    style={{width: 'calc(50% - 10px)'}}
                    className='btn btn-sm btn-success'
                    onClick={onSendBulkSms}>전송
                </button>
            </div>
        </div>
    )
}

const ModalTelbook = (props: {
    telbook: TelBook
}) => {

    const onDownload = () => {
        let page = "";
        for (let telNumber of props.telbook.numberList) {
            page += `${telNumber.number}\n`;
        }

        let data = new Blob([page], {type: 'application/csv'});
        saveAs(data, `${props.telbook.requestId}.csv`);
    }

    const [divided, setDivided] = useState<{ [key: string]: number }>({});
    const [telbook, setTelbook] = useState<TelNumber[]>([]);
    const [findNumber, setFindNumber] = useState("");
    const [dlr, setDlr] = useState<DlrResult>({
        delivered: 0,
        undelivered: 0,
        unchecked: 0,
    })

    useEffect(() => {
        const res: { [key: string]: number } = {};
        const resDlr: DlrResult = {
            delivered: 0,
            undelivered: 0,
            unchecked: 0,
        }
        for (let numbs of props.telbook.numberList) {
            if (res.hasOwnProperty(numbs.smsLine)) {
                res[numbs.smsLine] += 1;
            } else {
                res[numbs.smsLine] = 1;
            }

            switch (numbs.status) {
                case TelNumberStatus.Sent:
                    resDlr.unchecked += 1;
                    break;
                case TelNumberStatus.Fail:
                    resDlr.unchecked += 1;
                    break;
                case TelNumberStatus.DlrDelivered:
                    resDlr.delivered += 1;
                    break;
                case TelNumberStatus.DlrUndlivered:
                    resDlr.undelivered += 1;
                    break;
            }
        }
        setDivided(res);
        setTelbook(props.telbook.numberList);
        setFindNumber("");
        setDlr(resDlr);

    }, [props.telbook]);

    const onFindNumber = () => {
        const res = props.telbook.numberList.filter(value => {
            return value.number.includes(findNumber);
        })

        setTelbook(res);
    }

    const onInitNumber = () => {
        setTelbook(props.telbook.numberList);
    }

    const onDlrDelivered = () => {
        const res = telbook.filter(value => {
            return value.status === TelNumberStatus.DlrDelivered
        })
        setTelbook(res);
    }

    const onDlrUndelivered = () => {
        const res = telbook.filter(value => {
            return value.status === TelNumberStatus.DlrUndlivered
        })
        setTelbook(res);
    }

    return (
        <>
            <div className={cx('table')}>
                <div style={{width: 100}}>요청키</div>
                <div className='text-left'
                     style={{width: 'calc(100% - 100px)'}}>
                    {props.telbook.requestId}
                </div>
            </div>
            <div className={cx('table')}>
                <div style={{width: 100}}>요청시</div>
                <div className='text-left'
                     style={{width: 'calc(100% - 100px)'}}>
                    {moment(props.telbook.createdAt).format('YY.MM.DD - hh:mm a')}
                </div>
            </div>
            <div className={cx('table')}>
                <div style={{width: 100}}>결과</div>
                <div className='text-left'
                     style={{width: 'calc(100% - 100px)'}}>
                    요청 : {Pipe.toSeparatorNumber(props.telbook.requestTotal)},
                    완료 : {Pipe.toSeparatorNumber(props.telbook.sent)},
                    실패 : {Pipe.toSeparatorNumber(props.telbook.fail)}
                </div>
            </div>

            <div className={cx('table')}>
                <div style={{width: 100}}>분배</div>
                <div className='text-left'
                     style={{width: 'calc(100% - 100px)'}}>
                    {/*{Object.keys(divided).length} 개*/}
                    {Object.keys(divided).map((value, index) => {
                        return <span key={index}>{value} : {Pipe.toSeparatorNumber(divided[value])} 개, </span>
                    })}
                </div>
            </div>


            <div className={cx('table')}>
                <div style={{width: 100}}>DLR</div>
                <div className='text-left'
                     style={{width: 'calc(100% - 100px)'}}>
                    DLR
                    Rate: {new Decimal(dlr.delivered + dlr.undelivered).div(props.telbook.numberList.length).mul(100).floor().toNumber()} % <br/>
                    DLR 수신완료 : {Pipe.toSeparatorNumber(dlr.delivered)} 개
                    ({new Decimal(dlr.delivered).div(props.telbook.numberList.length).mul(100).floor().toNumber()} %)<br/>
                    DLR 수신실패 : {Pipe.toSeparatorNumber(dlr.undelivered)} 개<br/>
                    DLR 미확인 : {Pipe.toSeparatorNumber(dlr.unchecked)} 개 <br/>
                </div>
            </div>

            <div className={cx('table')}>
                <div style={{width: 100}}>수신율</div>
                <div className='text-left'
                     style={{width: 'calc(100% - 100px)'}}>
                    응답률 : {Pipe.toSeparatorNumber(dlr.delivered + dlr.undelivered)} 개
                    ({new Decimal(dlr.delivered + dlr.undelivered).div(props.telbook.numberList.length).mul(100).floor().toNumber()} %) <br/>
                    성공 : {Pipe.toSeparatorNumber(dlr.delivered)} 개
                    ({new Decimal(dlr.delivered).div(dlr.delivered + dlr.undelivered).mul(100).floor().toNumber()} %)<br/>
                    실패 : {Pipe.toSeparatorNumber(dlr.undelivered)} 개
                    ({new Decimal(dlr.undelivered).div(dlr.delivered + dlr.undelivered).mul(100).floor().toNumber()} %)<br/>
                </div>
            </div>


            <div className={cx('table')}>
                <div style={{width: 100}}>메세지</div>
                <div className='text-left'
                     style={{width: 'calc(100% - 100px)'}}
                     ref={ref => {
                         if (ref) {
                             ref.innerText = props.telbook.msg
                         }
                     }}/>
            </div>
            <div className={cx('table')}>
                <div style={{width: 100}}>
                    전화번호<br/>
                    <span style={{
                        fontSize: '0.8rem',
                        color: '#c1c1c1'
                    }}>({Pipe.toSeparatorNumber(telbook.length)} 개)</span>
                </div>
                <div className='text-left'
                     style={{width: 'calc(100% - 100px)', padding: 0}}>
                    <PerfectScrollbar style={{height: 200}}>
                        <div className={cx('box-number')}>
                            {telbook.map((value, index) => {
                                return (
                                    <div
                                        className={cx('numb',
                                            value.status === TelNumberStatus.DlrDelivered ? 'bg-green' : 'bg-red')}
                                        key={index}>{value.number}</div>
                                )
                            })}
                        </div>
                    </PerfectScrollbar>
                </div>
            </div>

            <div className={cx('table')}>
                <div style={{width: 110}}>찾기</div>
                <div style={{width: 'calc(100% - 260px)'}}>
                    <input
                        value={findNumber}
                        style={{marginTop: 4}}
                        className='form-control form-control-sm'
                        onChange={event => {
                            setFindNumber(event.target.value);
                        }}
                        onKeyUp={event => {
                            if (event.key === 'Enter') {
                                onFindNumber()
                            }
                        }}/>
                </div>
                <div style={{width: 80}}>
                    <button
                        style={{width: '100%'}}
                        className='btn btn-sm btn-outline-success'
                        onClick={onFindNumber}>찾기
                    </button>
                </div>
                <div style={{width: 80}}>
                    <button
                        style={{width: '100%'}}
                        className='btn btn-sm btn-outline-success'
                        onClick={onInitNumber}>초기화
                    </button>
                </div>
            </div>

            <div className={cx('table')}>
                <div style={{width: 110}}>필터</div>
                <div className='text-left' style={{width: 'calc(100% - 100px)'}}>
                    <button
                        className='btn btn-sm btn-outline-secondary'
                        style={{marginRight: 10}}
                        onClick={onDlrDelivered}>
                        DLR 성공
                    </button>
                    <button
                        className='btn btn-sm btn-outline-secondary'
                        style={{marginRight: 10}}
                        onClick={onDlrUndelivered}>
                        DLR 실패
                    </button>
                    <button
                        className='btn btn-sm btn-outline-secondary'
                        style={{marginRight: 10}}
                        onClick={onInitNumber}>
                        초기화
                    </button>
                </div>
            </div>


            <div className={cx('table')}>
                <div className='text-right'
                     style={{width: '100%'}}>
                    <button
                        className='btn btn-sm btn-success'
                        onClick={onDownload}>다운로드
                    </button>
                </div>
            </div>
        </>
    );
}

const gqlUser = gql`
    query UserWithId($id: Int!){
        userWithId(id:$id){
            id
            email
            point
            isActivate
            memo
            permission {
                apiKey
                isActivate
                testSend
                smsLine{
                    id
                    smsLine {
                        id
                        smsLineType
                        isActivate
                    }
                    rate
                }
                nm
                smsFee
            }
            clientPermission {
                id
                clientApiKey
                tableNm
                isActivate
                adminFb
                clientFb
                env
                devMode
                appNm
                dbSchemaNm
                permissions {
                    apiKey
                    defaultUserSmsFee
                    isDefault
                    nm
                    systemDefault
                }
            }
        }
        userSendReport(userId: $id, size: 30) {
            id
            userEmail
            memo
            msg
            status
            total
            createdAt
            doneAt
            tps
        }
        userDailyUsageWithLast(userId: $id, last: 7) {
            smsLineId
            smsLineType
            list {
                day
                usage
            }
        }
        smsLine{
            id
            smsLineType
            isActivate
        }
        userCouponWithId(userId: $id) {
            id
            isActivate
            amount
            smsCount
            smsFee
            taxAmount
            taxRate
            createdAt
        }
    }
`;

interface DlrResult {
    delivered: number,
    undelivered: number,
    unchecked: number
}

export interface MngUserViewInfoResp {
    userWithId: User,
    userSendReport: SendReport[],
    userDailyUsageWithLast: SmsLineDailyUsage[],
    smsLine: SmsLine[]
}

const gqlUpdateUserActivate = gql`
    mutation UpdateUserActivate($userId: Int!, $isActivate: Boolean!){
        updateUserActivate(userId:$userId, isActivate:$isActivate)
    }
`;

const gqlUpdateUserMemo = gql`
    mutation UpdateUserMemo($userId: Int!, $memo: String!){
        updateUserMemo(userId:$userId, memo:$memo)
    }
`;

const gqlCreateApiKey = gql`
    mutation CreateApiKey($userId: Int!){
        createApiKey(userId:$userId)
    }
`;

const gqlUpdateUserPermissionActivate = gql`
    mutation UpdateUserPermissionActivate($apiKey: String!, $isActivate: Boolean!, $testSend: Boolean!){
        updateUserPermissionActivate(apiKey:$apiKey, isActivate:$isActivate, testSend: $testSend)
    }
`

const gqlUpdateUserPermissionSmsLine = gql`
    mutation UpdateUserPermissionSmsLine($apiKey: String!, $input: [UpdateUserPermissionSmsLine!]!) {
        updateUserPermissionSmsLine(apiKey: $apiKey, input: $input)
    }
`;

const gqlIssuePoint = gql`
    mutation IssuePoint($input: IssuePoint!) {
        issuePoint(input: $input)
    }
`;

const gqlSendBulkSms = gql`
    mutation SendBulkSms($input: SendBulkSms!) {
        sendBulkSms(input: $input)
    }
`;

const gqlGetTelBook = gql`
    query Telbook($requestId: String!) {
        telbook(requestId: $requestId) {
            requestId
            msg
            requestTotal
            totalSend
            sent
            fail
            numberList {
                number
                status
                smsLine
            }
            createdAt
        }
    }
`;

const gqlSendTemplateSms = gql`
    mutation SendTemplateSms($input: SendTemplateSms!) {
        sendTemplateSms(input: $input)
    }
`;

const gqlUpdateClientPermission = gql`
    mutation UpdateClientPermission($input: UpdateClientPermission!) {
        updateClientPermission(input: $input)
    }
`;

const gqlCreateClientPermission = gql`
    mutation CreateClientPermission($input: CreateClientPermission!) {
        createClientPermission(input: $input)
    }
`;

const gqlUpdateUserPermission = gql`
    mutation UpdateUserPermission($input: UpdateUserPermission!, $otp: String!) {
        updateUserPermission(input: $input, otp: $otp)
    }
`;


const gqlUserClientSendReport = gql`
    query UserClientSendReport($userId: Int!, $day: Time!, $clientEmail: String!) {
        userClientSendReport(userId: $userId, day: $day, clientEmail: $clientEmail) {
            id
            clientEmail
            sendType
            msg
            request
            sent
            fail
            shortApiKey
            createdAt
        }
    }
`;

const gqlUpdateUserCoupon = gql`
    mutation UpdateUserCoupon($userId: Int!, $values: [UpdateUserCoupon!]!) {
        updateUserCoupon(userId: $userId, values: $values)
    }
`;
