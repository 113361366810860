import React from 'react';
import classNamesBind from "classnames/bind";
import styles from './NotAuthFooter.module.scss';

const cx = classNamesBind.bind(styles);

export const NotAuthFooter = () => {
    return (
        <div className={cx('cont')}>
            Copyright ⓒ 2020 ClientSmsSender <br/>
            All rights reserved.
        </div>
    )
}
