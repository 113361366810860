import React, {Fragment} from 'react';
import QRCODE from 'qrcode.react';

export const RegisterOTPModal = (props: {
    provisionUrl: string
}) => {
    return (
        <Fragment>
            <div className='text-center'>
                <h5 style={{color: 'red', marginBottom: 30}}>주의 !! 다시 요청할 수 없습니다.!!!</h5>
                <QRCODE value={props.provisionUrl}/>
            </div>
        </Fragment>
    )
}
