export class LocalStorage {
    static setSession(token: string) {
        localStorage.setItem('api-token', token);
    }

    static getSession(): string {
        return localStorage.getItem('api-token') || '';
    }

    static clearSession() {
        localStorage.removeItem('api-token');
    }

    static setLangCode(langCode: string) {
        localStorage.setItem("langCode", langCode);
    }

    static getLangCode(): string {
        return localStorage.getItem("langCode") || 'en';
    }
}
