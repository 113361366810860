// import {MultiLangs} from "../../graphql/types";

import moment from "moment";

export class Pipe {
    static toSeparatorNumber(numb: number): string {
        return numb.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }

    static toDayString(time: Date): string {
        return moment(time).format('YYYYMMDD')
    }

    static toTimeMonth(time: Date): number {
        return Number(moment(time).format('YYYYMM'));
    }

    static cvtTimeMonthToDate(timeMonth: number): Date {
        const value = String(timeMonth);
        if (value.length !== 6) {
            return new Date();
        }

        const year = value.substring(0, 4);
        const month = value.substring(4, 6);

        return moment(`${year}-${month}-01 00:00:00z`).toDate();

    }

    static cvtDayStringToDate(str?: string): Date | undefined {
        if (!str) {
            return undefined;
        }

        if (str.length != 8) {
            return undefined;
        }

        const year = str.substring(0, 4);
        const month = str.substring(4, 6);
        const day = str.substring(6, 8);

        return moment(`${year}-${month}-${day} 00:00:00z`).toDate();
    }

    static downloadFile(src: string) {
        const tag = document.createElement("a");
        tag.href = src;
        tag.target = "_blank";
        tag.setAttribute('hidden', 'true');
        tag.click();
    }
}
