import React, {useState} from 'react';
import {gql, useQuery} from "@apollo/client";
import {Query} from "../../../graphql/types";
import {FadeInContainer} from "../../shares/fadeInContainer/FadeInContainer";
import {BizCol12} from "../../shares/bizCol12/BizCol12";
import {BizTitle} from "../../shares/title/Title";
import {SettleMonthlyList} from "./list/SettleMonthlyList";
import {BizMonthPicker} from "../../shares/bizMonthPicker/BizMonthPicker";
import {Pipe} from "../../../utils/pipe/pipe";
import {navigate} from "hookrouter";
import {SettleMonthlyInput} from "./input/SettleMonthlyInput";

export const SettleMonthly = (props: {
    timeMonth: number
}) => {
    const {data, loading, error, refetch} = useQuery<Query>(gqlData, {
        variables: props
    });
    const [originFee, setOriginFee] = useState(11.5);

    const handler = {
        onChangeMonth: (date: Date) => {
            navigate(`/settle/monthly/${Pipe.toTimeMonth(date)}`)
        }
    };

    return (
        <>
            {data &&
            <FadeInContainer>
                <BizCol12>
                    <BizTitle title={'월간정산'}>
                        <BizMonthPicker
                            date={Pipe.cvtTimeMonthToDate(props.timeMonth)}
                            onChange={handler.onChangeMonth}/>
                    </BizTitle>

                    <SettleMonthlyInput
                        value={originFee}
                        onChangeValue={setOriginFee}/>

                    <div style={{height: 50}}/>
                    <BizTitle title={'정산목록'}/>
                    <SettleMonthlyList
                        originFee={originFee}
                        list={data.clientRechargeRequestListWithTimeMonth}/>
                </BizCol12>
            </FadeInContainer>
            }
        </>
    )
}

const gqlData = gql`
    query DATA($timeMonth: Int!) {
        clientRechargeRequestListWithTimeMonth(timeMonth: $timeMonth){
            amount
            clientEmail
            clientId
            smsCount
            taxAmount
            taxRate
            smsFee
        }
    }
`;
