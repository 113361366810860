import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import {App} from "./components/App";
import {createStore} from "redux";
import {RootReducer} from "./redux";
import {Provider} from "react-redux";
import {ApolloProvider} from "@apollo/client";
import {Apollo} from "./utils/apollo/apollo";
import {LoadingView} from "./components/shares/loadingView/LoadingView";
import {LayoutAction} from "./redux/Layout";
import './styles/common.scss'
import {I18nUtil} from "./utils/i18n/i18n";
import {I18nRes} from "./i18n";
import {FB} from "./utils/fb/FB";
import {ApolloCatch} from "./utils/apollo/apolloExec";
import {BizErrors} from "./graphql/errors";

export const ReduxStore =
    createStore(RootReducer,
        (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
        (window as any).__REDUX_DEVTOOLS_EXTENSION__());

// 다국어 처리
I18nUtil.initI18n(I18nUtil.parse(I18nRes));

// FB 초기화
FB.init();
FB.refreshSession().then().catch(ApolloCatch({
    [BizErrors.default]: () => {
    }
}));

// 화면 크기 조정
ReduxStore.dispatch(LayoutAction.setWindowWidth(window.innerWidth));
window.addEventListener("resize", () => {
    ReduxStore.dispatch(LayoutAction.setWindowWidth(window.innerWidth));
})

ReactDOM.render(
    <Suspense fallback={<LoadingView isActivate={true}/>}>
        <ApolloProvider client={Apollo.client}>
            <Provider store={ReduxStore}>
                <App/>
            </Provider>
        </ApolloProvider>
    </Suspense>
    ,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
