import {ErrorResponse} from "@apollo/client/link/error";
import {BizErrors} from "../../graphql/errors";
import {SwalUtil} from "../swal/swalUtil";

export type BizErrorReactor = { [key: string]: (reason: any) => void };
type Catch = (e: ErrorResponse) => void;

export const ApolloCatch = (reactor?: BizErrorReactor): Catch => {
    return e => {
        if (e.networkError) {
            SwalUtil.ok({
                msg: '서버와 접속이 불안정 합니다.<br/>다시 시도하여 주십시오.',
                icon: "error"
            });
            return;
        }

        if (!(e as any).hasOwnProperty("graphQLErrors")) {
            return;
        }

        if (!e.graphQLErrors || !reactor) {
            return;
        }

        let runDefault = true;
        for (let err of e.graphQLErrors) {
            if (err.message === BizErrors.swalCancel) {
                continue;
            }

            if (reactor.hasOwnProperty(err.message)) {
                runDefault = false;
                reactor[err.message](e);
            }

            if (runDefault && (reactor.hasOwnProperty(BizErrors.default))) {
                reactor[BizErrors.default](e);
            } else if(runDefault) {
                SwalUtil.ok({
                    msg: '실패 하였습니다.',
                    icon: 'error'
                })
            }
        }
    }
}
