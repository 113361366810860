export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Decimal: any;
  Map: any;
  Time: any;
  Upload: any;
};

export enum SmsLineType {
  RouteMobile = 'RouteMobile',
  RouteMobileResller = 'RouteMobileResller'
}

export type SendTemplateSms = {
  apiKey: Scalars['String'];
  msg: Scalars['String'];
  numbers: Array<TemplateSms>;
};

export type EsStatusValues = {
  __typename?: 'EsStatusValues';
  total: Scalars['Int'];
  done: Scalars['Int'];
};

export type Admin = {
  __typename?: 'Admin';
  id: Scalars['Int'];
  email: Scalars['String'];
};

export type ClientRechargeRequest = {
  __typename?: 'ClientRechargeRequest';
  id: Scalars['Int'];
  status: ClientRechargeRequestStatus;
  clientEmail: Scalars['String'];
  clientId: Scalars['Int'];
  amount: Scalars['Decimal'];
  point: Scalars['Decimal'];
  taxRate: Scalars['Decimal'];
  taxAmount: Scalars['Decimal'];
  smsCount: Scalars['Int'];
  createdAt: Scalars['Time'];
};

export type SmsLineBalance = {
  __typename?: 'SmsLineBalance';
  id: Scalars['Int'];
  sendType: Scalars['String'];
  username: Scalars['String'];
  point: Scalars['Decimal'];
  currency: Scalars['String'];
  updatedAt: Scalars['Time'];
};

export type TelBook = {
  __typename?: 'TelBook';
  requestId: Scalars['String'];
  msg: Scalars['String'];
  requestTotal: Scalars['Int'];
  totalSend: Scalars['Int'];
  sent: Scalars['Int'];
  fail: Scalars['Int'];
  numberList: Array<TelNumber>;
  dlrDelivered: Scalars['Int'];
  dlrUndelivered: Scalars['Int'];
  dlrUnchecked: Scalars['Int'];
  createdAt: Scalars['Time'];
};

export type SendReport = {
  __typename?: 'SendReport';
  id: Scalars['String'];
  userEmail: Scalars['String'];
  memo: Scalars['String'];
  msg: Scalars['String'];
  status: SmsRequestType;
  total: Scalars['Int'];
  tps: Scalars['Int'];
  createdAt: Scalars['Time'];
  doneAt?: Maybe<Scalars['Time']>;
};

export type Query = {
  __typename?: 'Query';
  admin: Admin;
  alertDelaySmsLine: Array<DelayedSmsLine>;
  smsLineDailyUsageWithLast: Array<SmsLineDailyUsage>;
  smsCompanyDailyUsageWithLast: Array<SmsCompanyDailyUsage>;
  smsDailySendReport: SmsDailySendReport;
  esApiStatus: EsApiStatus;
  esApiRequestList: EsRequestStatusList;
  smsLineSendReport: SmsLineSendReport;
  sendReport: SendDetailReportList;
  clientRechargeRequest: Array<ClientRechargeRequest>;
  clientRechargeRequestList: ClientRechargeReqeustList;
  clientRechargeRequestListWithTimeMonth: Array<ClientRechargeRequestReceipt>;
  receiverTransferStatus: ReceiverTransferStatus;
  smsLine: Array<SmsLine>;
  smsLineBind: Array<SmsLineBind>;
  smsLineBalance: Array<SmsLineBalance>;
  speedTestTotal: SpeedTestTotal;
  speedTestList?: Maybe<SpeedTestList>;
  speedTestLine: Array<SpeedTestLine>;
  speedTestNumber: Array<SpeedTestNumber>;
  speedTestGraph: Array<SpeedTestLineGraph>;
  user: UserList;
  userWithId: User;
  userWithEmail: Array<User>;
  userSendReport: Array<SendReport>;
  userDailyUsageWithLast: Array<SmsLineDailyUsage>;
  userDailyUsageWithSmsCompany: Array<SmsCompanyDailyUsage>;
  userClientSendReport: Array<UserClientSendReport>;
  telbook: TelBook;
  userCouponWithId: Array<UserCoupon>;
  userCategory: Array<UserCategory>;
};


export type QueryAlertDelaySmsLineArgs = {
  minute: Scalars['Int'];
};


export type QuerySmsLineDailyUsageWithLastArgs = {
  last: Scalars['Int'];
};


export type QuerySmsCompanyDailyUsageWithLastArgs = {
  last: Scalars['Int'];
};


export type QuerySmsDailySendReportArgs = {
  period: Period;
};


export type QueryEsApiRequestListArgs = {
  page: Scalars['Int'];
  size: Scalars['Int'];
};


export type QuerySmsLineSendReportArgs = {
  period: Period;
};


export type QuerySendReportArgs = {
  page: Scalars['Int'];
  size: Scalars['Int'];
  period: Period;
};


export type QueryClientRechargeRequestListArgs = {
  page: Scalars['Int'];
  size: Scalars['Int'];
};


export type QueryClientRechargeRequestListWithTimeMonthArgs = {
  timeMonth: Scalars['Int'];
};


export type QuerySpeedTestTotalArgs = {
  day: Scalars['Time'];
};


export type QuerySpeedTestListArgs = {
  page: Scalars['Int'];
  size: Scalars['Int'];
  day: Scalars['Time'];
};


export type QuerySpeedTestGraphArgs = {
  day: Scalars['Time'];
};


export type QueryUserArgs = {
  page: Scalars['Int'];
  size: Scalars['Int'];
};


export type QueryUserWithIdArgs = {
  id: Scalars['Int'];
};


export type QueryUserWithEmailArgs = {
  email: Scalars['String'];
};


export type QueryUserSendReportArgs = {
  userId: Scalars['Int'];
  size: Scalars['Int'];
};


export type QueryUserDailyUsageWithLastArgs = {
  userId: Scalars['Int'];
  last: Scalars['Int'];
};


export type QueryUserDailyUsageWithSmsCompanyArgs = {
  userId: Scalars['Int'];
  last: Scalars['Int'];
};


export type QueryUserClientSendReportArgs = {
  userId: Scalars['Int'];
  day: Scalars['Time'];
  clientEmail: Scalars['String'];
};


export type QueryTelbookArgs = {
  requestId: Scalars['String'];
};


export type QueryUserCouponWithIdArgs = {
  userId: Scalars['Int'];
};

export type UpdateUserCoupon = {
  isActivate: Scalars['Boolean'];
  smsCount: Scalars['Int'];
  smsFee: Scalars['Decimal'];
  taxRate: Scalars['Decimal'];
  taxAmount: Scalars['Decimal'];
  amount: Scalars['Decimal'];
};

export type SendDetailReport = {
  __typename?: 'SendDetailReport';
  id: Scalars['String'];
  apiKey: Scalars['String'];
  userEmail: Scalars['String'];
  memo: Scalars['String'];
  msg: Scalars['String'];
  status: SmsRequestType;
  total: Scalars['Int'];
  sent: Scalars['Int'];
  fail: Scalars['Int'];
  tps: Scalars['Int'];
  createdAt: Scalars['Time'];
  doneAt?: Maybe<Scalars['Time']>;
};

export type ClientRechargeRequestReceipt = {
  __typename?: 'ClientRechargeRequestReceipt';
  clientId: Scalars['Int'];
  clientEmail: Scalars['String'];
  amount: Scalars['Decimal'];
  taxRate: Scalars['Decimal'];
  taxAmount: Scalars['Decimal'];
  smsCount: Scalars['Int'];
  smsFee: Scalars['Decimal'];
};

export type SpeedTest = {
  __typename?: 'SpeedTest';
  id: Scalars['Int'];
  smsLineId: Scalars['Int'];
  smsLineNm: Scalars['String'];
  sentTotal: Scalars['Int'];
  receivedTotal: Scalars['Int'];
  avgDelay: Scalars['Int'];
  list: Array<SmsTestDetail>;
  sentAt: Scalars['Time'];
};

export type SpeedTestList = {
  __typename?: 'SpeedTestList';
  page: Scalars['Int'];
  size: Scalars['Int'];
  total: Scalars['Int'];
  list: Array<SpeedTest>;
};

export enum SmsCompany {
  Sk = 'SK',
  Kt = 'KT',
  Lg = 'LG',
  RouteMobile = 'RouteMobile'
}

export type UpdateUserCategory = {
  id: Scalars['Int'];
  nm: Scalars['String'];
  isActivate: Scalars['Boolean'];
};

export type UserCoupon = {
  __typename?: 'UserCoupon';
  id: Scalars['Int'];
  isActivate: Scalars['Boolean'];
  smsCount: Scalars['Int'];
  smsFee: Scalars['Decimal'];
  taxRate: Scalars['Decimal'];
  taxAmount: Scalars['Decimal'];
  amount: Scalars['Decimal'];
  createdAt: Scalars['Time'];
};

export enum SendType {
  RouteSmpp = 'RouteSmpp',
  RouteApi = 'RouteApi'
}

export type SpeedTestNumber = {
  __typename?: 'SpeedTestNumber';
  id: Scalars['Int'];
  isActivated: Scalars['Boolean'];
  number: Scalars['String'];
  company: SmsCompany;
};

export type SmsDailySendReport = {
  __typename?: 'SmsDailySendReport';
  requested: Scalars['Int'];
  sent: Scalars['Int'];
  fail: Scalars['Int'];
  delivered: Scalars['Int'];
  undelivered: Scalars['Int'];
};

export type EsRequestStatusList = {
  __typename?: 'EsRequestStatusList';
  page: Scalars['Int'];
  size: Scalars['Int'];
  total: Scalars['Int'];
  list: Array<EsRequestStatus>;
};

export type CreateCoupon = {
  userId: Scalars['Int'];
  smsCount: Scalars['Int'];
  smsFee: Scalars['Decimal'];
  taxRate: Scalars['Decimal'];
  taxAmount: Scalars['Decimal'];
  amount: Scalars['Decimal'];
};

export type UpdateClinetPermissionApiKey = {
  apiKey: Scalars['String'];
  nm: Scalars['String'];
  isDefault: Scalars['Boolean'];
  defaultUserSmsFee: Scalars['Decimal'];
  systemDefault: Scalars['Boolean'];
};

export type UpdateUserPermissionSmsLine = {
  smsLineId: Scalars['Int'];
  rate: Scalars['Int'];
};

export type DelayedSmsLine = {
  __typename?: 'DelayedSmsLine';
  reportId: Scalars['Int'];
  smsLineId: Scalars['Int'];
  smsLineNm: Scalars['String'];
  delay: Scalars['Int'];
  createdAt: Scalars['Time'];
};

export type Mutation = {
  __typename?: 'Mutation';
  join: Scalars['Boolean'];
  registerOtp: Scalars['String'];
  login: Scalars['String'];
  admin: Admin;
  logout: Scalars['Boolean'];
  requestTelBookSummaryWithUserIdAndPeriod: Scalars['Boolean'];
  esFindUserIdWithEmail: Scalars['Int'];
  issuePoint: Scalars['Boolean'];
  allowClientRechargeRequest: Scalars['Boolean'];
  denyClientRechargeRequest: Scalars['Boolean'];
  createCoupon: Scalars['Boolean'];
  toggleActivateCoupon: Scalars['Boolean'];
  bindSmsLine: Scalars['Boolean'];
  refreshSmsLineBalance: Scalars['String'];
  sendSpeedTest: Scalars['Boolean'];
  sendSpeedTestWithSmsLineId: Scalars['Boolean'];
  toggleSpeedTestLine: Scalars['Boolean'];
  toggleSpeedTestNumber: Scalars['Boolean'];
  updateSpeedTestLineApiKey: Scalars['Boolean'];
  updateSpeedTestNumber: Scalars['Boolean'];
  createUser: Scalars['Boolean'];
  createApiKey: Scalars['Boolean'];
  createClientPermission: Scalars['Boolean'];
  updateUserMemo: Scalars['Boolean'];
  updateUserActivate: Scalars['Boolean'];
  updateUserPermission: Scalars['Boolean'];
  updateUserPermissionActivate: Scalars['Boolean'];
  updateUserPermissionSmsLine: Scalars['Boolean'];
  sendBulkSms: Scalars['Boolean'];
  sendTemplateSms: Scalars['Boolean'];
  updateClientPermission: Scalars['Boolean'];
  createUserCategory: Scalars['Boolean'];
  updateUserCategory: Scalars['Boolean'];
  updateUserCoupon: Scalars['Boolean'];
};


export type MutationJoinArgs = {
  uid: Scalars['String'];
};


export type MutationRegisterOtpArgs = {
  uid: Scalars['String'];
};


export type MutationLoginArgs = {
  uid: Scalars['String'];
  otp: Scalars['String'];
};


export type MutationRequestTelBookSummaryWithUserIdAndPeriodArgs = {
  userId: Scalars['Int'];
  stDate: Scalars['Int'];
  edDate: Scalars['Int'];
};


export type MutationEsFindUserIdWithEmailArgs = {
  email: Scalars['String'];
};


export type MutationIssuePointArgs = {
  input: IssuePoint;
};


export type MutationAllowClientRechargeRequestArgs = {
  clientRechargeRequestId: Scalars['Int'];
  otp: Scalars['String'];
};


export type MutationDenyClientRechargeRequestArgs = {
  clientRechargeRequestId: Scalars['Int'];
};


export type MutationCreateCouponArgs = {
  input: CreateCoupon;
};


export type MutationToggleActivateCouponArgs = {
  userCouponId: Scalars['Int'];
  swt: Scalars['Boolean'];
};


export type MutationBindSmsLineArgs = {
  input: Array<BindSmsLine>;
  otp: Scalars['String'];
};


export type MutationSendSpeedTestWithSmsLineIdArgs = {
  smsLineId: Scalars['Int'];
};


export type MutationToggleSpeedTestLineArgs = {
  smsLineId: Scalars['Int'];
  swt: Scalars['Boolean'];
};


export type MutationToggleSpeedTestNumberArgs = {
  testNumberId: Scalars['Int'];
  swt: Scalars['Boolean'];
};


export type MutationUpdateSpeedTestLineApiKeyArgs = {
  smsLineId: Scalars['Int'];
  apiKey: Scalars['String'];
};


export type MutationUpdateSpeedTestNumberArgs = {
  testNumberId: Scalars['Int'];
  number: Scalars['String'];
};


export type MutationCreateUserArgs = {
  email: Scalars['String'];
  pw: Scalars['String'];
};


export type MutationCreateApiKeyArgs = {
  userId: Scalars['Int'];
};


export type MutationCreateClientPermissionArgs = {
  input: CreateClientPermission;
};


export type MutationUpdateUserMemoArgs = {
  userId: Scalars['Int'];
  memo: Scalars['String'];
};


export type MutationUpdateUserActivateArgs = {
  userId: Scalars['Int'];
  isActivate: Scalars['Boolean'];
};


export type MutationUpdateUserPermissionArgs = {
  input: UpdateUserPermission;
  otp: Scalars['String'];
};


export type MutationUpdateUserPermissionActivateArgs = {
  apiKey: Scalars['String'];
  isActivate: Scalars['Boolean'];
  testSend: Scalars['Boolean'];
};


export type MutationUpdateUserPermissionSmsLineArgs = {
  apiKey: Scalars['String'];
  input: Array<UpdateUserPermissionSmsLine>;
};


export type MutationSendBulkSmsArgs = {
  input: SendBulkSms;
};


export type MutationSendTemplateSmsArgs = {
  input: SendTemplateSms;
};


export type MutationUpdateClientPermissionArgs = {
  input: UpdateClientPermission;
};


export type MutationCreateUserCategoryArgs = {
  input: CreateUserCategory;
};


export type MutationUpdateUserCategoryArgs = {
  input: UpdateUserCategory;
};


export type MutationUpdateUserCouponArgs = {
  userId: Scalars['Int'];
  values: Array<UpdateUserCoupon>;
};

export type TemplateSms = {
  msg: Scalars['String'];
  number: Scalars['String'];
};

export enum SmsRequestType {
  Request = 'Request',
  Queue = 'Queue',
  Done = 'Done',
  Fail = 'Fail',
  NotEnoughPoint = 'NotEnoughPoint',
  Sending = 'Sending'
}

export type IssuePoint = {
  userId: Scalars['Int'];
  point: Scalars['Decimal'];
  memo: Scalars['String'];
  otp: Scalars['String'];
};

export type SpeedTestLineGraph = {
  __typename?: 'SpeedTestLineGraph';
  smsLineId: Scalars['Int'];
  smsLineNm: Scalars['String'];
  list: Array<SpeedTestLineGraphData>;
};

export type SpeedTestLineGraphData = {
  __typename?: 'SpeedTestLineGraphData';
  sentAt: Scalars['Time'];
  avgDelay: Scalars['Int'];
};

export type SendDetailReportList = {
  __typename?: 'SendDetailReportList';
  size: Scalars['Int'];
  page: Scalars['Int'];
  total: Scalars['Int'];
  list: Array<SendDetailReport>;
};

export type CreateUserCategory = {
  isActivate: Scalars['Boolean'];
  nm: Scalars['String'];
};

export type UserCategory = {
  __typename?: 'UserCategory';
  id: Scalars['Int'];
  isActivate: Scalars['Boolean'];
  nm: Scalars['String'];
};

export type ClientPermissionApiKey = {
  __typename?: 'ClientPermissionApiKey';
  apiKey: Scalars['String'];
  nm: Scalars['String'];
  defaultUserSmsFee: Scalars['Decimal'];
  systemDefault: Scalars['Boolean'];
  isDefault: Scalars['Boolean'];
};

export type EsRequestStatus = {
  __typename?: 'EsRequestStatus';
  requestId: Scalars['String'];
  options: Scalars['String'];
  deduplicating: EsStatusValues;
  sorting: EsStatusValues;
  extracting: EsStatusValues;
  filePath: Scalars['String'];
  requestAt: Scalars['Time'];
};

export type SmsLineSend = {
  __typename?: 'SmsLineSend';
  smsLineId: Scalars['Int'];
  smsLineName: Scalars['String'];
  totalSend: Scalars['Int'];
  unchecked: Scalars['Int'];
  undelivered: Scalars['Int'];
  delivered: Scalars['Int'];
  fail: Scalars['Int'];
};

export type TelNumber = {
  __typename?: 'TelNumber';
  number: Scalars['String'];
  status: TelNumberStatus;
  smsLine: SmsLineType;
};

export type SendBulkSms = {
  apiKey: Scalars['String'];
  msg: Scalars['String'];
  numbers: Array<Scalars['String']>;
};


export type ReceiverTransferStatus = {
  __typename?: 'ReceiverTransferStatus';
  isProcessing: Scalars['Boolean'];
  totalSmsRequest: Scalars['Int'];
  doneSmsRequest: Scalars['Int'];
  lastUpdatedTimeDay: Scalars['Int'];
  requestTimeDay: Scalars['Int'];
};

export enum SmsSenderStatus {
  Done = 'Done',
  Fail = 'Fail',
  Delivered = 'Delivered',
  Undelivered = 'Undelivered'
}

export type Period = {
  stDate: Scalars['Time'];
  edDate: Scalars['Time'];
};

export type SmsLineSendReport = {
  __typename?: 'SmsLineSendReport';
  stDate: Scalars['Time'];
  edDate: Scalars['Time'];
  totalSend: Scalars['Int'];
  unchecked: Scalars['Int'];
  undelivered: Scalars['Int'];
  delivered: Scalars['Int'];
  fail: Scalars['Int'];
  report: Array<SmsLineSend>;
};

export type ClientPermission = {
  __typename?: 'ClientPermission';
  id: Scalars['Int'];
  clientApiKey: Scalars['String'];
  permissions: Array<ClientPermissionApiKey>;
  tableNm: Scalars['String'];
  isActivate: Scalars['Boolean'];
  adminFb: Scalars['String'];
  clientFb: Scalars['String'];
  env: Scalars['String'];
  devMode: Scalars['Boolean'];
  appNm: Scalars['String'];
  dbSchemaNm: Scalars['String'];
};

export type SmsLineDailyUsage = {
  __typename?: 'SmsLineDailyUsage';
  smsLineId: Scalars['Int'];
  smsLineType: SmsLineType;
  list: Array<DailyUsage>;
};

export type BindSmsLine = {
  id: Scalars['Int'];
  isActivate: Scalars['Boolean'];
};

export type UpdateUserPermission = {
  apiKey: Scalars['String'];
  nm: Scalars['String'];
  smsFee: Scalars['Decimal'];
};

export type UpdateClientPermission = {
  id: Scalars['Int'];
  isActivate: Scalars['Boolean'];
  adminFb: Scalars['String'];
  clientFb: Scalars['String'];
  env: Scalars['String'];
  devMode: Scalars['Boolean'];
  appNm: Scalars['String'];
  apiKeys: Array<UpdateClinetPermissionApiKey>;
};

export type UserList = {
  __typename?: 'UserList';
  page: Scalars['Int'];
  size: Scalars['Int'];
  total: Scalars['Int'];
  list: Array<User>;
};


export type DailyUsage = {
  __typename?: 'DailyUsage';
  day: Scalars['Time'];
  usage: Scalars['Int'];
};

export type UserSmsLine = {
  __typename?: 'UserSmsLine';
  id: Scalars['Int'];
  smsLine: SmsLine;
  rate: Scalars['Int'];
};


export type SmsLineBind = {
  __typename?: 'SmsLineBind';
  id: Scalars['Int'];
  name: Scalars['String'];
  sendType: SendType;
  tps: Scalars['Int'];
  senderApi: Scalars['Boolean'];
  moduleApi: Scalars['Boolean'];
};

export type User = {
  __typename?: 'User';
  id: Scalars['Int'];
  email: Scalars['String'];
  point: Scalars['Decimal'];
  isActivate: Scalars['Boolean'];
  memo: Scalars['String'];
  permission: Array<UserPermission>;
  clientPermission: Array<ClientPermission>;
};


export type EsApiStatus = {
  __typename?: 'EsApiStatus';
  isProcessing: Scalars['Boolean'];
  requestStatus?: Maybe<EsRequestStatus>;
};

export type SpeedTestTotal = {
  __typename?: 'SpeedTestTotal';
  sent: Scalars['Int'];
  received: Scalars['Int'];
};

export type UserClientSendReport = {
  __typename?: 'UserClientSendReport';
  id: Scalars['String'];
  sendType: SmsRequestType;
  clientEmail: Scalars['String'];
  msg: Scalars['String'];
  request: Scalars['Int'];
  sent: Scalars['Int'];
  fail: Scalars['Int'];
  shortApiKey: Scalars['String'];
  createdAt: Scalars['Time'];
};

export type SmsLine = {
  __typename?: 'SmsLine';
  id: Scalars['Int'];
  smsLineType: SmsLineType;
  username: Scalars['String'];
  host: Scalars['String'];
  password: Scalars['String'];
  isActivate: Scalars['Boolean'];
};

export type CreateClientPermission = {
  apiKey: Scalars['String'];
  nm: Scalars['String'];
  appNm: Scalars['String'];
  dbSchemaNm: Scalars['String'];
  adminFb: Scalars['String'];
  clientFb: Scalars['String'];
  env: Scalars['String'];
};

export type UserPermission = {
  __typename?: 'UserPermission';
  apiKey: Scalars['String'];
  isActivate: Scalars['Boolean'];
  testSend: Scalars['Boolean'];
  smsLine: Array<UserSmsLine>;
  nm: Scalars['String'];
  smsFee: Scalars['Decimal'];
};

export enum TelNumberStatus {
  Sent = 'Sent',
  Fail = 'Fail',
  ParsingError = 'ParsingError',
  DlrDelivered = 'DlrDelivered',
  DlrUndlivered = 'DlrUndlivered',
  DlrUnknown = 'DlrUnknown'
}

export type SpeedTestLine = {
  __typename?: 'SpeedTestLine';
  smsLineId: Scalars['Int'];
  smsLineNm: Scalars['String'];
  hasTest: Scalars['Boolean'];
  apiKey: Scalars['String'];
  isActivated: Scalars['Boolean'];
};

export type SmsTestDetail = {
  __typename?: 'SmsTestDetail';
  id: Scalars['Int'];
  phoneNumber: Scalars['String'];
  company: SmsCompany;
  isSent: Scalars['Boolean'];
  isReceived: Scalars['Boolean'];
  sentAt: Scalars['Time'];
  receivedAt?: Maybe<Scalars['Time']>;
  sender?: Maybe<Scalars['String']>;
  delay?: Maybe<Scalars['Int']>;
};

export enum ClientRechargeRequestStatus {
  Requested = 'Requested',
  Allow = 'Allow',
  Deny = 'Deny',
  UserCancel = 'UserCancel'
}

export type SmsCompanyDailyUsage = {
  __typename?: 'SmsCompanyDailyUsage';
  smsCompany: Scalars['String'];
  list: Array<DailyUsage>;
};

export type ClientRechargeReqeustList = {
  __typename?: 'ClientRechargeReqeustList';
  page: Scalars['Int'];
  size: Scalars['Int'];
  total: Scalars['Int'];
  list: Array<ClientRechargeRequest>;
};
