import {combineReducers} from "redux";
import {I18nReducer} from "./I18n";
import {UserReducer} from "./User";
import {SystemReducer} from "./System";
import {LayoutReducer} from "./Layout";
import {AlertReducer} from "./Alert";


export const RootReducer = combineReducers({
    i18n: I18nReducer,
    user: UserReducer,
    system: SystemReducer,
    layout: LayoutReducer,
    alert: AlertReducer,
});
