import React, {useEffect, useState} from "react";
import classNamesBind from "classnames/bind";
import styles from './SettleMonthlyList.module.scss'
import {ClientRechargeRequestReceipt} from "../../../../graphql/types";
import {Pipe} from "../../../../utils/pipe/pipe";
import Decimal from "decimal.js";
import {useSelector} from "react-redux";
import {LayoutState} from "../../../../redux/Layout";
import ScrollArea from "react-scrollbar";

const cx = classNamesBind.bind(styles);

interface Total {
    amount: number,
    taxAmount: number,
    smsCount: number,
    profit: number
}

export const SettleMonthlyList = (props: {
    list: ClientRechargeRequestReceipt[],
    originFee: number
}) => {
    const [total, setTotal] = useState<Total>({
        amount: 0, profit: 0, smsCount: 0, taxAmount: 0
    });

    const [scrollAreaHeight, setScrollAreaHeight] = useState<any>();
    const [scrollAreaWidth, setScrollAreaWidth] = useState<any>();
    const [scrollAreaContent, setScrollAreaContent] = useState<any>();

    const layout = useSelector<any, LayoutState>(state => state.layout);

    useEffect(() => {
        if (layout.viewMode === 0) {
            setScrollAreaHeight("auto");
            setScrollAreaWidth("auto");
            setScrollAreaContent("auto");
        } else {
            setScrollAreaHeight(285);
            setScrollAreaWidth(315);
            setScrollAreaContent(styles.scrollTableWidthForMobile);
        }
    }, [layout.viewMode]);

    useEffect(() => {
        let amount = new Decimal(0);
        let taxAmount = new Decimal(0);
        let smsCount = new Decimal(0);
        let profit = new Decimal(0);

        for (let item of props.list) {
            amount = amount.add(item.amount);
            taxAmount = taxAmount.add(item.taxAmount);
            smsCount = smsCount.add(item.smsCount);
            profit = profit.add(new Decimal(item.smsFee)
                .sub(props.originFee)
                .mul(item.smsCount)
                .add(item.taxAmount))
        }


        setTotal({
            amount: amount.toNumber(),
            taxAmount: taxAmount.toNumber(),
            smsCount: smsCount.toNumber(),
            profit: profit.toNumber(),
        })
    }, [props])

    return (
        <>
            <ScrollArea
                style={{height: scrollAreaHeight, width: scrollAreaWidth}}
                contentStyle={{width: scrollAreaContent}}
                smoothScrolling={true}
            >
                <div className={cx('table', "scrollTable")}>
                    <div className={cx('header', "emailCell")}>이메일</div>
                    <div className={cx('header', "taxRateCell")}>수수료 비율</div>
                    <div className={cx('header', 'smsFeeCell')}>건당가격</div>
                    <div className={cx('header', 'originFeeCell')}>원가</div>
                    <div className={cx('header', 'amountCell')}>입금총액</div>
                    <div className={cx('header', 'taxAmountCell')}>수수료</div>
                    <div className={cx('header', 'smsCountCell')}>충전갯수</div>
                    <div className={cx('header', 'profitCell')}>수익</div>
                </div>

                {props.list.length === 0 &&
                <div className={cx("table")}>
                    <div className={cx("col")} style={{width: '100%'}}>데이터가 없습니다.</div>
                </div>
                }

                {props.list.map((value, index) => (
                    <div key={index} className={cx('table', 'hover', "scrollTable")}>
                        <div className={cx('col', "emailCell")}>{value.clientEmail}</div>
                        <div className={cx('col', "taxRateCell", "textRight")}>
                            {new Decimal(value.taxRate).mul(100).toNumber()} %
                        </div>
                        <div className={cx('col', "smsFeeCell", "textRight")}>
                            {Pipe.toSeparatorNumber(value.smsFee)} 원
                        </div>
                        <div className={cx('col', "originFeeCell", "textRight")}>
                            {props.originFee} 원
                        </div>
                        <div className={cx('col', "amountCell", "textRight")}>
                            {Pipe.toSeparatorNumber(value.amount)} 원
                        </div>
                        <div className={cx('col', "taxAmountCell", "textRight")}>
                            {Pipe.toSeparatorNumber(value.taxAmount)} 원
                        </div>
                        <div className={cx('col', "smsCountCell", "textRight")}>
                            {Pipe.toSeparatorNumber(value.smsCount)} 개
                        </div>
                        <div className={cx('col', "profitCell", "textRight")}>
                            {Pipe.toSeparatorNumber(
                                new Decimal(value.smsFee)
                                    .sub(props.originFee)
                                    .mul(value.smsCount)
                                    .add(value.taxAmount)
                                    .toNumber()
                            )} 원
                        </div>
                    </div>
                ))}

                <div className={cx('table', "scrollTable")}>
                    <div className={cx('header', "sumCell")}>합계</div>
                    <div className={cx("col", "amountCell", "textRight")}>
                        {Pipe.toSeparatorNumber(total.amount)} 원
                    </div>
                    <div className={cx("col", "taxAmountCell", "textRight")}>
                        {Pipe.toSeparatorNumber(total.taxAmount)} 원
                    </div>
                    <div className={cx("col", "smsCountCell", "textRight")}>
                        {Pipe.toSeparatorNumber(total.smsCount)} 개
                    </div>
                    <div className={cx("col", "profitCell", "textRight")}>
                        {Pipe.toSeparatorNumber(total.profit)} 원
                    </div>
                </div>

            </ScrollArea>
        </>
    )
}
