import React, {useEffect} from "react";
import {LoadingView} from "./shares/loadingView/LoadingView";
import {useDispatch, useSelector} from "react-redux";
import {LayoutState} from "../redux/Layout";
import {UserState} from "../redux/User";
import {HookRouter, useRoutes} from "hookrouter";
import {NotAuthLayoutWeb} from "./layout/notAuth/NotAuthLayoutWeb";
import {Dashboard as DB} from './dashboard/Dashboard';
import {
    AddToHomeScreen,
    Assessment, AttachMoney,
    BrightnessLow, CategoryOutlined,
    Dashboard,
    DirectionsRun, LocalAtmOutlined, MenuBookOutlined, Payment,
    Textsms,
    TrendingUpOutlined
} from "@material-ui/icons";
import {AuthJoin} from "./auth/join/AuthJoin";
import {AuthLogin} from "./auth/login/AuthLogin";
import {NotFound} from "./system/notFound/NotFound";
import {SidebarMenu} from "./layout/auth/sidebar/LayoutSidebar";
import {LayoutAction, ViewMode} from "../redux/Layout";
import {AuthLayout} from "./layout/auth/AuthLayout";
import {MngUserCreate} from "./mngUser/create/MngUserCreate";
import {MngUserView} from "./mngUser/view/MngUserView";
import {SmsLine} from "./smsLine/SmsLine";
import {MsgHistory} from "./msgHistory/MsgHistory";
import {Validator} from "../utils/validator/Validator";
import {SpeedTestList} from "./speedTest/list/SpeedTestList";
import {SpeedTestConfig} from "./speedTest/config/SpeedTestConfig";
import gql from "graphql-tag";
import {useLazyQuery} from "@apollo/client";
import {DelayedSmsLine} from "../graphql/types";
import {AlertAction} from "../redux/Alert";
import {TelBookCategory} from "./telBook/category/TelBookCategory";
import {PointRequest} from "./point/request/PointRequest";
import {SettleMonthly} from "./settle/monthly/SettleMonthly";
import {Pipe} from "../utils/pipe/pipe";
import {GlobalVars} from "../globalVars/globalVars";
import {SmsLineBalanceList} from "./smsLineBalance/list/SmsLineBalanceList";
import {TelBookSummary} from "./telBook/summary/TelBookSummary";

export const App = () => {
    const layout = useSelector<any, LayoutState>(state => state.layout);
    const user = useSelector<any, UserState>(state => state.user);
    const [getData, resData] = useLazyQuery<ResData>(gqlData, {
        pollInterval: 5000,
    });
    const dispatch = useDispatch();
    const routes = user.isLogin ? AuthRoutes : NotAuthRoutes;
    const child = useRoutes(routes);

    useEffect(() => {
        if (!resData.data) {
            return;
        }
        dispatch(AlertAction.setDelayedSmsLine(resData.data.alertDelaySmsLine));
    }, [resData.data]);


    useEffect(() => {
        if ((user.isLogin) && (layout.viewMode === ViewMode.Web)) {
            dispatch(LayoutAction.setSidebar(true))
        } else {
            dispatch(LayoutAction.setSidebar(false))
        }

        if (user.isLogin) {
            getData();
        } else {
            if (resData.stopPolling) {
                resData.stopPolling();
            }
        }
    }, [user.isLogin]);

    return (
        <>
            {user.isLogin &&
            <AuthLayout>
                {child || <NotFound/>}
            </AuthLayout>
            }

            {!user.isLogin &&
            <NotAuthLayoutWeb>
                {child || <NotFound/>}
            </NotAuthLayoutWeb>
            }

            <LoadingView isActivate={layout.loadingView}/>
        </>
    );
}

interface ResData {
    alertDelaySmsLine: DelayedSmsLine[],
}

const gqlData = gql`
    query Alert {
        alertDelaySmsLine(minute: 1) {
            reportId
            smsLineId
            smsLineNm
            delay
            createdAt
        }
    }
`;


const AuthRoutes: HookRouter.RouteObject = {
    '/': params => <DB/>,
    '/user': params => <MngUserCreate/>,
    '/user/:id': ({id}) => <MngUserView id={id}/>,
    '/smsLine': () => <SmsLine/>,
    '/balance': () => <SmsLineBalanceList/>,
    '/msg-history': () => <MsgHistory page={0}/>,
    '/msg-history/:page': ({page}) => <MsgHistory page={Validator.isNumber(page)}/>,
    '/msg-history/:page/:stDate/:edDate': ({page, stDate, edDate}) =>
        <MsgHistory page={Validator.isNumber(page)} stDate={stDate} edDate={edDate}/>,
    '/speedTest': () => <SpeedTestList page={0}/>,
    '/speedTest/list/:page': ({page}) => <SpeedTestList page={Validator.isNumber(page)}/>,
    '/speedTest/list/:page/:day': ({page, day}) => <SpeedTestList page={Validator.isNumber(page)} day={day}/>,
    '/speedTest/config': () => <SpeedTestConfig/>,
    '/telbook': () => <TelBookCategory/>,
    '/telbook/summary/:page':
        ({page}) => <TelBookSummary page={Validator.isNumber(page)}/>,
    '/telbook/summary':
        () => <TelBookSummary page={0}/>,
    '/point': () =>
        <PointRequest requestPage={0}/>,
    '/point/request': () =>
        <PointRequest requestPage={0}/>,
    '/point/request/:requestPage': ({requestPage}) =>
        <PointRequest requestPage={Validator.isNumber(requestPage)}/>,

    '/settle': () =>
        <SettleMonthly timeMonth={Pipe.toTimeMonth(new Date())}/>,
    '/settle/monthly/:timeMonth': ({timeMonth}) =>
        <SettleMonthly timeMonth={Validator.isNumber(timeMonth)}/>,
}

const NotAuthRoutes: HookRouter.RouteObject = {
    '/': params => <AuthLogin/>,
    '/join': params => <AuthJoin/>,
}

export const Sidebar: SidebarMenu[] = [
    {
        icon: style => <Dashboard style={style}/>, title: '대쉬보드', router: '/',
        topNav: []
    },
    {
        icon: style => <Textsms style={style}/>, title: '회원관리', router: '/user',
        topNav: []
    },
    {
        icon: style => <Payment style={style}/>, title: '정산', router: '/settle',
        topNav: []
    },
    {
        icon: style => <AttachMoney style={style}/>, title: '충전관리', router: '/point',
        topNav: []
    },
    {
        icon: style => <TrendingUpOutlined style={style}/>, title: '회선관리', router: '/smsLine',
        topNav: []
    },
    {
        icon: style => <LocalAtmOutlined style={style}/>, title: '회선잔고', router: '/balance',
        topNav: []
    },
    {
        icon: style => <MenuBookOutlined style={style}/>, title: '전화번호부', router: '/telbook',
        topNav: [
            {icon: <CategoryOutlined/>, title: '카테고리', router: '/telbook'},
            {icon: <MenuBookOutlined/>, title: '전화번호부', router: '/telbook/summary'}
        ]
    },
    {
        icon: style => <DirectionsRun style={style}/>, title: '속도측정', router: '/speedTest',
        topNav: [
            {icon: <AddToHomeScreen/>, title: '테스트', router: '/speedTest'},
            {icon: <BrightnessLow/>, title: '설정', router: '/speedTest/config'},
        ]
    },
    {
        icon: style => <Assessment style={style}/>, title: '전송기록', router: '/msg-history',
        topNav: []
    },
];

export class LoadingViewUtil {
    static loadingView(isShow: boolean) {
        GlobalVars.layout.loadingView(isShow);
    };

    static loading(promise: Promise<any>) {
        GlobalVars.layout.loadingView(true);
        promise.then(value => {
            GlobalVars.layout.loadingView(false);
        }).catch(e => {
            GlobalVars.layout.loadingView(false);
        });
    };
}
