import React, {Fragment, ReactNode} from "react";
import classNamesBind from "classnames/bind";
import styles from './CenterContents.module.scss'
import {useSpring, animated} from "react-spring";

const cx = classNamesBind.bind(styles);

export const CenterContents = (props: {
    children: ReactNode,
    animatedContents?: boolean
}) => {
    const [contentsProps, setContentsProps] = useSpring(() => ({opacity: props.animatedContents ? 0 : 1}));
    setContentsProps({opacity: 1});


    return (
        <div className={cx('cont')}>
            <animated.div style={{...contentsProps, width: "100%"}}>
                {props.children}
            </animated.div>
        </div>
    )
}
