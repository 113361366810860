import React from "react";
import classNamesBind from "classnames/bind";
import styles from './AuthJoinView.module.scss';
import {A} from "hookrouter";
import {CenterContents} from "../../../shares/centerContents/CenterContents";
import {AuthJoinFunc, AuthJoinState} from "../AuthJoin";

const cx = classNamesBind.bind(styles);

export const AuthJoinView = (props: {
    state: AuthJoinState,
    func: AuthJoinFunc,
}) => {
    const state = Object.assign({}, props.state);

    return (
        <CenterContents animatedContents>
            <form className={cx('box-join')} onSubmit={event => {
                event.preventDefault();
                props.func.join();
            }}>
                <h3 className={cx("logo")}>Join</h3>
                <p className={cx("item")}>Email</p>
                <input
                    onChange={event => {
                        state.email = event.target.value;
                        props.func.setState(state);
                    }}
                    value={state.email} className="form-control" type="email"
                    placeholder='이메일을 입력하여 주십시오.' required/>

                <p className={cx("item")}>Password</p>
                <input
                    onChange={event => {
                        state.pw = event.target.value;
                        props.func.setState(state);
                    }}
                    value={state.pw}
                    className="form-control"
                    type="password"
                    placeholder='비밀번호를 입력하여 주십시오.' required/>

                <p className={cx("item")}>Confirm</p>
                <input
                    onChange={event => {
                        state.confirm = event.target.value;
                        props.func.setState(state);
                    }}
                    value={state.confirm}
                    className="form-control"
                    type="password"
                    placeholder='비밀번호를 다시 입력하여 주십시오.' required/>

                <hr/>

                <button
                    type="submit"
                    className="btn btn-primary btn-block">
                    회원가입
                </button>

                <div style={{height: 40}}/>

                <div style={{height: 10}}/>
                <p style={{marginRight: 10}}>
                    이미 회원이십니까?
                    <A href={'/'} style={{paddingLeft: 10}}>
                        로그인
                    </A>
                </p>
            </form>
        </CenterContents>
    )
}
