import React, {ReactNode, useEffect} from 'react';
import classNamesBind from "classnames/bind";
import styles from './LayoutSidebar.module.scss';
import {A, navigate, usePath} from 'hookrouter';
import {Spring} from "react-spring/renderprops-universal";
import {useDispatch, useSelector} from "react-redux";
import {LayoutAction, LayoutState, ViewMode} from "../../../../redux/Layout";
import {Close, KeyboardArrowRight, PowerSettingsNew} from "@material-ui/icons";
import {Sidebar} from "../../../App";

const cx = classNamesBind.bind(styles);

export const LayoutSidebar = () => {
    const menu = Sidebar;
    const path = usePath();
    const layout = useSelector<any, LayoutState>(state => state.layout);
    const dispatch = useDispatch();

    const color = {
        activated: {
            color: 'rgb(255, 255, 255)',
            background: 'rgba(12,12,32,1)',
            iconColor: 'rgba(99,99,220,1)',
        },
        deactivated: {
            color: 'rgb(109,109,109)',
            background: 'rgba(0,0,0,0)',
            iconColor: 'rgba(69,69,101,1)'
        }
    };

    const setIndex = (index: number) => {
        if (layout.sidebarIndex !== index) {
            dispatch(LayoutAction.setSidebarIndex(index));
        }
    }

    useEffect(() => {
        // 인덱스 초기화
        if (path === '/') {
            setIndex(0);
        } else {
            for (let i = 1; i < menu.length; i++) {
                if (path.includes(menu[i].router)) {
                    setIndex(i);
                    break;
                }
            }
        }

        if (layout.viewMode === ViewMode.Mobile) {
            dispatch(LayoutAction.setSidebar(false));
        }

    }, [path])

    return (
        <>
            <div className={cx('cont')} onClick={ev => {
                navigate('/');
            }}>
                SMS Master
            </div>

            {Sidebar.map((value, index) => {
                const activated = index === layout.sidebarIndex;

                return (
                    <Spring
                        key={index}
                        from={{}}
                        to={activated ? color.activated : color.deactivated}>
                        {param =>
                            <A
                                style={param}
                                href={value.router}
                                className={cx('cont', 'menu')}
                                onClick={event => {
                                    event.preventDefault();
                                    setIndex(index);
                                }}>
                                {value.icon({color: param.iconColor})}
                                <div className={cx('title')}>{value.title}</div>
                                <KeyboardArrowRight style={{color: param.iconColor}}/>
                            </A>}
                    </Spring>

                )
            })}


            {layout.viewMode === ViewMode.Mobile &&
                <>
                    <div
                        className={cx('cont', 'menu')}
                        onClick={event => {
                            event.preventDefault();
                            dispatch(LayoutAction.setSidebar(false));
                        }}>
                        <PowerSettingsNew style={{color: color.deactivated.iconColor}}/>
                        <div className={cx('title')}>로그아웃</div>
                    </div>

                    <div
                        className={cx('cont', 'menu')}
                        onClick={event => {
                            event.preventDefault();
                            dispatch(LayoutAction.setSidebar(false));
                        }}>
                        <Close style={{color: color.deactivated.iconColor}}/>
                        <div className={cx('title')}>메뉴닫기</div>
                    </div>
                </>

            }

        </>
    )
}

export interface SidebarMenu {
    icon: (style: any) => React.ReactNode;
    title: string;
    router: string;
    topNav: {
        icon: ReactNode,
        title: string,
        router: string,
    }[]
}
