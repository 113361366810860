import {I18nNamespace} from "../utils/i18n/i18n";

export const Result: I18nNamespace = {
    titleResult: {
        ko: "전송 결과"
    },
    tbDate: {
        ko: "날짜"
    },
    tbStatus: {
        ko: "상태"
    },
    tbContents: {
        ko: "메시지"
    },
    tbSend: {
        ko: "전송"
    },
    tbFail: {
        ko: "실패"
    },
    tbRest: {
        ko: "잔고"
    },
    noResult: {
        ko: "전송 내역이 없습니다."
    },
    badgeDone: {
        ko: "완료"
    },
    badgeCancel: {
        ko: "취소"
    },
    badgeWait: {
        ko: "대기"
    },
    badgeFail: {
        ko: "실패"
    },
    badgeError: {
        ko: "에러"
    }
}
