import {I18nNamespace} from "../utils/i18n/i18n";

export const Dashboard: I18nNamespace = {
    usedPoint: {
        ko: "사용"
    },
    chargedPoint: {
        ko: "충전완료"
    },
    requestedPoint: {
        ko: "충전요청"
    },
    restPoint: {
        ko: "잔고"
    },
    stdDate: {
        ko: "기준시"
    },
    sendResult: {
        ko: "전송량"
    },
    week: {
        ko: "주",
        en: "W"
    },
    connectionInfo: {
        ko: "API 접속 정보"
    },
    apiPermission: {
        ko: "API 권한"
    },
    badgeNotPermission: {
        ko: "없음"
    },
    badgeWait: {
        ko: "대기"
    },
    badgeOk: {
        ko: "허용"
    }

}
