import React, {useEffect, useState} from "react";
import classNamesBind from "classnames/bind";
import styles from './PointRequestList.module.scss'
import {ClientRechargeRequest, ClientRechargeRequestStatus} from "../../../../graphql/types";
import {BizNoDataTable} from "../../../shares/bizNoDataTable/BizNoDataTable";
import moment from "moment";
import {Pipe} from "../../../../utils/pipe/pipe";
import {BadgeClientRechargeRequestStatus} from "../../../shares/badgeClientRechargeRequestStatus/BadgeClientRechargeRequestStatus";
import {IconButton, Tooltip} from "@material-ui/core";
import {Check, Close} from "@material-ui/icons";
import {OnChangeValue} from "../../../shares/interfaces/interfaces";
import {useSelector} from "react-redux";
import {LayoutState} from "../../../../redux/Layout";
import ScrollArea from "react-scrollbar";

const cx = classNamesBind.bind(styles);

export const PointRequestList = (props: {
    list: ClientRechargeRequest[],
    onAllow: OnChangeValue<number>,
    onDeny: OnChangeValue<number>,
}) => {
    const [scrollAreaHeight, setScrollAreaHeight] = useState<any>();
    const [scrollAreaWidth, setScrollAreaWidth] = useState<any>();
    const [scrollAreaContent, setScrollAreaContent] = useState<any>();

    const layout = useSelector<any, LayoutState>(state => state.layout);

    useEffect(() => {
        if (layout.viewMode === 0) {
            setScrollAreaHeight("auto");
            setScrollAreaWidth("auto");
            setScrollAreaContent("auto");
        } else {
            setScrollAreaHeight(285);
            setScrollAreaWidth(315);
            setScrollAreaContent(styles.scrollTableWidthForMobile);
        }
    }, [layout.viewMode]);

    return (
        <>
            <ScrollArea
                style={{height: scrollAreaHeight, width: scrollAreaWidth}}
                contentStyle={{width: scrollAreaContent}}
                smoothScrolling={true}
            >
                <div className={cx('table')}>
                    <div className={cx('header', "requestedAtCell")}>요청일</div>
                    <div className={cx('header', "statusCell")}>상태</div>
                    <div className={cx('header', "clientEmailCell")}>유저</div>
                    <div className={cx('header', "pointCell")}>포인트</div>
                    <div className={cx('header', "amountCell")}>입금액</div>
                    <div className={cx('header', "taxCell")}>부가세</div>
                    <div className={cx('header', "countCell")}>SMS 갯수</div>
                    <div className={cx('header', "funcCell")}>기능</div>
                </div>

                {props.list.length === 0 && <BizNoDataTable/>}

                {props.list.map((value, index) => (
                    <div key={index} className={cx('table')}>
                        <div className={cx('col', "requestedAtCell")}>{moment(value.createdAt).format('YY.MM.DD')}</div>
                        <div className={cx('col', "statusCell")}>
                            <BadgeClientRechargeRequestStatus
                                status={value.status}/>
                        </div>
                        <div className={cx('col', "clientEmailCell", 'ellipsis')}>{value.clientEmail}</div>
                        <div className={cx('col', "pointCell", "textRight")}>{Pipe.toSeparatorNumber(value.point)} P
                        </div>
                        <div className={cx('col', "amountCell", "textRight")}>{Pipe.toSeparatorNumber(value.amount)} 원
                        </div>
                        <div className={cx('col', "taxCell", "textRight")}>{Pipe.toSeparatorNumber(value.taxAmount)} 원
                        </div>
                        <div className={cx('col', "countCell", "textRight")}>
                            {Pipe.toSeparatorNumber(value.smsCount)} 개
                        </div>
                        <div className={cx('col', "funcCell")}>
                            {value.status === ClientRechargeRequestStatus.Requested &&
                            <>
                                <Tooltip title={'승인'}>
                                    <IconButton onClick={() => props.onAllow(value.id)}>
                                        <Check/>
                                    </IconButton>
                                </Tooltip>

                                <Tooltip title={'반려'}>
                                    <IconButton onClick={() => props.onDeny(value.id)}>
                                        <Close/>
                                    </IconButton>
                                </Tooltip>
                            </>
                            }
                        </div>
                    </div>
                ))}
            </ScrollArea>
        </>
    )
}
