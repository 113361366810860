import React, {useEffect, useState} from "react";
import classNamesBind from "classnames/bind";
import styles from './NotAuthTopWeb.module.scss'
import {navigate} from "hookrouter";
import {useDispatch, useSelector} from "react-redux";
import {
    AppBar,
    Button,
    IconButton,
    SwipeableDrawer,
    Toolbar,
    Typography
} from "@material-ui/core";
import {LayoutState, ViewMode} from "../../../../redux/Layout";
import {useTranslation} from "react-i18next";
import {Menu} from "@material-ui/icons";
import {I18nState} from "../../../../redux/I18n";

const cx = classNamesBind.bind(styles);

export const NotAuthTopWeb = () => {
    const layout = useSelector<any, LayoutState>(state => state.layout);
    const i18n = useSelector<any, I18nState>(state => state.i18n);
    const [drawer, setDrawer] = useState(false);
    const [btnAnchor, setBtnAnchor] = useState<any>(null);
    const [btnLangShow, setBtnLangShow] = useState(false);
    const dispatch = useDispatch();
    const [t] = useTranslation('common');

    useEffect(() => {
        setDrawer(false);
    }, [layout.viewMode]);

    return (
        <>
            <AppBar className={cx('appbar')} position='fixed'>
                <Toolbar>
                    <Typography onClick={event => {
                        navigate('/');
                    }}>
                        ClientSmsSender
                    </Typography>
                    <div className={cx('space')}/>

                    {layout.viewMode === ViewMode.Web &&
                    <>
                        <Button
                            className={cx('btn')}
                            variant='text' color='default'
                            onClick={event => {
                                navigate('/')
                            }}>
                            로그인
                        </Button>
                        <Button
                            className={cx('btn')}
                            variant='text' color='default'
                            onClick={event => {
                                navigate('/join')
                            }}>
                            회원가입
                        </Button>
                    </>
                    }

                   {/* <Button
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        className={cx('btn')}
                        onClick={event => {
                            setBtnLangShow(true);
                            setBtnAnchor(event.currentTarget);
                        }}>
                        {i18n.langCode}
                    </Button>
                    <MenuButton
                        id="simple-menu"
                        keepMounted
                        anchorEl={btnAnchor}
                        open={btnLangShow}
                        onClose={() => {
                            setBtnLangShow(false);
                        }}>
                        {i18n.langList.map((value, index) => {
                            return (
                                <MenuItem key={index} onClick={() => {
                                    setBtnLangShow(false);
                                    dispatch(I18nAction.setLangCode(value.code));
                                }}>
                                    <span style={{marginRight: 10}} className={`flag-icon ${value.flag}`}/>
                                    {value.nm}</MenuItem>
                            )
                        })}
                    </MenuButton>*/}

                    {layout.viewMode === ViewMode.Mobile &&
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        className={cx('btn')}
                        onClick={event => {
                            setDrawer(true);
                        }}>
                        <Menu/>
                    </IconButton>
                    }
                </Toolbar>
            </AppBar>

            <SwipeableDrawer
                classes={{root: cx('drawer')}}
                anchor="right"
                onClose={() => {
                    setDrawer(false);
                }}
                onOpen={() => {
                    setDrawer(false);
                }}
                open={drawer}>

                <div className={cx('cont-drawer')}>
                    <div
                        className={cx('btn-drawer')}
                        onClick={event => {
                            navigate("/");
                            setDrawer(false);
                        }}>
                        {t('layout.login')}
                    </div>
                    <div
                        className={cx('btn-drawer')}
                        onClick={event => {
                            navigate("/join");
                            setDrawer(false);
                        }}>
                        {t('layout.join')}
                    </div>
                </div>
            </SwipeableDrawer>
        </>
    )
}
