import {I18nNamespace} from "../utils/i18n/i18n";

export const CS: I18nNamespace = {
    changeLineTitle: {
        ko: "회선변경 신청"
    },
    changeLineDes: {
        ko: "* 회선 변경시 일정 비율에 따라 잔여 포인트가 변경됩니다."
    },
    usedLine: {
        ko: "사용중 회선"
    },
    wholesale: {
        ko: "도매"
    },
    premium: {
        ko: "프리미엄"
    },
    direct: {
        ko: "다이렉트"
    },
    changeLine: {
        ko: "변경 회선"
    },
    request: {
        ko: "신청"
    },
    noticeTitle: {
        ko: "공지사항"
    },
    tbTitle: {
        ko: "제목"
    },
    tbDate: {
        ko: "날짜"
    },
    notFoundNotice: {
        ko: "공지사항이 없습니다."
    }
}
