import {I18nNamespace} from "../utils/i18n/i18n";

export const Layout: I18nNamespace = {
    intro: {
        ko: "소개",
        en: "Intro"
    },
    login: {
        ko: "로그인",
        en: "Login"
    },
    join: {
        ko: "회원가입",
        en: "Join"
    },
    logout: {
        ko: "로그아웃"
    },
    closeMenu: {
        ko: "닫기"
    },
    ynLogout: {
        ko: "로그아웃 하시겠습니까?",
    },
    sideDashboard: {
        ko: "대쉬보드"
    },
    sideSend: {
        ko: "문자전송"
    },
    sideResult: {
        ko: "전송결과"
    },
    sideRequest: {
        ko:"충전요청"
    },
    sideCs: {
        ko: "고객센터"
    },
    sideApiDocument: {
        ko: "API 문서"
    }
}
