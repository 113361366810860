import React, {useEffect, useState} from "react";
import classNamesBind from "classnames/bind";
import styles from './SmsLine.module.scss'
import gql from "graphql-tag";
import {useLazyQuery, useMutation} from "@apollo/client";
import {BindSmsLine, SmsLineBind} from "../../graphql/types";
import {FadeInContainer} from "../shares/fadeInContainer/FadeInContainer";
import {BoxCont} from "../shares/boxCont/BoxCont";
import {Title} from "../shares/title/Title";
import {BadgeIsActivate} from "../shares/badgeIsActivate/BadgeIsActivate";
import {Switch} from "@material-ui/core";
import {CopyUtil} from "../../utils/copy/CopyUtil";
import {SwalUtil} from "../../utils/swal/swalUtil";
import {ApolloCatch} from "../../utils/apollo/apolloExec";
import {BizErrors} from "../../graphql/errors";
import {LoadingViewUtil} from "../App";
import {useSelector} from "react-redux";
import {LayoutState} from "../../redux/Layout";
import ScrollArea from "react-scrollbar";

const cx = classNamesBind.bind(styles);

export const SmsLine = () => {
    const [getData, resData] = useLazyQuery<ResData, {}>(gqlData);
    const [data, setData] = useState<ResData | undefined>();
    const [input, setInput] = useState<BindSmsLine[] | undefined>();
    const [mutResetBind] = useMutation<{
        bindSmsLine: boolean
    }, {
        input: BindSmsLine[],
        otp: string
    }>(gqlResetBind);

    const [scrollAreaHeight, setScrollAreaHeight] = useState<any>();
    const [scrollAreaWidth, setScrollAreaWidth] = useState<any>();
    const [scrollAreaContent, setScrollAreaContent] = useState<any>();

    const layout = useSelector<any, LayoutState>(state => state.layout);

    useEffect(() => {
        if (layout.viewMode === 0) {
            setScrollAreaHeight("auto");
            setScrollAreaWidth("auto");
            setScrollAreaContent("auto");
        } else {
            setScrollAreaHeight(600);
            setScrollAreaWidth(315);
            setScrollAreaContent(styles.scrollTableWidthForMobile);
        }
    }, [layout.viewMode]);

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        if (resData.data) {
            setData(resData.data);
            const newInput: BindSmsLine[] = resData.data.smsLineBind.map(value => {
                return {
                    id: value.id,
                    isActivate: value.senderApi
                }
            })
            setInput(newInput);
        }
    }, [resData.data])

    useEffect(() => {
        LoadingViewUtil.loadingView(resData.loading);
    }, [resData.loading]);


    const onSave = () => {
        if (!input) {
            return
        }

        SwalUtil.otp(otp => {
            mutResetBind({
                variables: {
                    input, otp
                }
            })
                .then(res => {
                    SwalUtil.ok({
                        msg: '변경 되었습니다.',
                        icon: "success",
                    })
                    getData();
                })
                .catch(ApolloCatch({
                    [BizErrors.default]: SwalUtil.bizError()
                }))

        })
    }

    return (
        <>
            {data && input &&
            <FadeInContainer>
                <div className='col-12'>
                    <BoxCont>
                        <Title>연결 정보</Title>
                        <ScrollArea
                            style={{height: scrollAreaHeight, width: scrollAreaWidth}}
                            contentStyle={{width: scrollAreaContent}}
                            smoothScrolling={true}
                        >
                            <div className={cx('table')}>
                                <div className={cx("header", "numCell")}>번호</div>
                                <div className={cx("header", "activateCell")}>활성화</div>
                                <div className={cx("header", "tpsCell")}>TPS</div>
                                <div className={cx("header", "nameCell")}>이름</div>
                                <div className={cx("header", "typeCell")}>전송타입</div>
                                <div className={cx("header", "sendAPICell")}>전송API</div>
                                <div className={cx("header", "moduleAPICell")}>모듈API</div>
                            </div>

                            {data.smsLineBind.map((value, index) => {
                                return (
                                    <div className={cx('table', 'hover')} key={index}>
                                        <div className={cx("col", "numCell")}>{value.id}</div>
                                        <div className={cx("col", "activateCell")}>
                                            <Switch
                                                color="primary"
                                                checked={input[index].isActivate}
                                                onChange={(event, checked) => {
                                                    const copy = CopyUtil.copy(input);
                                                    copy[index].isActivate = checked
                                                    setInput(copy);
                                                }}/>
                                        </div>
                                        <div className={cx("col", "tpsCell")}>{value.tps}</div>
                                        <div className={cx("col", "nameCell")}>{value.name}</div>
                                        <div className={cx("col", "typeCell")}>{value.sendType}</div>
                                        <div className={cx("col", "sendAPICell")}>
                                            <BadgeIsActivate isActivate={value.senderApi}/>
                                        </div>
                                        <div className={cx("col", "moduleAPICell")}>
                                            <BadgeIsActivate isActivate={value.moduleApi}/>
                                        </div>
                                    </div>
                                )
                            })}

                            <div className={cx('table')}>
                                <div style={{width: '100%'}} className='text-right'>
                                    <button
                                        className='btn btn-sm btn-success'
                                        onClick={onSave}>저장
                                    </button>
                                </div>
                            </div>

                        </ScrollArea>
                    </BoxCont>
                </div>
            </FadeInContainer>

            }
        </>
    )
}

interface ResData {
    smsLineBind: SmsLineBind[];
}


const gqlData = gql`
    query Data {
        smsLineBind {
            id
            name
            sendType
            moduleApi
            senderApi
            tps
        }
    }
`;

const gqlResetBind = gql`
    mutation ResetBind($input: [BindSmsLine!]!, $otp: String!) {
        bindSmsLine(input: $input, otp: $otp)
    }
`
