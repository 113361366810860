import React, {Fragment} from "react";
import styles from './SmsLineBalanceListView.module.scss'
import {SmsLineBalance} from "../../../../graphql/types";
import classNames from "classnames";
import {BizNoDataTable} from "../../../shares/bizNoDataTable/BizNoDataTable";
import {Pipe} from "../../../../utils/pipe/pipe";
import moment from "moment";


export const SmsLineBalanceListView = (props: {
    list : SmsLineBalance[]
}) => {
    return (
        <Fragment>
            <div className={classNames(styles.table, styles.tableBalanceList)}>
                <div className={classNames(styles.col, styles.colId)}>번호</div>
                <div className={classNames(styles.col, styles.colSendType)}>전송타입</div>
                <div className={classNames(styles.col, styles.colUsername)}>아이디</div>
                <div className={classNames(styles.col, styles.colPoint)}>잔고</div>
                <div className={classNames(styles.col, styles.colCurrency)}>단위</div>
                <div className={classNames(styles.col, styles.colUpdatedAt)}>갱신일</div>
            </div>
            {props.list.length === 0 && <BizNoDataTable/>}
            {props.list.map((value, index) => (
                <div key={index} className={classNames(styles.table, styles.hover, styles.tableBalanceList)}>
                    <div className={classNames(styles.col, styles.colId)}>{value.id}</div>
                    <div className={classNames(styles.col, styles.colSendType)}>{value.sendType}</div>
                    <div className={classNames(styles.col, styles.colUsername)}>{value.username}</div>
                    <div className={classNames(styles.col, styles.colPoint, 'text-right')}>
                        {Pipe.toSeparatorNumber(value.point)}
                    </div>
                    <div className={classNames(styles.col, styles.colCurrency)}>{value.currency}</div>
                    <div className={classNames(styles.col, styles.colUpdatedAt)}>
                        {moment(value.updatedAt).format('MM.DD - hh:mm a')}
                    </div>
                </div>

            ))}
        </Fragment>
    )
}
