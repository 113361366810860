import React from 'react';
import {gql, useMutation, useQuery} from "@apollo/client";
import {Mutation, Query} from '../../../graphql/types';
import {FadeInContainer} from "../../shares/fadeInContainer/FadeInContainer";
import {BizCol12} from "../../shares/bizCol12/BizCol12";
import {BizTitle} from "../../shares/title/Title";
import {SmsLineBalanceListView} from "./view/SmsLineBalanceListView";
import {SwalUtil} from "../../../utils/swal/swalUtil";
import {ApolloCatch} from "../../../utils/apollo/apolloExec";

export const SmsLineBalanceList = (props: {}) => {
    const {data, loading, error, refetch} = useQuery<Query>(gqlData);
    const [mutRefresh] = useMutation<Mutation>(gqlRefresh);

    const handler = {
        onRefresh: () => {
            SwalUtil.ynPromise({
                msg: '갱신 하시겠습니까?'
            }).then(() => {
                return mutRefresh();
            }).then(res => {
                console.log(res.data?.refreshSmsLineBalance)
                return refetch()
            }).then(() => {
                SwalUtil.ok({
                    msg: '갱신 되었습니다.',
                    icon: 'success',
                })
            }).catch(ApolloCatch({}))
        }
    };

    return (
        <>
            {data &&
            <FadeInContainer>
                <BizCol12>
                    <BizTitle title='회선별 잔고'>
                        <button
                            onClick={handler.onRefresh}
                            className='btn btn-sm btn-outline-success'>갱신
                        </button>
                    </BizTitle>
                    <SmsLineBalanceListView
                        list={data.smsLineBalance}/>
                </BizCol12>
            </FadeInContainer>
            }

        </>
    )
}

const gqlData = gql`
    query DATA {
        smsLineBalance {
            id
            currency
            point
            sendType
            username
            updatedAt
        }
    }
`;

const gqlRefresh = gql`
    mutation Refresh {
        refreshSmsLineBalance
    }
`;
