import React, {useEffect, useState} from "react";
import gql from "graphql-tag";
import {useLazyQuery} from "@apollo/client";
import {Period, SendDetailReportList, SmsLineSendReport} from "../../graphql/types";
import {navigate} from "hookrouter";
import {Pipe} from "../../utils/pipe/pipe";
import moment from "moment";
import styles from "./MsgHistory.module.scss";
import {FadeInContainer} from "../shares/fadeInContainer/FadeInContainer";
import {BoxCont} from "../shares/boxCont/BoxCont";
import {Title} from "../shares/title/Title";
import classNamesBind from "classnames/bind";
import {BizPeriodPicker} from "../shares/bizPeriodPicker/BizPeriodPicker";
import {Pager} from "../shares/pager/Pager";
import {LoadingViewUtil} from "../App";
import {BizSmTitle} from "../shares/bizSmTitle/BizSmTitle";
import {MsgHistorySendReportGraph} from "./sendReportGraph/MsgHistorySendReportGraph";
import {MsgHistorySmsLineGraph} from "./smsLineGraph/MsgHistorySmsLineGraph";
import {Tooltip} from "@material-ui/core";
import {MsgHistorySendReport} from "./sendReport/MsgHistorySendReport";
import {BizCol12} from "../shares/bizCol12/BizCol12";
import {useSelector} from "react-redux";
import {LayoutState} from "../../redux/Layout";
import ScrollArea from "react-scrollbar";

const cx = classNamesBind.bind(styles);

export const MsgHistory = (props: {
    page: number,
    stDate?: string,
    edDate?: string,
}) => {
    const [getData, resData] = useLazyQuery<ResData, ResInput>(gqlData);
    const [data, setData] = useState<ResData | undefined>();
    const [period, setPeriod] = useState<Period>({
        stDate: Pipe.cvtDayStringToDate(Pipe.toDayString(new Date())),
        edDate: Pipe.cvtDayStringToDate(Pipe.toDayString(new Date()))
    })

    const [scrollAreaHeight, setScrollAreaHeight] = useState<any>();
    const [scrollAreaWidth, setScrollAreaWidth] = useState<any>();
    const [scrollAreaContent, setScrollAreaContent] = useState<any>();

    const layout = useSelector<any, LayoutState>(state => state.layout);

    useEffect(() => {
        if (layout.viewMode === 0) {
            setScrollAreaHeight("auto");
            setScrollAreaWidth("auto");
            setScrollAreaContent("auto");
        } else {
            setScrollAreaHeight(350);
            setScrollAreaWidth(315);
            setScrollAreaContent(styles.scrollTableWidthForMobile);
        }
    }, [layout.viewMode]);

    useEffect(() => {
        if (resData.data) {
            setData(resData.data);
        }
    }, [resData.data]);

    useEffect(() => {
        init();
    }, [props.page, props.edDate, props.stDate])

    useEffect(() => {
        LoadingViewUtil.loadingView(resData.loading);
    }, [resData.loading]);

    const init = () => {
        const stDate = Pipe.cvtDayStringToDate(props.stDate);
        const edDate = Pipe.cvtDayStringToDate(props.edDate);

        if (stDate && edDate) {
            getData({
                variables: {
                    page: props.page,
                    size: 20,
                    period: {stDate, edDate}
                }
            });
            setPeriod({
                stDate, edDate
            });
        } else {
            getData({
                variables: {
                    page: props.page,
                    size: 20,
                    period
                }
            })
        }
    }

    const onChangePage = (page: number) => {
        if (props.stDate && props.edDate) {
            navigate(`/msg-history/${page}/${props.stDate}/${props.edDate}`)
        } else {
            navigate(`/msg-history/${page}`)
        }
    }

    const onApplyPeriod = () => {
        navigate(`/msg-history/0/${Pipe.toDayString(period.stDate)}/${Pipe.toDayString(period.edDate)}`);
    }

    return (
        <>
            {data &&
            <FadeInContainer>
                <BizCol12>
                    <Title>기간</Title>
                    <hr/>

                    <BizPeriodPicker
                        period={period}
                        onChange={setPeriod}/>

                    <div className='text-right' style={{width: '100%'}}>
                        <button
                            className='btn btn-sm btn-success'
                            onClick={onApplyPeriod}>적용
                        </button>
                    </div>
                </BizCol12>

                {/* <div className='col-12' style={{marginBottom: 30}}>
                    <BoxCont>
                        <Title>요약</Title>
                        <MsgHistorySendReport
                            report={data.smsLineSendReport}/>
                    </BoxCont>
                </div>


                <div className='col-12' style={{marginBottom: 30}}>
                    <BoxCont>
                        <Title>응답정보</Title>
                        <MsgHistorySmsLineGraph
                            report={data.smsLineSendReport}/>
                    </BoxCont>
                </div>*/}

                <BizCol12>
                    <Title>전송기록</Title>
                    <ScrollArea
                        style={{height: scrollAreaHeight, width: scrollAreaWidth}}
                        contentStyle={{width: scrollAreaContent}}
                        smoothScrolling={true}
                    >
                        <div className={cx('table')}>
                            <div className={cx("header", "statusCell")}>상태</div>
                            <div className={cx("header", "dateCell")}>시각</div>
                            <div className={cx("header", "memoCell")}>메모</div>
                            <div className={cx("header", "sendCell")}>전송</div>
                            <div className={cx("header", "messageCell")}>메시지</div>
                        </div>

                        {data.sendReport.list.map((value, index) => {
                            const delay = moment(value.doneAt).toDate().getTime() - moment(value.createdAt).toDate().getTime();
                            const viewTime = Math.floor(delay / 1000);

                            return (
                                <div key={index} className={cx('table', 'hover')}>
                                    <div className={cx("col", "statusCell")}>{value.status}</div>
                                    <div className={cx("col", "dateCell")}>
                                        {moment(value.createdAt).format('YY.MM.DD - hh:mm')} ({viewTime} 초)
                                    </div>
                                    <div className={cx("col", "memoCell", "textLeft")}>
                                        {value.memo}
                                    </div>
                                    <div className={cx("col", "sendCell")}>
                                        <Tooltip
                                            title={`요청 ${Pipe.toSeparatorNumber(value.total)} 개/ 성공 ${Pipe.toSeparatorNumber(value.sent)} 개/ 실패 ${Pipe.toSeparatorNumber(value.fail)} 개`}>
                                            <span>
                                                <span
                                                    style={{color: 'gray'}}>{Pipe.toSeparatorNumber(value.total)} /</span>
                                                <span style={{
                                                    color: '#009410',
                                                    marginLeft: 5
                                                }}>{Pipe.toSeparatorNumber(value.sent)} /</span>
                                                <span style={{
                                                    color: 'red',
                                                    marginLeft: 5
                                                }}>{Pipe.toSeparatorNumber(value.fail)}</span>
                                            </span>
                                        </Tooltip>

                                    </div>
                                    <div className={cx("col", "messageCell", "textLeft")}
                                         ref={ref => {
                                             if (ref) {
                                                 ref.innerText = value.msg;
                                             }
                                         }}/>
                                </div>
                            )
                        })}

                    </ScrollArea>

                    <Pager
                        page={data.sendReport.page}
                        size={data.sendReport.size}
                        total={data.sendReport.total}
                        onChange={onChangePage}/>
                </BizCol12>
            </FadeInContainer>
            }
        </>
    )
}

interface ResData {
    sendReport: SendDetailReportList;
    smsLineSendReport: SmsLineSendReport;
}

interface ResInput {
    page: number;
    size: number;
    period: Period;
}

const gqlData = gql`
    query Data($page: Int!, $size: Int!, $period: Period!) {
        sendReport(page: $page, size: $size, period: $period) {
            size
            page
            size
            total
            list {
                id
                apiKey
                userEmail
                memo
                msg
                status
                total
                tps
                sent
                fail
                createdAt
                doneAt
            }
        }
#        smsLineSendReport(period: $period) {
#            stDate
#            edDate
#            totalSend
#            unchecked
#            undelivered
#            delivered
#            fail
#            report {
#                smsLineId
#                smsLineName
#                totalSend
#                unchecked
#                undelivered
#                delivered
#                fail
#            }
#        }
    }
`

