import {createActionCreators, createReducerFunction, ImmerReducer} from "immer-reducer";


export interface SystemState {
    langCode: LangCode;
    langList: LangCode[];
    loadingView: boolean,
}

const initState: SystemState = {
    langCode: {code: 'en', nm: 'English'},
    langList: [
        {code: 'en', nm: 'English'},
        {code: 'kr', nm: 'Korean'},
    ],
    loadingView: false
};

class System extends ImmerReducer<typeof initState> {
    setLangCode(langCode: LangCode) {
        this.draftState.langCode = langCode;
    }
    setLoadingView(swt: boolean) {
        this.draftState.loadingView = swt;
    }
}


export const SystemAction = createActionCreators(System);
export const SystemReducer = createReducerFunction(System, initState);

export type LangCode = { code: string, nm: string };
