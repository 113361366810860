import {createActionCreators, createReducerFunction, ImmerReducer} from "immer-reducer";

export enum ViewMode {
    Web, Mobile
}

export interface LayoutState {
    critWidth: number;
    showCategory: boolean;
    sidebar: boolean;
    sidebarIndex: number;
    topNavIndex: number;
    windowWidth: number;
    viewMode: ViewMode;
    loadingView: boolean;
}

const initState: LayoutState = {
    critWidth: 998,
    showCategory: false,
    sidebar: false,
    sidebarIndex: 0,
    topNavIndex: 0,
    windowWidth: 0,
    viewMode: ViewMode.Web,
    loadingView: false,
};

class Layout extends ImmerReducer<typeof initState> {
    showCategory(swt: boolean) {
        this.draftState.showCategory = swt;
    }

    setSidebar(swt: boolean) {
        this.draftState.sidebar = swt;
    }

    setSidebarIndex(index: number) {
        if (this.state.sidebarIndex !== index) {
            this.draftState.sidebarIndex = index;
        }
    }

    setTopNavIndex(index: number) {
        this.draftState.topNavIndex = index;
    }

    setWindowWidth(width: number) {
        if ((width < this.state.critWidth) && (this.state.viewMode === ViewMode.Web)) {
            this.draftState.viewMode = ViewMode.Mobile;
            this.draftState.sidebar = false;
        } else if ((this.state.critWidth < width) && (this.state.viewMode === ViewMode.Mobile)) {
            this.draftState.viewMode = ViewMode.Web;
            this.draftState.sidebar = true;
        }
    }

    setLoadingView(isView: boolean) {
        this.draftState.loadingView = isView;
    }
}

export const LayoutAction = createActionCreators(Layout);
export const LayoutReducer = createReducerFunction(Layout, initState);

