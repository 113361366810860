import React, {ReactNode} from 'react';
import classNamesBind from "classnames/bind";
import styles from './AuthLayout.module.scss';
import {useSelector} from "react-redux";
import {LayoutState, ViewMode} from "../../../redux/Layout";
import {Drawer} from "@material-ui/core";
import {LayoutSidebar} from "./sidebar/LayoutSidebar";
import {Spring} from "react-spring/renderprops-universal";
import {LayoutNavTop} from "./navTop/LayoutNavTop";

const cx = classNamesBind.bind(styles);

export const AuthLayout = (props: {
    children: ReactNode
}) => {
    const layout = useSelector<any, LayoutState>(state => state.layout);
    const drawerWidth = 250;

    const defaultMarginLeft = layout.viewMode === ViewMode.Web ? drawerWidth : 0;
    const contentWidth = layout.viewMode === ViewMode.Web ? 'calc(100vw - 250px)' : '100vw'
    const drawerVariant = layout.viewMode === ViewMode.Web ? 'permanent' : 'persistent';


    return (
        <>
            <Drawer
                variant={drawerVariant}
                anchor="left"
                classes={{
                    root: cx('root'),
                    paper: cx('paper')
                }}
                open={layout.sidebar}>
                <LayoutSidebar/>
            </Drawer>

            <Spring
                from={{drawerWidth: defaultMarginLeft}}
                to={{drawerWidth: layout.sidebar ? drawerWidth : 0}}>
                {params =>
                    <div className={cx('contents')} style={{marginLeft: params.drawerWidth, width: contentWidth}}>
                        <LayoutNavTop navWidth={contentWidth}/>
                        <div style={{height: 20}}/>
                        {props.children}
                        <div style={{height: 20}}/>
                    </div>
                }
            </Spring>


        </>
    )
}
