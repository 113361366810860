export class BizErrors {
    static default = 'default';
    static needLogin = "needLogin";
    static notFoundSession = 'notFoundSession';
    static notFoundAdmin = "notFoundAdmin";
    static notFoundToken = "notFoundToken"
    static notFoundUser = 'notFoundUser';
    static loginLimited = "loginLimited";
    static loginFail = "loginFail";
    static invalidPassword = "invalidPassword";
    static passwordNotFound = "passwordNotFound";
    static notEnoughPoint = "notEnoughPoint";
    static alreadyRegisteredOtp = "alreadyRegisteredOtp";
    static failVerifyOTP = "failVerifyOTP";
    static swalCancel = "swalCancel";
}
