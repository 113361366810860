import React, {useEffect, useState} from "react";
import classNamesBind from "classnames/bind";
import styles from './TelBookCategoryList.module.scss';
import {InputUserCategory} from "../TelBookCategory";
import {OnChangeValue} from "../../../shares/interfaces/interfaces";
import {FormControlLabel, IconButton, Switch} from "@material-ui/core";
import {CopyUtil} from "../../../../utils/copy/CopyUtil";
import {BizNoDataTable} from "../../../shares/bizNoDataTable/BizNoDataTable";
import {Add, Close} from "@material-ui/icons";
import {useSelector} from "react-redux";
import {LayoutState} from "../../../../redux/Layout";
import ScrollArea from "react-scrollbar";

const cx = classNamesBind.bind(styles);

interface Props {
    value: InputUserCategory;
    onChangeValue: OnChangeValue<InputUserCategory>;
}

export const TelBookCategoryList = (props: Props) => {
    const [scrollAreaHeight, setScrollAreaHeight] = useState<any>();
    const [scrollAreaWidth, setScrollAreaWidth] = useState<any>();
    const [scrollAreaContent, setScrollAreaContent] = useState<any>();

    const layout = useSelector<any, LayoutState>(state => state.layout);

    useEffect(() => {
        if (layout.viewMode === 0) {
            setScrollAreaHeight("auto");
            setScrollAreaWidth("auto");
            setScrollAreaContent("auto");
        } else {
            setScrollAreaHeight(500);
            setScrollAreaWidth(315);
            setScrollAreaContent(styles.scrollTableWidthForMobile);
        }
    }, [layout.viewMode]);

    return (
        <>
            <ScrollArea
                style={{height: scrollAreaHeight, width: scrollAreaWidth}}
                contentStyle={{width: scrollAreaContent}}
                smoothScrolling={true}
            >
                <div className={cx('table')}>
                    <div className={cx("header", "activateCell")}>활성화</div>
                    <div className={cx("header", "idCell")}>아이디</div>
                    <div className={cx("header", "nameCell")}>이름</div>
                    <div className={cx("header", "funcCell")}>삭제</div>
                </div>

                {props.value.map((value, index) => (
                    <div key={index} className={cx('table')}>
                        <div className={cx("col", "activateCell")}>
                            <Switch
                                color="primary"
                                checked={value.isActivate}
                                onChange={ev => {
                                    const copy = CopyUtil.copyAll(props.value);
                                    copy[index].isActivate = ev.target.checked;
                                    copy[index].isChanged = true;
                                    props.onChangeValue(copy);
                                }}/>
                        </div>
                        <div className={cx("col", "idCell")}>{value.id === -1 ? '신규' : value.id}</div>
                        <div className={cx("col", "nameCell")}>
                            <input
                                className='form-control form-control-sm'
                                value={value.nm}
                                onChange={ev => {
                                    const copy = CopyUtil.copyAll(props.value);
                                    copy[index].nm = ev.target.value;
                                    copy[index].isChanged = true;
                                    props.onChangeValue(copy);
                                }}/>
                        </div>
                        <div className={cx("col", "funcCell")}>
                            {value.id === -1 &&
                            <IconButton size='small' onClick={() => {
                                let copy = CopyUtil.copyAll(props.value);
                                copy = [...copy.slice(0, index), ...copy.slice(index + 1, copy.length)];
                                props.onChangeValue(copy);
                            }}>
                                <Close/>
                            </IconButton>
                            }
                        </div>
                    </div>
                ))}

                {props.value.length === 0 &&
                <BizNoDataTable/>
                }

            </ScrollArea>

            <div className={cx('table')} onClick={() => {
                const copy = CopyUtil.copyAll(props.value);
                copy.push({
                    id: -1,
                    isActivate: true,
                    isChanged: true,
                    nm: ""
                })
                props.onChangeValue(copy);
            }}>
                <div style={{width: '100%'}}>
                    <FormControlLabel
                        label={'추가하기'}
                        control={<Add/>}/>
                </div>
            </div>

        </>
    )
};
