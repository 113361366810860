import {I18nNamespace} from "../utils/i18n/i18n";

export const Request: I18nNamespace = {
    requestTitle: {
        ko: "상품권 구매"
    },
    desRequest: {
        ko: "* 해당 상품권의 금액을 입금 후 확인 신청을 하여주십시오."
    },
    giftCard: {
        ko: "상품권"
    },
    requestListTitle: {
        ko : "구매목록"
    },
    phNm: {
        ko: "입금자명을 입력하여 주십시오."
    },
    tbDate: {
        ko: "날짜"
    },
    tbStatus: {
        ko : "상태"
    },
    tbNm: {
        ko: "입금자명"
    },
    tbPurchase: {
        ko: "구매금액"
    },
    tbPoint: {
        ko: "충전포인트"
    },
    tbCancel: {
        ko: "취소"
    },
    notFoundRequest: {
        ko : "구매 내역이 없습니다."
    },
    badgeRequested: {
        ko : "대기중"
    },
    badgeAllowed: {
        ko: "승인"
    },
    badgeDenied: {
        ko: "거절"
    },
    badgeCanceled: {
        ko: "취소"
    },
    won: {
        ko: " 원"
    },
    modalRequestTitle: {
        ko: "상품권 구매"
    }
}
