import React, {useState} from "react";
import classNamesBind from "classnames/bind";
import styles from './MngUserViewIssuePoint.module.scss'
import {Title} from "../../../shares/title/Title";
import NumberFormat from "react-number-format";
import {OnChangeValue} from "../../../shares/interfaces/interfaces";
import {IssuePoint, User} from "../../../../graphql/types";
import {CopyUtil} from "../../../../utils/copy/CopyUtil";

const cx = classNamesBind.bind(styles);

export const MngUserViewIssuePoint = (props: {
    user: User,
    onIssuePoint: OnChangeValue<IssuePoint>,
}) => {
    const [input, setInput] =  useState<IssuePoint>({
        memo: "",
        otp: "",
        point: 0,
        userId: props.user.id
    })

    const onIssuePoint = () => {
        props.onIssuePoint(CopyUtil.copyAll(input));
        setInput({
            memo: "",
            otp: "",
            point: 0,
            userId: props.user.id
        });
    }

    return (
        <>
            <Title>포인트 충전/ 회수</Title>
            <div className={cx('table')}>
                <div className={cx("col", "itemCell")}>충전/ 회수</div>
                <div className={cx("col", "valueCell")}>
                    <NumberFormat
                        thousandSeparator
                        className='form-control form-control-sm text-center'
                        displayType='input'
                        decimalScale={0}
                        value={input.point}
                        onValueChange={values => {
                            const copy = CopyUtil.copyAll(input);
                            copy.point = values.floatValue || 0;
                            setInput(copy);
                        }}
                    />
                </div>
            </div>
            <div className={cx('table')}>
                <div className={cx("col", "itemCell")}>메모</div>
                <div className={cx("col", "valueCell")}>
                    <input
                        className='form-control form-control-sm text-center'
                        value={input.memo}
                        onChange={event => {
                            const copy = CopyUtil.copyAll(input);
                            copy.memo = event.target.value;
                            setInput(copy);
                        }}/>
                </div>
            </div>
            <div className={cx('table')}>
                <div className={cx("col", "itemCell")}>OTP</div>
                <div className={cx("col", "valueCell")}>
                    <input
                        className='form-control form-control-sm text-center'
                        value={input.otp}
                        onChange={event => {
                            const copy = CopyUtil.copyAll(input);
                            copy.otp = event.target.value;
                            setInput(copy);
                        }}
                        onKeyUp={event => {
                            if (event.key === 'Enter') {
                                onIssuePoint();
                            }
                        }}/>
                </div>
            </div>

            <div className={cx('table')}>
                <div className='text-right' style={{width: '100%'}}>
                    <button
                        className='btn btn-sm btn-success'
                        onClick={onIssuePoint}>적용
                    </button>
                </div>
            </div>
        </>
    )
}
