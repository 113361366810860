import React, {Fragment} from "react";
import classNamesBind from "classnames/bind";
import styles from './BizNoDataTable.module.scss'

const cx = classNamesBind.bind(styles);

export const BizNoDataTable = () => {
    return (
        <div className={cx('table')}>
            <div className={cx('col', 'col-full')}>데이터가 없습니다.</div>
        </div>
    )
}
