import React, {useEffect, useState} from 'react';
import classNamesBind from "classnames/bind";
import styles from './MngUserCreate.module.scss';
import {Title} from "../../shares/title/Title";
import gql from "graphql-tag";
import {useLazyQuery, useMutation} from "@apollo/client";
import {SwalUtil} from "../../../utils/swal/swalUtil";
import {ApolloCatch} from "../../../utils/apollo/apolloExec";
import {BizErrors} from "../../../graphql/errors";
import {LoadingViewUtil} from "../../App";
import {UserList} from "../../../graphql/types";
import {Pipe} from "../../../utils/pipe/pipe";
import {BadgeIsActivate} from "../../shares/badgeIsActivate/BadgeIsActivate";
import {Pager} from "../../shares/pager/Pager";
import {navigate} from "hookrouter";
import {ModalWeb} from "../../shares/modal/ModalWeb";
import {FadeInContainer} from "../../shares/fadeInContainer/FadeInContainer";
import {BizCol12} from "../../shares/bizCol12/BizCol12";
import {useSelector} from 'react-redux';
import {LayoutState} from '../../../redux/Layout';
import ScrollArea from "react-scrollbar";

const cx = classNamesBind.bind(styles);

export const MngUserCreate = () => {
    const [mutCreateUser] = useMutation<{ createUser: boolean }, { email: string, pw: string }>(gqlCreateUser);
    const [getUser] = useLazyQuery<{ user: UserList }, { page: number }>(gqlUser, {
        onCompleted: res => {
            setUserList(res.user);
        }
    });

    const [userList, setUserList] = useState<UserList | undefined>();
    const [createUserModal, setCreateUserModal] = useState(false);
    const [scrollAreaHeight, setScrollAreaHeight] = useState<any>();
    const [scrollAreaWidth, setScrollAreaWidth] = useState<any>();
    const [scrollAreaContent, setScrollAreaContent] = useState<any>();

    const layout = useSelector<any, LayoutState>(state => state.layout);

    useEffect(() => {
        if (layout.viewMode === 0) {
            setScrollAreaHeight("auto");
            setScrollAreaWidth("auto");
            setScrollAreaContent("auto");
        } else {
            setScrollAreaHeight(450);
            setScrollAreaWidth(315);
            setScrollAreaContent(styles.scrollTableWidthForMobile);
        }
    }, [layout.viewMode]);

    const onCreateUser = (email: string, pw: string) => {
        LoadingViewUtil.loading(
            mutCreateUser({variables: {email, pw}})
                .then(res => {
                    SwalUtil.ok({
                        msg: "회원가입 되었습니다.",
                        icon: "success"
                    });
                    onChangePage(0);
                    setCreateUserModal(false);
                })
                .catch(ApolloCatch({
                    [BizErrors.default]: SwalUtil.bizError()
                }))
        );
    }

    const onChangePage = (page: number) => {
        getUser({variables: {page}})
    }

    useEffect(() => {
        getUser({variables: {page: 0}})
    }, [])

    return (
        <>
            <ModalWeb
                title={'회원추가'}
                isShow={createUserModal}
                size={'lg'}
                onCancel={() => {
                    setCreateUserModal(false)
                }}
                onOk={() => {
                    setCreateUserModal(false)
                }}>
                <CreateUserForm onCreateUser={onCreateUser}/>
            </ModalWeb>


            <FadeInContainer>
                <BizCol12>
                    <Title>회원목록</Title>
                    {userList &&
                    <>
                        <ScrollArea
                            style={{height: scrollAreaHeight, width: scrollAreaWidth}}
                            contentStyle={{width: scrollAreaContent}}
                            smoothScrolling={true}
                        >
                            <div className={cx('table')}>
                                <div className={cx("header", "numCell")}>아이디</div>
                                <div className={cx("header", "activateCell")}>활성화</div>
                                <div className={cx("header", "emailCell")}>이메일</div>
                                <div className={cx("header", "pointCell")}>포인트</div>
                                <div className={cx("header", "apiCell")}>ApiKey</div>
                            </div>

                            {userList.list.map((value, index) => {
                                return (
                                    <div
                                        key={index} className={cx('table', 'hover', 'pointer')}
                                        onClick={event => {
                                            navigate(`/user/${value.id}`);
                                        }}>
                                        <div className={cx("col", "numCell")}>{value.id}</div>
                                        <div className={cx("col", "activateCell")}>
                                            <BadgeIsActivate isActivate={value.isActivate}/>
                                        </div>
                                        <div className={cx("col", "emailCell")}>{value.email}</div>
                                        <div
                                            className={cx("col", "pointCell", "textRight")}>{Pipe.toSeparatorNumber(value.point)}</div>
                                        <div className={cx("col", "apiCell")}>{value.permission.length} 개 등록
                                        </div>
                                    </div>
                                )
                            })}
                        </ScrollArea>

                        <div className={cx('table')}>
                            <div className='text-right' style={{width: '100%'}}>
                                <button
                                    className='btn btn-sm btn-success'
                                    onClick={event => {
                                        setCreateUserModal(true);
                                    }}>추가
                                </button>
                            </div>
                        </div>

                        <Pager
                            page={userList.page}
                            size={userList.size}
                            total={userList.total}
                            onChange={onChangePage}/>
                    </>
                    }
                </BizCol12>
            </FadeInContainer>
        </>

    )
}


const CreateUserForm = (props: {
    onCreateUser: (email: string, pw: string) => void;
}) => {
    const [email, setEmail] = useState("");
    const [pw, setPw] = useState("");
    const [confirm, setConfirm] = useState("");

    const onCreateUser = () => {
        if (!pw) {
            SwalUtil.ok({
                msg: '비밀번호를 입력하여 주십시오.',
                icon: 'error'
            });
            return;
        }

        if (pw != confirm) {
            SwalUtil.ok({
                msg: '두 비밀번호가 다릅니다.',
                icon: 'error'
            });
            return;
        }

        if (pw.length < 8) {
            SwalUtil.ok({
                msg: '비밀번호는 8잘 이상으로 입력하여 주십시오.',
                icon: 'error'
            });
            return;
        }

        props.onCreateUser(email, pw);
    }

    return (
        <>
            <form
                style={{padding: 20}}
                onSubmit={event => {
                    event.preventDefault();
                    onCreateUser();
                }}>
                <p className={cx('input-label')}>이메일</p>
                <input
                    value={email}
                    type='email'
                    className='form-control form-control-sm'
                    onChange={event => {
                        setEmail(event.target.value)
                    }}
                    required/>

                <p className={cx('input-label')}>비밀번호</p>
                <input
                    value={pw}
                    type='password'
                    className='form-control form-control-sm'
                    onChange={event => {
                        setPw(event.target.value)
                    }}
                    required/>

                <p className={cx('input-label')}>비밀번호 확인</p>
                <input
                    value={confirm}
                    type='password'
                    className='form-control form-control-sm'
                    onChange={event => {
                        setConfirm(event.target.value);
                    }}
                    required/>
                <hr/>
                <button type='submit' className='btn btn-block btn-sm btn-primary'>회원가입</button>
            </form>
        </>
    )
}


const gqlCreateUser = gql`
    mutation CreateUesr($email: String!, $pw: String!) {
        createUser(email: $email, pw: $pw)
    }
`;

const gqlUser = gql`
    query User($page: Int!) {
        user(page: $page, size: 20) {
            page
            size
            total
            list {
                id
                email
                point
                isActivate
                permission {
                    apiKey
                }
            }
        }
    }
`
