import React, {useEffect, useState} from "react";
import classNamesBind from "classnames/bind";
import styles from './SpeedTestList.module.scss'
import gql from "graphql-tag";
import {SmsCompany, SmsTestDetail, SpeedTestLine, SpeedTestList as SpeedList} from "../../../graphql/types";
import {useLazyQuery, useMutation} from "@apollo/client";
import {Pipe} from "../../../utils/pipe/pipe";
import {FadeInContainer} from "../../shares/fadeInContainer/FadeInContainer";
import {BoxCont} from "../../shares/boxCont/BoxCont";
import {BizTitle, Title} from "../../shares/title/Title";
import {BizDatePicker} from "../../shares/bizDatePicker/BizDatePicker";
import {ApolloCatch} from "../../../utils/apollo/apolloExec";
import {BizErrors} from "../../../graphql/errors";
import {SwalUtil} from "../../../utils/swal/swalUtil";
import moment from "moment";
import {Accordion, AccordionDetails, AccordionSummary, Tooltip} from "@material-ui/core";
import {Alarm, CallMade, CallReceived, Check, Close, ExpandMore, FiberManualRecord} from "@material-ui/icons";
import {LoadingViewUtil} from "../../App";
import imgKt from '../../../assets/img/smsCompany/kt.png';
import imgLg from '../../../assets/img/smsCompany/lg.png';
import imgSk from '../../../assets/img/smsCompany/sk.png';
import {Pager} from "../../shares/pager/Pager";
import {navigate} from "hookrouter";
import {BizMonthPicker} from "../../shares/bizMonthPicker/BizMonthPicker";
import {BizCol12} from "../../shares/bizCol12/BizCol12";

const cx = classNamesBind.bind(styles);

export const SpeedTestList = (props: {
    day?: string,
    page: number,
}) => {
    const [getData, resData] = useLazyQuery<ResData, ResInput>(gqlData, {
        pollInterval: 5000,
    });
    // const [getData, resData] = useLazyQuery<ResData, ResInput>(gqlData);
    const [mutSendTest, mutSendTestData] = useMutation<{ sendSpeedTest: boolean }>(gqlSendTest);
    const [mutSendTestWithId, mutSendTestWithIdData] = useMutation<{
        sendSpeedTestWithSmsLineId: boolean
    }, {
        smsLineId: number
    }>(gqlSendTestWithSmsLineId);
    const [data, setData] = useState<ResData | undefined>();
    const [day, setDay] = useState(new Date());

    useEffect(() => {
        const paramDay = Pipe.cvtDayStringToDate(props.day);
        setDay(paramDay || day);
        getData({
            variables: {
                day: paramDay || day,
                page: props.page,
                size: 20
            }
        });
    }, [props.day, props.page]);

    useEffect(() => {
        if (resData.data) {
            setData(resData.data);
        }
    }, [resData.data]);

    useEffect(() => {
        LoadingViewUtil.loadingView(
            resData.loading ||
            mutSendTestData.loading ||
            mutSendTestWithIdData.loading
        );
    }, [resData.loading, mutSendTestData.loading, mutSendTestWithIdData.loading]);

    const onClickSendTest = () => {
        mutSendTest()
            .then(res => {
                SwalUtil.ok({
                    msg: '전송 되었습니다.',
                    icon: "success",
                })
            })
            .catch(ApolloCatch({
                [BizErrors.default]: SwalUtil.bizError(),
            }))
    }

    const onClickSendTestWithId = (smsLineId: number) => {
        mutSendTestWithId({variables: {smsLineId}})
            .then(res => {
                SwalUtil.ok({
                    msg: '전송 되었습니다.',
                    icon: "success",
                })
            })
            .catch(ApolloCatch({
                [BizErrors.default]: SwalUtil.bizError(),
            }))
    }

    const onChangePage = (page: number) => {
        navigate(`/speedTest/list/${page}/${Pipe.toDayString(day)}`)
    }

    const onChangeDate = (date: Date) => {
        const adjDate = moment(date).hour(0).minute(0).second(0).millisecond(0).toDate();
        navigate(`/speedTest/list/0/${Pipe.toDayString(adjDate)}`)
    }

    return (
        <>
            {data &&
            <FadeInContainer>
                <BizCol12>
                    <BizTitle title={'속도측정'}>
                        <BizDatePicker date={day} onChange={onChangeDate}/>
                    </BizTitle>
                </BizCol12>

                <BizCol12>
                    <Title>통신사</Title>
                    <SmsTestLineView
                        list={data.speedTestLine}
                        onSendTest={onClickSendTest}
                        onSendTestWithId={onClickSendTestWithId}/>
                </BizCol12>

                <div className='col-12' style={{marginBottom: 30}}>
                    {data.speedTestList.list.map((value, index) => {
                        let color;
                        if ((value.sentTotal === value.receivedTotal) && (60 < value.avgDelay)) {
                            color = '#eeb64a';
                        } else if (value.sentTotal === value.receivedTotal) {
                            color = '#4da847';
                        } else {
                            color = 'red';
                        }

                        return (
                            <Accordion
                                key={index}
                                style={{boxShadow: '5px 5px 10px rgba(0, 0, 0, 0.1)'}}>
                                <AccordionSummary expandIcon={<ExpandMore/>}>
                                    <div className={cx('cont-list')}>
                                        <div
                                            className={cx('sent-at')}>
                                            <FiberManualRecord style={{color}}/>
                                            {moment(value.sentAt).format('HH:mm')}
                                        </div>
                                        <div style={{width: 220}}>{value.smsLineNm}</div>
                                        <div className={cx('cont-data')}>
                                            <Tooltip title={'전송갯수'}>
                                                <div>
                                                    <CallMade/> {value.sentTotal}
                                                </div>
                                            </Tooltip>
                                        </div>
                                        <div className={cx('cont-data')}>
                                            <Tooltip title={'수신완료'}>
                                                <div>
                                                    <CallReceived style={{color: 'green'}}/>{value.receivedTotal}
                                                </div>
                                            </Tooltip>
                                        </div>
                                        <div className={cx('cont-data')}>
                                            <Tooltip title={'평균 딜레이'}>
                                                <div>
                                                    <Alarm
                                                        style={{color: '#af72d0'}}/>{Pipe.toSeparatorNumber(value.avgDelay)}
                                                </div>
                                            </Tooltip>
                                        </div>

                                        <div className={cx('cont-test')}>
                                            <div className={cx('box-test')}>
                                                <SmsTestView list={value.list} company={SmsCompany.Sk}/>
                                            </div>
                                            <div className={cx('box-test')}>
                                                <SmsTestView list={value.list} company={SmsCompany.Kt}/>
                                            </div>
                                            <div className={cx('box-test')}>
                                                <SmsTestView list={value.list} company={SmsCompany.Lg}/>
                                            </div>
                                        </div>
                                    </div>

                                </AccordionSummary>
                                <AccordionDetails>
                                    <div style={{width: '100%'}}>

                                        <div className={cx('table')}>
                                            <div style={{width: 100}}>번호</div>
                                            <div style={{width: 100}}>통신사</div>
                                            <div style={{width: 150}}>폰번호</div>
                                            <div style={{width: 200}}>발신번호</div>
                                            <div style={{width: 150}}>전송</div>
                                            <div style={{width: 150}}>수신</div>
                                            <div style={{flexGrow: 1}}>딜레이</div>
                                        </div>

                                        {value.list.map((test, testIdx) => {
                                            return (
                                                <div className={cx('table')} key={testIdx}>
                                                    <div style={{width: 100}}>{test.id}</div>
                                                    <div style={{width: 100}}>{test.company}</div>
                                                    <div style={{width: 150}}>{test.phoneNumber}</div>
                                                    <div style={{width: 200}}>{test.sender ? test.sender : '-'}</div>
                                                    <div
                                                        style={{width: 150}}>{moment(test.sentAt).format('HH:mm:ss')}</div>
                                                    <div
                                                        style={{width: 150}}>{test.receivedAt ? moment(test.receivedAt).format('HH:mm:ss') : '-'}</div>
                                                    <div
                                                        style={{width: 'calc(100% - 850px)'}}>{test.delay ? `${test.delay} 초` : '-'}</div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        )
                    })}

                    <Pager
                        page={data.speedTestList.page}
                        size={data.speedTestList.size}
                        total={data.speedTestList.total}
                        onChange={onChangePage}/>
                </div>
            </FadeInContainer>
            }
        </>
    )
}

const SmsTestView = (props: {
    list: SmsTestDetail[],
    company: SmsCompany
}) => {
    return (
        <>
            {props.list
                .filter(test => {
                    return test.company === props.company
                })
                .map((test, testIdx) => {
                    return (
                        <div className={cx('test')} key={testIdx}>
                            {props.company === SmsCompany.Kt && <img className={cx('logo')} src={imgKt} alt='logo'/>}
                            {props.company === SmsCompany.Sk && <img className={cx('logo')} src={imgSk} alt='logo'/>}
                            {props.company === SmsCompany.Lg && <img className={cx('logo')} src={imgLg} alt='logo'/>}
                            {test.isReceived &&
                            <span style={{fontSize: '0.8rem'}}>
                                <Check style={{color: 'green'}}/> {test.delay} 초
                            </span>}

                            {!test.isReceived &&
                            <span>
                                <Close style={{color: 'red'}}/>
                            </span>}
                        </div>
                    )
                })}
        </>
    )
}

const SmsTestLineView = (props: {
    list: SpeedTestLine[],
    onSendTest: () => void,
    onSendTestWithId: (id: number) => void,
}) => {

    return (
        <>
            {props.list
                .filter(value => (value.hasTest))
                .map((value, index) => (
                    <div key={index} className={cx('table')}>
                        <div style={{width: 100}}>{value.smsLineId}</div>
                        <div className='text-left' style={{flexGrow: 1}}>{value.smsLineNm}</div>
                        <div style={{width: 100}}>
                            <button
                                style={{width: 80}} className='btn btn-sm btn-success'
                                onClick={event => {
                                    props.onSendTestWithId(value.smsLineId);
                                }}>
                                테스트
                            </button>
                        </div>
                    </div>
                ))}

            <div className={cx('table')}>
                <div style={{width: 100}}/>
                <div className='text-left' style={{flexGrow: 1}}>전체 테스트</div>
                <div style={{width: 100}}>
                    <button
                        style={{width: 80}}
                        className='btn btn-sm btn-outline-success'
                        onClick={props.onSendTest}>
                        테스트
                    </button>
                </div>
            </div>
        </>
    )
}


interface ResData {
    speedTestList: SpeedList,
    speedTestLine: SpeedTestLine[]
}

interface ResInput {
    day: Date,
    page: number,
    size: number,
}

const gqlData = gql`
    query SpeedTestList($day: Time!, $page: Int!, $size: Int!) {
        speedTestList(day: $day, page: $page, size: $size) {
            page
            size
            total
            list {
                avgDelay
                id
                receivedTotal
                sentTotal
                smsLineId
                smsLineNm
                sentAt
                list {
                    company
                    delay
                    id
                    isReceived
                    isSent
                    phoneNumber
                    sender
                    sentAt
                    receivedAt
                }
            }
        }
        speedTestLine {
            smsLineId
            smsLineNm
            hasTest
        }
    }
`;

const gqlSendTest = gql`
    mutation sendSpeedTest {
        sendSpeedTest
    }
`;

const gqlSendTestWithSmsLineId = gql`
    mutation sendSpeedTestWithSmsLineId($smsLineId: Int!) {
        sendSpeedTestWithSmsLineId(smsLineId: $smsLineId)
    }

`
