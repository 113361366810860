import {I18nNamespace} from "../utils/i18n/i18n";

export const Auth: I18nNamespace = {
    phEmail: {
        ko: "이메일을 입력하여 주십시오."
    },
    phPw: {
        ko: "비밀번호를 입력하여 주십시오."
    },
    phConfirm: {
        ko: "비밀번호를 다시 입력하여 주십시오."
    },
    hasAccount: {
        ko: "이미 아이디가 있으십니까?",
    },
    forgot: {
        ko: "비밀번호를 잊으셨습니까?"
    },
    initialize: {
        ko: "초기화"
    },
    join: {
        ko: "회원가입"
    },
    login: {
        ko: "로그인"
    },
    selWholesale: {
        ko: "도매"
    },
    selPremium: {
        ko: "프리미엄"
    },
    selDirect: {
        ko: "다이렉트"
    }
}
