import React, {ReactNode} from 'react';

export const Title = (props: {
    children: any,
    leftPadding?: number,
}) => {
    return (
        <div style={{color: '#6664aa'}}>
            <h5 style={{fontSize: '1.2rem', marginBottom: 15, paddingLeft: props.leftPadding ? props.leftPadding : 0}}>{props.children}</h5>
        </div>
    )
}

export const BizTitle = (props: {
    title: ReactNode,
    children?: ReactNode
}) => {
    return (
        <div style={{display: 'flex'}}>
            <div style={{width: '50%'}}>
                <Title>{props.title}</Title>
            </div>
            <div className='text-right' style={{width: '50%'}}>
                {props.children}
            </div>
        </div>
    )
}
