import React from 'react';
import {gql, useMutation, useQuery} from "@apollo/client";
import {
    Mutation,
    MutationAllowClientRechargeRequestArgs,
    MutationDenyClientRechargeRequestArgs,
    Query
} from "../../../graphql/types";
import {FadeInContainer} from "../../shares/fadeInContainer/FadeInContainer";
import {BizCol12} from "../../shares/bizCol12/BizCol12";
import {BizTitle} from "../../shares/title/Title";
import {Pager} from "../../shares/pager/Pager";
import {navigate} from "hookrouter";
import {PointRequestList} from "./list/PointRequestList";
import {SwalUtil} from "../../../utils/swal/swalUtil";
import {ApolloCatch} from "../../../utils/apollo/apolloExec";

export const PointRequest = (props: {
    requestPage: number,
}) => {
    const {data, loading, error, refetch} = useQuery<Query>(gqlData, {
        variables: props
    });

    const [mutAllow] = useMutation<Mutation, MutationAllowClientRechargeRequestArgs>(gqlAllow);
    const [mutDeny] = useMutation<Mutation, MutationDenyClientRechargeRequestArgs>(gqlDeny);

    const handler = {
        onChangeRequestPage: (p: number) => {
            navigate(`/point/request/${p}`)
        },
        onAllow: (id: number) => {
            SwalUtil.otp(otp => {
                mutAllow({
                    variables: {
                        clientRechargeRequestId: id,
                        otp
                    }
                }).then(() => {
                    return refetch();
                }).then(() => {
                    SwalUtil.ok({
                        msg: '승인 되었습니다.',
                        icon: 'success',
                    })
                }).catch(ApolloCatch({}));
            })

        },
        onDeny: (id: number) => {
            SwalUtil.ynPromise({
                msg: '반려 하시겠습니까?'
            }).then(() => {
                return mutDeny({
                    variables: {
                        clientRechargeRequestId: id
                    }
                })
            }).then(() => {
                return refetch();
            }).then(() => {
                SwalUtil.ok({
                    msg: '반려 되었습니다.',
                    icon: 'success'
                })
            }).catch(ApolloCatch({}))

        }
    };

    return (
        <>
            {data &&
            <FadeInContainer>
                <BizCol12>
                    <BizTitle title={'포인트 충전 요청'}/>
                    <PointRequestList
                        {...handler}
                        list={data.clientRechargeRequest}/>
                </BizCol12>

                <BizCol12>
                    <BizTitle title={'충전 요청 목록'}/>
                    <PointRequestList
                        {...handler}
                        list={data.clientRechargeRequestList.list}/>
                    <Pager
                        page={data.clientRechargeRequestList.page}
                        size={data.clientRechargeRequestList.size}
                        total={data.clientRechargeRequestList.total}
                        onChange={handler.onChangeRequestPage}/>
                </BizCol12>
            </FadeInContainer>

            }
        </>
    )
}

const gqlData = gql`
    query DATA($requestPage: Int!) {
        clientRechargeRequest {
            id
            clientEmail
            clientId
            point
            status
            taxAmount
            taxRate
            createdAt
            smsCount
            amount
        }
        clientRechargeRequestList(page: $requestPage, size: 20) {
            page
            size
            total
            list {
                id
                clientEmail
                clientId
                point
                status
                taxAmount
                taxRate
                createdAt
                smsCount
                amount
            }
        }
    }
`;

const gqlAllow = gql`
    mutation Allow($clientRechargeRequestId: Int!, $otp: String!) {
        allowClientRechargeRequest(clientRechargeRequestId: $clientRechargeRequestId, otp: $otp)
    }
`;

const gqlDeny = gql`
    mutation Deny($clientRechargeRequestId: Int!) {
        denyClientRechargeRequest(clientRechargeRequestId: $clientRechargeRequestId)
    }
`;
