import * as firebase from 'firebase';
import {SwalUtil} from "../swal/swalUtil";
import {Apollo} from "../apollo/apollo";
import {Admin} from "../../graphql/types";
import gql from "graphql-tag";
import {ReduxStore} from "../../index";
import {UserAction} from "../../redux/User";
import {navigate} from "hookrouter";
import {ApolloCatch} from "../apollo/apolloExec";

export class FB {
    static fb: firebase.app.App;
    static dev = {
        apiKey: "AIzaSyBjCnIrKdKi1iS2ceA5046qpKI78V0IP7c",
        authDomain: "clientsmssender.firebaseapp.com",
        projectId: "clientsmssender",
        storageBucket: "clientsmssender.appspot.com",
        messagingSenderId: "811438504278",
        appId: "1:811438504278:web:fd1f013ffd0447014cbef7",
        measurementId: "G-PL5XNMJ665"
    };
    static prod = {
        apiKey: "AIzaSyBy2sPPkPpB1YsyTG_2gwfEcsVhDsakvZk",
        authDomain: "smsadmin-216ef.firebaseapp.com",
        projectId: "smsadmin-216ef",
        storageBucket: "smsadmin-216ef.appspot.com",
        messagingSenderId: "222543504324",
        appId: "1:222543504324:web:55e3db20e8b43b0992ba91",
        measurementId: "G-5PP03TR010"
    }

    static init() {
        switch (process.env.REACT_APP_ENV) {
            case "dev":
                this.fb = firebase.initializeApp(this.dev);
                break;
            case "prod":
                this.fb = firebase.initializeApp(this.prod);
                break;
            default:
                process.exit(0);
        }
    }

    static join(params: {
        email: string,
        password: string
    }): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            firebase.auth().createUserWithEmailAndPassword(params.email, params.password)
                .then(cred => {
                    const {user} = cred;

                    if (!user) {
                        reject();
                        return;
                    }

                    user.sendEmailVerification().then();
                    resolve(user.uid)
                })
                .catch(e => {
                    if (!e.hasOwnProperty("code")) {
                        SwalUtil.ok({
                            msg: e,
                            icon: "error"
                        });
                        reject();
                        return;
                    }

                    switch (e.code) {
                        case "auth/email-already-in-use":
                            SwalUtil.ok({
                                msg: '이미 가입되어 있는 이메일 입니다.',
                                icon: 'error'
                            });
                            break;
                        default:
                            SwalUtil.ok({
                                msg: e.code,
                                icon: "error"
                            });
                    }
                    reject();
                })
        })
    }

    static login(params: {
        email: string,
        password: string
    }): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            firebase.auth().signInWithEmailAndPassword(params.email, params.password)
                .then(cred => {
                    const {user} = cred;

                    if (!user) {
                        reject();
                        return;
                    }

                    if (!user.emailVerified) {
                        SwalUtil.ok({
                            msg: '이메일 인증을 완료하여 주십시오.',
                            icon: 'warning'
                        });
                        reject();
                        return;
                    }

                    resolve(user.uid)
                })
                .catch(e => {
                    if (!e.hasOwnProperty("code")) {
                        SwalUtil.ok({
                            msg: e,
                            icon: "error"
                        });
                        reject();
                        return;
                    }

                    switch (e.code) {
                        case "auth/email-already-in-use":
                            SwalUtil.ok({
                                msg: '이미 가입되어 있는 이메일 입니다.',
                                icon: 'error'
                            });
                            break;
                        default:
                            SwalUtil.ok({
                                msg: e.code,
                                icon: "error"
                            });
                    }
                    reject();
                })
        })
    }

    static refreshSession(): Promise<Admin> {
        return Apollo.client.mutate<{ admin: Admin }>({
            mutation: gql`
                mutation Admin {
                    admin {
                        id
                        email
                    }
                }
            `
        }).then(res => {
            const user = res.data!.admin;
            ReduxStore.dispatch(UserAction.login(user));
            return user;
        })
    }

    static logout(): Promise<void> {
        return Apollo.client.mutate<{login: boolean}>({
            mutation: gql`
                mutation Logout {
                    logout
                }
            `
        }).then(res => {
            ReduxStore.dispatch(UserAction.logout());
            navigate('/');
        }).catch(ApolloCatch({
        }))
    }
}
