import React, {Fragment} from "react";
import {ClientRechargeRequestStatus} from "../../../graphql/types";

export const BadgeClientRechargeRequestStatus = (props: {
    status: ClientRechargeRequestStatus
}) => {
    return (
        <Fragment>
            {props.status === ClientRechargeRequestStatus.Requested && <span className='badge badge-warning'>요청</span>}
            {props.status === ClientRechargeRequestStatus.Deny && <span className='badge badge-danger'>반려</span>}
            {props.status === ClientRechargeRequestStatus.UserCancel && <span className='badge badge-secondary'>취소</span>}
            {props.status === ClientRechargeRequestStatus.Allow && <span className='badge badge-success'>승인</span>}
        </Fragment>
    )
}
