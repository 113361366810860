import React, {Fragment, ReactNode} from "react";
import classNamesBind from "classnames/bind";
import styles from './BizSmTitle.module.scss'

const cx = classNamesBind.bind(styles);

export const BizSmTitle = (props: {
    children?: ReactNode
}) => {
    return (
        <Fragment>
            <div className={cx('title')}>
                {props.children}
            </div>
        </Fragment>
    )
}
