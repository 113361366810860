import React, {Fragment} from "react";
import styles from './TelBookSummaryExtractStatus.module.scss'
import classNames from "classnames";
import {LinearProgress} from "@material-ui/core";
import moment from "moment";
import {EsRequestStatus} from "../../../../graphql/types";
import {Pipe} from "../../../../utils/pipe/pipe";
import Decimal from "decimal.js";

export const TelBookSummaryExtractStatus = (props: {
    requestStatus: EsRequestStatus
}) => {
    const deduplicatingRate = new Decimal(props.requestStatus.deduplicating.done).div(props.requestStatus.deduplicating.total).mul(100).floor().toNumber() ||0;
    const sortingRate = new Decimal(props.requestStatus.sorting.done).div(props.requestStatus.sorting.total).mul(100).floor().toNumber() || 0;
    const extractingRate = new Decimal(props.requestStatus.extracting.done).div(props.requestStatus.extracting.total).mul(100).floor().toNumber() || 0;


    return (
        <Fragment>
            <div className={classNames(styles.table, styles.tableStatus)}>
                <div className={classNames(styles.header, styles.colLabel)}>요청 아이디</div>
                <div className={classNames(styles.col, styles.colValue)}>{props.requestStatus.requestId}</div>
            </div>
            <div className={classNames(styles.table, styles.tableStatus)}>
                <div className={classNames(styles.header, styles.colLabel)}>요청시</div>
                <div className={classNames(styles.col, styles.colValue)}>
                    {moment(props.requestStatus.requestAt).format('YY.MM.DD hh:mm a')}
                </div>
            </div>
            <div className={classNames(styles.table, styles.tableStatus)}>
                <div className={classNames(styles.header, styles.colLabel)}>1. 수집</div>
                <div className={classNames(styles.colProgressBar)}>
                    <LinearProgress
                        value={deduplicatingRate}
                        variant="determinate"/>
                </div>
                <div className={classNames(styles.colDesc)}>({deduplicatingRate}%) {Pipe.toSeparatorNumber(props.requestStatus.deduplicating.done)} / {Pipe.toSeparatorNumber(props.requestStatus.deduplicating.total)}</div>
            </div>

            <div className={classNames(styles.table, styles.tableStatus)}>
                <div className={classNames(styles.header, styles.colLabel)}>2. 정렬</div>
                <div className={classNames(styles.colProgressBar)}>
                    <LinearProgress
                        value={sortingRate}
                        variant="determinate"/>
                </div>
                <div className={classNames(styles.colDesc)}>({sortingRate}%) {Pipe.toSeparatorNumber(props.requestStatus.sorting.done)} / {Pipe.toSeparatorNumber(props.requestStatus.sorting.total)}</div>
            </div>

            <div className={classNames(styles.table, styles.tableStatus)}>
                <div className={classNames(styles.header, styles.colLabel)}>3. 추출</div>
                <div className={classNames(styles.colProgressBar)}>
                    <LinearProgress
                        value={extractingRate}
                        variant="determinate"/>
                </div>
                <div className={classNames(styles.colDesc)}>({extractingRate}%) {Pipe.toSeparatorNumber(props.requestStatus.extracting.done)} / {Pipe.toSeparatorNumber(props.requestStatus.extracting.total)}</div>
            </div>
        </Fragment>
    )
}
