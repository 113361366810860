import {I18nNamespace} from "../utils/i18n/i18n";

export const Swal: I18nNamespace = {
    btnOk: {
        ko: "확인"
    },
    btnCancel: {
        ko: "취소"
    },
    clearedNumber: {
        ko: "정리 되었습니다."
    },
    ynCancel: {
        ko: "취소하시겠습니까?"
    }
}
