import React, {useEffect, useState} from 'react';
import classNamesBind from "classnames/bind";
import styles from "./Dashboard.module.scss";
import gql from "graphql-tag";
import {useLazyQuery} from "@apollo/client";
import {Period, SendDetailReportList, SmsDailySendReport} from "../../graphql/types";
import {FadeInContainer} from "../shares/fadeInContainer/FadeInContainer";
import {BoxCont} from "../shares/boxCont/BoxCont";
import {Title} from "../shares/title/Title";
import {Validator} from "../../utils/validator/Validator";
import {DashboardSendReport} from "./sendReport/DashboardSendReport";
import {LoadingViewUtil} from "../App";
import {DashboardDailySendReport} from "./dailySendReport/DashboardDailySendReport";

const cx = classNamesBind.bind(styles);

export const Dashboard = () => {
    const [getData, resData] = useLazyQuery<ResData, GetDataParam>(gqlData, {
        pollInterval: 5000,
    });
    const [data, setData] = useState<ResData | undefined>();
    const [size, setSize] = useState(20);

    useEffect(() => {
        getData({
            variables: {
                size,
                period: {
                    stDate: new Date(),
                    edDate: new Date(),
                }
            }
        })
    }, [size]);

    useEffect(() => {
        if (resData.data) {
            setData(resData.data);
        }
    }, [resData.data]);


    useEffect(() => {
        LoadingViewUtil.loadingView(resData.loading);
    }, [resData.loading]);

    return (
        <>
            {data &&
            <FadeInContainer>
                {/*<div className='col-12 text-right' style={{marginBottom: 10}}>*/}
                {/*    {moment(resData).format('YY.MM.DD - HH:mm:ss a')}*/}
                {/*</div>*/}

                <div className={cx("fullContainer")}>
                        <Title>일일 전체 사용량</Title>
                        <DashboardDailySendReport
                            report={data.smsDailySendReport}/>
                </div>

                <div className={cx("fullContainer")}>
                    <div className={cx("fullRow")}>
                        <div style={{display: 'flex'}}>
                            <div className={cx("titleLeft")}>
                                <Title>전송목록</Title>
                            </div>
                            <div className={cx("titleRight")}>
                                <select
                                    className='form-control form-control-sm text-center'
                                    value={String(size)}
                                    onChange={value => {
                                        setSize(Validator.isNumber(value.target.value));
                                    }}>
                                    <option value='20'>20 개</option>
                                    <option value='50'>50 개</option>
                                </select>
                            </div>
                        </div>
                        <DashboardSendReport
                            list={data.sendReport}/>
                    </div>
                </div>

            </FadeInContainer>
            }
        </>
    )
}


const gqlData = gql`
    query Data($size: Int!, $period: Period!) {
        sendReport(page: 0, size: $size, period: $period) {
            size
            page
            size
            total
            list {
                id
                apiKey
                userEmail
                memo
                msg
                status
                total
                tps
                sent
                fail
                createdAt
                doneAt
            }
        }
        smsDailySendReport(period: $period) {
            requested
            sent
            fail
            undelivered
            delivered
        }
    }
`

interface ResData {
    sendReport: SendDetailReportList;
    smsDailySendReport: SmsDailySendReport;
}

interface GetDataParam {
    size: number;
    period: Period;
}
