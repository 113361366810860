import React, {Fragment, useEffect, useState} from "react";
import classNamesBind from "classnames/bind";
import styles from './MngUserViewModalApiSmsLine.module.scss'
import {SmsLine, SmsLineType, UpdateUserPermissionSmsLine, UserSmsLine} from "../../../../graphql/types";
import NumberFormat from "react-number-format";
import {CopyUtil} from "../../../../utils/copy/CopyUtil";

const cx = classNamesBind.bind(styles);

export const MngUserViewModalApiSmsLine = (props: {
    apiKey: string,
    smsLine: SmsLine[],
    userSmsLine: UserSmsLine[],
    onUpdateUserPermissionSmsLine: (apiKey: string, input: UpdateUserPermissionSmsLine[]) => void
}) => {
    const [view, setView] = useState<SmsLineView[]>([]);

    useEffect(() => {
        const list: SmsLineView[] = [];

        for (let smsLine of props.smsLine) {
            const obj = props.userSmsLine.find(value => {
                return smsLine.id === value.smsLine.id
            })

            list.push({
                smsLineId: smsLine.id,
                smsLineType: smsLine.smsLineType,
                rate: obj ? obj.rate : 0
            })
        }
        setView(list);
    }, [props.userSmsLine, props.smsLine]);

    const onChangeRate = (idx: number, rate: number) => {
        const copy = CopyUtil.copy(view);
        copy[idx].rate = rate;
        setView(copy);
    }

    const onUpdate = () => {
        const list: UpdateUserPermissionSmsLine[] = [];
        for (let item of view) {
            if (item.rate !== 0) {
                list.push({
                    smsLineId: item.smsLineId,
                    rate: item.rate
                })
            }
        }
        props.onUpdateUserPermissionSmsLine(props.apiKey, list);
    }

    return (
        <Fragment>
            <div className={cx('table')}>
                <div style={{width: 200}}>회선</div>
                <div style={{width: 'calc(100% - 200px)'}}>비율</div>
            </div>
            {view.map((value, index) => {
                return (
                    <div key={index} className={cx('table')}>
                        <div style={{width: 200}}>{value.smsLineType}</div>
                        <div style={{width: 'calc(100% - 200px)', paddingTop: 13}}>
                            <NumberFormat
                                className='form-control form-control-sm'
                                thousandSeparator
                                allowNegative={false}
                                decimalScale={0}
                                max={1000}
                                min={0}
                                value={value.rate}
                                onValueChange={values => {
                                    onChangeRate(index, values.floatValue || 0);
                                }}/>
                        </div>
                    </div>
                )
            })}

            <div className={cx('table')}>
                <div className='text-right' style={{width: '100%'}}>
                    <button
                        className='btn btn-sm btn-outline-success'
                        onClick={onUpdate}>
                        저장
                    </button>
                </div>
            </div>

        </Fragment>
    )
}


interface SmsLineView {
    smsLineId: number,
    smsLineType: SmsLineType,
    rate: number
}
