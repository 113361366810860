import {I18nNamespace} from "../utils/i18n/i18n";

export const SMS: I18nNamespace = {
    modalMngNumber: {
        ko: "번호정리"
    },
    desNumber: {
        ko: "중복된 전화번호나 형식에 맞지 않는 전화번호를 삭제 합니다."
    },
    critChar: {
        ko : "숫자 이외의 문자 삭제"
    },
    critValid: {
        ko : "형식에 맞지 않는 전화번호 삭제 ( 10 XXXX XXXX 또는 010 XXXX XXXX 형태 이외 삭제 )"
    },
    critDup: {
        ko : "중복된 전화번호 삭제"
    },
    btnApply: {
        ko : "적용"
    },
    contents: {
        ko: "문자내용"
    },
    phContents: {
        ko :"이곳에 내용을 작성하여 주십시오."
    },
    numbers: {
        ko: "전화번호"
    },
    phNumbers: {
        ko: "이곳에 전화번호를 입력하여 주십시오."
    },
    btnMngNumber: {
        ko: "번호정리"
    },
    btnSend: {
        ko : "전송"
    }
}
