import React from 'react';
import classNamesBind from "classnames/bind";
import styles from './AuthLoginView.module.scss';
import {navigate} from "hookrouter";
import {CenterContents} from "../../../shares/centerContents/CenterContents";
import {AuthLoginFunc, AuthLoginState} from "../AuthLogin";

const cx = classNamesBind.bind(styles);

export const AuthLoginView = (props: {
    state: AuthLoginState,
    func: AuthLoginFunc,
    onRegisterOtp: () => void,
}) => {
    const state = Object.assign({}, props.state);

    return (
        <CenterContents animatedContents>
            <form className={cx("box-login")} onSubmit={event => {
                event.preventDefault();
                props.func.login();
            }}>
                <h3 className={cx("logo")}>SMS Admin</h3>
                <p className={cx("item")}>Email</p>
                <input
                    className='form-control'
                    type='email'
                    placeholder='이메일을 입력하여 주십시오.'
                    value={state.email}
                    onChange={event => {
                        state.email = event.target.value;
                        props.func.setState(state);
                    }}/>
                <p className={cx("item")}>Password</p>
                <input
                    className='form-control'
                    type='password'
                    placeholder='비밀번호를 입력하여 주십시오.'
                    value={state.pw}
                    onChange={event => {
                        state.pw = event.target.value;
                        props.func.setState(state);
                    }}/>

                <p className={cx("item")}>OTP</p>
                <input
                    className='form-control'
                    type='text'
                    placeholder='OTP를 입력하여 주십시오.'
                    minLength={6}
                    maxLength={6}
                    value={state.otp}
                    onKeyUp={event => {
                        if (event.key === 'Enter') {
                            props.func.login();
                        }
                    }}
                    onChange={event => {
                        state.otp = event.target.value;
                        props.func.setState(state);
                    }}/>

                <hr/>

                <button
                    style={{marginBottom: 10}}
                    type="submit"
                    className="btn btn-primary btn-block">
                    로그인
                </button>

                {/* OTP 등록 */}
                <button
                    style={{marginBottom: 10}}
                    type="button"
                    className="btn btn-outline-secondary btn-block"
                    onClick={props.onRegisterOtp}>
                    OTP 등록
                </button>

                {/*회원가입*/}
                <button
                    onClick={event => {
                        navigate('/join');
                    }}
                    className='btn btn-outline-secondary btn-block'>
                    회원가입
                </button>

                {/* 회원가입, 비밀번호 찾기 안내
                        <div style={{height: 10}}/>
                        <p>{t('auth.forgot')}
                            <span style={{
                                paddingLeft: 10,
                                color: '#86a7cd',
                                cursor: "pointer"
                            }}>{t('auth.initialize')}</span>
                        </p>*/}
            </form>
        </CenterContents>
    )
}
