import React, {Fragment} from 'react';
import classNamesBind from "classnames/bind";
import styles from './BizDatePicker.module.scss';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker, {registerLocale} from "react-datepicker";
import ko from 'date-fns/locale/ko';
registerLocale('ko', ko);

const cx = classNamesBind.bind(styles);

export const BizDatePicker = (props: {
    date: Date,
    onChange: (date: Date) => void
}) => {
    return (
        <Fragment>
            <DatePicker
                className='form-control form-control-sm text-center'
                wrapperClassName={cx('date-picker')}
                locale={'ko'}
                dateFormat={"yyyy/MM/dd"}
                selected={props.date}
                onChange={date => {
                    if (date instanceof Date) {
                        props.onChange(date);
                    }
                }}/>
        </Fragment>
    )
}
