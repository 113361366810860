import React from "react";
import classNamesBind from "classnames/bind";
import styles from './DashboardSendReport.module.scss'
import {SendDetailReportList, SmsRequestType} from "../../../graphql/types";
import moment from "moment";
import {Tooltip} from "@material-ui/core";
import {AccessAlarm, ChatBubbleOutlineOutlined, Check, Close} from "@material-ui/icons";
import {Pipe} from "../../../utils/pipe/pipe";
import {CircularProgressbar} from "react-circular-progressbar";
import 'react-circular-progressbar/dist/styles.css';

const cx = classNamesBind.bind(styles);

export const DashboardSendReport = (props: {
    list: SendDetailReportList
}) => {
    return (
        <>
            {props.list.list.map((value, index) => {
                return (
                    <div className={cx('cont')} key={index}>
                        <div className={cx('box-time')}>
                            <div className={cx('prog')}>
                                <Progress value={value.status}/>
                            </div>
                            <div className={cx('times')}>
                                <div
                                    className={cx('request-at')}>{moment(value.createdAt).format('YY.MM.DD - HH:mm')}</div>
                                <div className={cx('icons')}>
                                    <Tooltip title={'전송시간'}>
                                        <AccessAlarm/>
                                    </Tooltip>
                                    <span>{moment(value.doneAt).diff(moment(value.createdAt), "s")} 초</span>
                                </div>
                            </div>
                        </div>

                        <div className={cx('box-count')}>
                            <div className={cx('total')}>
                                <ChatBubbleOutlineOutlined/>
                                {Pipe.toSeparatorNumber(value.total)}
                            </div>
                            <div className={cx('result')}>
                                <Check style={{color: 'green'}}/> {Pipe.toSeparatorNumber(value.sent)}
                                <span/>
                                <Close style={{color: 'red'}}/> {Pipe.toSeparatorNumber(value.fail)}
                            </div>
                        </div>

                        <div className={cx('box-user')}>
                            <div>유저 <span className={cx('value')}>{value.userEmail}</span></div>
                            <div>메모 <span className={cx('value')}>{value.memo}</span></div>
                            <div>인증 <span className={cx('value')}>{value.apiKey}</span></div>
                        </div>

                        <div className={cx('box-msg')} ref={ref=>{
                            if (ref) {
                                ref.innerText = value.msg;
                            }
                        }}>
                        </div>

                    </div>
                )
            })}
        </>
    )
}

const Progress = (props: {
    value: SmsRequestType
}) => {
    let value;
    let text;
    let color: 'primary' | 'secondary' | 'inherit' = 'primary';
    switch (props.value) {
        case  SmsRequestType.Request:
            value = 25;
            text = "요청중";
            break;
        case SmsRequestType.Sending:
            value = 50;
            text = "전송중";
            break;
        case SmsRequestType.Done:
            value = 100;
            text = "완료";
            break;
        default:
            color = 'secondary';
            text = "Undefined";
            value = 100;
    }

    return (
        <>
            <div className={cx("circleProgress")}>
                <CircularProgressbar value={value} text={text}
                styles={{text: {
                    fontSize: "1.5rem"
                    }}}/>
            </div>
        </>
    )
}
