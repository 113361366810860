import React, {Fragment} from "react";
import styles from './ReceiverApiTransferStatus.module.scss'
import classNames from "classnames";
import {ReceiverTransferStatus} from "../../../../graphql/types";
import moment from "moment";
import {Pipe} from "../../../../utils/pipe/pipe";
import Decimal from "decimal.js";
import {LinearProgress} from "@material-ui/core";

export const ReceiverApiTransferStatus = (props: {
    status: ReceiverTransferStatus
}) => {
    let percent = 0;
    if (props.status.isProcessing) {
        percent = new Decimal(props.status.doneSmsRequest).div(props.status.totalSmsRequest).mul(100).floor().toNumber();
    }

    return (
        <Fragment>
            <div className={classNames(styles.table, styles.tableStatus)}>
                <div className={classNames(styles.col, styles.colLabel)}>작동</div>
                <div className={styles.colValue}>
                    {props.status.isProcessing && <span className='badge badge-success'>백업중</span>}
                    {!props.status.isProcessing && <span className='badge badge-secondary'>대기중</span>}
                </div>
            </div>

            <div className={classNames(styles.table, styles.tableStatus)}>
                <div className={classNames(styles.col, styles.colLabel)}>마지막 백업일</div>
                <div
                    className={styles.colValue}>{moment(Pipe.cvtDayStringToDate(String(props.status.lastUpdatedTimeDay))).format("YY.MM.DD")}</div>
            </div>

            {props.status.isProcessing &&
            <div className={classNames(styles.table, styles.tableStatus)}>
                <div className={classNames(styles.col, styles.colLabel)}>진행</div>
                <div className={styles.colProgressBar}>
                    <LinearProgress
                        value={percent}
                        variant="determinate"/>
                </div>
                <div className={styles.colDesc}>
                    ({percent} %) {Pipe.toSeparatorNumber(props.status.doneSmsRequest)} / {Pipe.toSeparatorNumber(props.status.totalSmsRequest)}
                </div>
            </div>

            }


        </Fragment>
    )
}
