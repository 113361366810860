import React, {useState} from 'react';
import {navigate} from "hookrouter";
import gql from "graphql-tag";
import {useMutation} from "@apollo/client";
import {FB} from "../../../utils/fb/FB";
import {SwalUtil} from "../../../utils/swal/swalUtil";
import {ApolloCatch} from "../../../utils/apollo/apolloExec";
import {BizErrors} from "../../../graphql/errors";
import {LocalStorage} from "../../../utils/localstorage/LocalStorage";
import {LoadingViewUtil} from "../../App";
import {ModalWeb} from "../../shares/modal/ModalWeb";
import {RegisterOTPModal} from "./share/registerOtpModal/RegisterOTPModal";
import {AuthLoginView} from './view/AuthLoginView';

export const AuthLogin = () => {
    const [state, setState] = useState<AuthLoginState>({
        email: "",
        pw: "",
        otp: "",
    });
    const [mutLogin] = useMutation<{ login: string }, { uid: string, otp: string }>(gqlLogin);
    const [mutRegisterOtp] = useMutation<{ registerOtp: string }, { uid: string }>(gqlRegisterOtp);
    const [otpRegisterModal, setOtpRegisterModal] = useState(false);
    const [provisionUrl, setProvisionUrl] = useState("");

    const func: AuthLoginFunc = {
        setState: value => {
            setState(value);
        },
        login: () => {
            LoadingViewUtil.loading(
                FB.login({email: state.email, password: state.pw})
                    .then(uid => {
                        if (!uid) {
                            SwalUtil.ok({
                                msg: '로그인 실패',
                                icon: 'error'
                            });
                            return;
                        }

                        return mutLogin({variables: {uid, otp: state.otp}})
                    })
                    .then(res => {
                        if (!res) {
                            return;
                        }
                        LocalStorage.setSession(res.data!.login);
                        return FB.refreshSession();
                    })
                    .then(res => {
                        navigate('/');
                    })
                    .catch(ApolloCatch({
                        [BizErrors.default]: SwalUtil.bizError(),
                        [BizErrors.loginLimited]: SwalUtil.bizErrorMsg({
                            msg: '로그인 제한 계정입니다.',
                            icon: 'warning'
                        }),
                        [BizErrors.failVerifyOTP]: SwalUtil.bizErrorMsg({
                            msg: 'OTP 번호를 다시 입력하여 주십시오.',
                            icon: "warning"
                        })
                    }))
            );
        }
    }

    const onRegisterOtp = () => {
        LoadingViewUtil.loading(
            FB.login({email: state.email, password: state.pw})
                .then(uid => {
                    if (!uid) {
                        SwalUtil.ok({
                            msg: '로그인 실패',
                            icon: 'error'
                        });
                        return;
                    }

                    return mutRegisterOtp({variables: {uid}})
                })
                .then(res => {
                    setProvisionUrl(res!.data!.registerOtp);
                    setOtpRegisterModal(true);
                })
                .catch(ApolloCatch({
                    [BizErrors.default]: SwalUtil.bizError(),
                    [BizErrors.alreadyRegisteredOtp]: SwalUtil.bizErrorMsg({
                        msg: "이미 등록하셨습니다.",
                        icon: "warning"
                    })
                }))
        )
    }

    return (
        <>
            <ModalWeb
                title={'Google OTP 등록'}
                size='sm'
                isShow={otpRegisterModal}
                onCancel={() => {
                    setOtpRegisterModal(false);
                }}
                onOk={() => {
                    setOtpRegisterModal(false);
                }}>
                <RegisterOTPModal provisionUrl={provisionUrl}/>
            </ModalWeb>

            <AuthLoginView
                func={func}
                state={state}
                onRegisterOtp={onRegisterOtp}
            />
        </>
    );
}

export interface AuthLoginState {
    email: string;
    pw: string;
    otp: string;
}

export interface AuthLoginFunc {
    setState: (state: AuthLoginState) => void;
    login: () => void;
}

const gqlLogin = gql`
    mutation Login($uid: String!, $otp: String!) {
        login(uid: $uid, otp: $otp)
    }
`

const gqlRegisterOtp = gql`
    mutation RegisterOtp($uid: String!) {
        registerOtp(uid: $uid)
    }
`;
