import {createActionCreators, createReducerFunction, ImmerReducer} from "immer-reducer";
import {DelayedSmsLine} from "../graphql/types";


export interface AlertState {
    delayedSmsLine: DelayedSmsLine[]
}

const initState: AlertState = {
    delayedSmsLine: []
}

class Alert extends ImmerReducer<typeof initState> {
    setDelayedSmsLine(list: DelayedSmsLine[]) {
        this.draftState.delayedSmsLine = list;
    }
}


export const AlertAction = createActionCreators(Alert);
export const AlertReducer = createReducerFunction(Alert, initState);

