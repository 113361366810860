import React from 'react';
import {Transition} from "react-spring/renderprops-universal";
import styles from './LoadingView.module.scss'
import classNamesBind from "classnames/bind";
import {CircularProgress} from "@material-ui/core";

const cx = classNamesBind.bind(styles);

export const LoadingView = (props: {isActivate: boolean}) => {
    return (
        <Transition
            items={props.isActivate}
            from={{opacity: 0}}
            enter={{opacity: 1}}
            leave={{opacity: 0}}>
            {show => show && (params =>
                    <div style={params} className={cx('full')}>
                        <CircularProgress color="secondary" />
                    </div>
            )}
        </Transition>
    )
}
