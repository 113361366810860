import React, {useEffect, useState} from "react";
import classNamesBind from "classnames/bind";
import styles from './ClientPermission.module.scss'
import {
    ClientPermission as Permission,
    CreateClientPermission,
    UpdateClientPermission,
    UserPermission
} from "../../../../graphql/types";
import {BadgeIsActivate} from "../../../shares/badgeIsActivate/BadgeIsActivate";
import {ModalWeb} from "../../../shares/modal/ModalWeb";
import {IconButton, Switch} from "@material-ui/core";
import {SwalUtil} from "../../../../utils/swal/swalUtil";
import {CopyUtil} from "../../../../utils/copy/CopyUtil";
import NumberFormat from "react-number-format";
import {Title} from "../../../shares/title/Title";
import {Close} from "@material-ui/icons";
import {useSelector} from "react-redux";
import {LayoutState} from "../../../../redux/Layout";
import ScrollArea from "react-scrollbar";

const cx = classNamesBind.bind(styles);

export const ClientPermission = (props: {
    clientPermission: Permission[],
    userPermission: UserPermission[],
    onUpdateClientPermission: (input: UpdateClientPermission) => void,
    onCreateClientPermission: (input: CreateClientPermission) => void,
}) => {
    const [updateModal, setUpdateModal] = useState(false);
    const [createModal, setCreateModal] = useState(false);
    const [selPermission, setSelPermission] = useState<Permission | undefined>();

    const [scrollAreaHeight, setScrollAreaHeight] = useState<any>();
    const [scrollAreaWidth, setScrollAreaWidth] = useState<any>();
    const [scrollAreaContent, setScrollAreaContent] = useState<any>();

    const layout = useSelector<any, LayoutState>(state => state.layout);

    useEffect(() => {
        if (layout.viewMode === 0) {
            setScrollAreaHeight("auto");
            setScrollAreaWidth("auto");
            setScrollAreaContent("auto");
        } else {
            setScrollAreaHeight(285);
            setScrollAreaWidth(315);
            setScrollAreaContent(styles.scrollTableWidthForMobile);
        }
    }, [layout.viewMode]);

    const onCreateClientPermission = (input: CreateClientPermission) => {
        setCreateModal(false);
        props.onCreateClientPermission(input);
    }

    const onUpdateClientPermission = (input: UpdateClientPermission) => {
        setUpdateModal(false);
        props.onUpdateClientPermission(input);
    }


    const onClickPermission = (value: Permission) => {
        setSelPermission(value);
        setUpdateModal(true);
    }

    return (
        <>
            <ModalWeb
                title={'클라이언트 권한 업데이트'}
                isShow={updateModal}
                onCancel={() => {
                    setUpdateModal(false);
                }}
                onOk={() => {
                    setUpdateModal(false);
                }}>
                {selPermission &&
                <UpdateModal
                    onUpdateClientPermission={onUpdateClientPermission}
                    userPermission={props.userPermission}
                    permission={selPermission}/>
                }
            </ModalWeb>

            <ModalWeb
                title={'클라이언트 권한 생성'}
                isShow={createModal}
                onCancel={() => {
                    setCreateModal(false)
                }}
                onOk={() => {
                    setCreateModal(false)
                }}>
                <CreateModal
                    userPermission={props.userPermission}
                    onCreateClientPermission={onCreateClientPermission}/>
            </ModalWeb>

            <ScrollArea
                style={{height: scrollAreaHeight, width: scrollAreaWidth}}
                contentStyle={{width: scrollAreaContent}}
                smoothScrolling={true}
            >

                <div className={cx('table')}>
                    <div className={cx("header", "idCell")}>아이디</div>
                    <div className={cx("header", "nameCell")}>앱이름</div>
                    <div className={cx("header", "activateCell")}>활성화</div>
                    <div className={cx("header", "devCell")}>개발모드</div>
                    <div className={cx("header", "apiCell")}>Client API Key</div>
                </div>

                {props.clientPermission.length === 0 &&
                <div className={cx("table")}>
                    <div className={cx("col")} style={{width: '100%'}}>등록된 클라이언트가 없습니다.</div>
                </div>
                }

                {props.clientPermission.map((value, index) => {
                    return (
                        <div key={index} className={cx("table", "hover", "scrollTable")} onClick={event => {
                            onClickPermission(value);
                        }}>
                            <div className={cx("col", "idCell")}>{value.id}</div>
                            <div className={cx("col", "nameCell")}>{value.appNm}</div>
                            <div className={cx("col", "activateCell")}>
                                <BadgeIsActivate isActivate={value.isActivate}/>
                            </div>
                            <div className={cx("col", "devCell")}>
                                <BadgeIsActivate isActivate={value.devMode}/>
                            </div>
                            <div className={cx("col", "apiCell")}>
                                {value.clientApiKey}
                            </div>

                        </div>
                    )
                })}

            </ScrollArea>

            <div className={cx('table')}>
                <div className='text-right' style={{width: '100%'}}>
                    <button
                        className='btn btn-sm btn-success'
                        onClick={event => {
                            setCreateModal(true);
                        }}>생성
                    </button>
                </div>
            </div>
        </>
    )
}


const UpdateModal = (props: {
    permission: Permission,
    userPermission: UserPermission[],
    onUpdateClientPermission: (input: UpdateClientPermission) => void,
}) => {
    const [permission, setPermission] = useState<UpdateClientPermission | undefined>();

    useEffect(() => {
        const next: UpdateClientPermission = {
            id: props.permission.id,
            isActivate: props.permission.isActivate,
            adminFb: props.permission.adminFb,
            clientFb: props.permission.clientFb,
            env: props.permission.env,
            devMode: props.permission.devMode,
            appNm: props.permission.appNm,
            apiKeys: props.permission.permissions.map(value => {
                return {
                    apiKey: value.apiKey,
                    nm: value.nm,
                    defaultUserSmsFee: value.defaultUserSmsFee,
                    isDefault: value.isDefault,
                    systemDefault: value.systemDefault,
                }
            })
        };
        setPermission(next);
    }, [props]);


    const onUpdate = () => {
        if (!permission) {
            return;
        }

        const copy = CopyUtil.copyAll(permission);
        copy.apiKeys = copy.apiKeys.filter(value => {
            return value.nm !== ""
        });

        props.onUpdateClientPermission({
            adminFb: copy.adminFb,
            apiKeys: copy.apiKeys,
            appNm: copy.appNm,
            clientFb: copy.clientFb,
            devMode: copy.devMode,
            env: copy.env,
            id: copy.id,
            isActivate: copy.isActivate
        });
    }

    const onChangeApiValue = (index: number, value: string) => {
        if (!permission) {
            return;
        }

        const copy = CopyUtil.copyAll(permission);
        copy.apiKeys[index].nm = value;

        setPermission(copy);
    }


    return (
        <>
            {permission &&
            <>
                <div className={cx('table')}>
                    <div className={cx('col')} style={{width: 200}}>유저 아이디</div>
                    <div className={cx('col')} style={{width: 'calc(100% - 200px)'}}>{permission.id}</div>
                </div>

                <div className={cx('table')}>
                    <div style={{width: 200}}>Client ApiKey</div>
                    <div style={{width: 'calc(100% - 200px)'}}>{props.permission.clientApiKey}</div>
                </div>

                <div className={cx('table')}>
                    <div style={{width: 200}}>DB Schema</div>
                    <div style={{width: 'calc(100% - 200px)'}}>
                        {props.permission.dbSchemaNm}
                    </div>
                </div>

                <div className={cx('table')}>
                    <div style={{width: 200}}>관리자 FB</div>
                    <div style={{width: 'calc(100% - 200px)'}}>
                    <textarea
                        className='form-control'
                        style={{resize: 'none', height: 100}}
                        value={permission.adminFb || ''}
                        onChange={event => {
                            setPermission({...permission, adminFb: event.target.value})
                        }}/>

                    </div>
                </div>
                <div className={cx('table')}>
                    <div style={{width: 200}}>사용자 FB</div>
                    <div style={{width: 'calc(100% - 200px)'}}>
                    <textarea
                        className='form-control'
                        style={{resize: 'none', height: 100}}
                        value={permission.clientFb || ''}
                        onChange={event => {
                            setPermission({...permission, clientFb: event.target.value})
                        }}/>

                    </div>
                </div>

                <div className={cx('table')}>
                    <div style={{width: 200}}>환경변수</div>
                    <div style={{width: 'calc(100% - 200px)'}}>
                    <textarea
                        className='form-control'
                        style={{resize: 'none', height: 100}}
                        value={permission.env || ''}
                        onChange={event => {
                            setPermission({...permission, env: event.target.value})
                        }}/>
                    </div>
                </div>
                <div className={cx('table')}>
                    <div style={{width: 200}}>앱이름</div>
                    <div style={{width: 'calc(100% - 200px)'}}>
                        <input
                            value={permission.appNm || ''}
                            className='form-control form-control-sm'
                            onChange={event => {
                                // setPermission({...permission, appNm: event.target.value})
                            }}/>
                    </div>
                </div>

                <div className={cx('table')}>
                    <div style={{width: 200}}>활성화</div>
                    <div style={{width: 'calc(100% - 200px)'}}>
                        <BadgeIsActivate isActivate={permission.isActivate || false}/>
                        <Switch
                            color='primary'
                            checked={permission.isActivate || false}
                            onChange={(event, checked) => {
                                setPermission({...permission, isActivate: checked});
                            }}/>

                    </div>
                </div>

                <div className={cx('table')}>
                    <div style={{width: 200}}>개발모드</div>
                    <div style={{width: 'calc(100% - 200px)'}}>
                        <BadgeIsActivate isActivate={permission.devMode || false}/>
                        <Switch
                            color='primary'
                            checked={permission.devMode || false}
                            onChange={(event, checked) => {
                                setPermission({...permission, devMode: checked});
                            }}/>
                    </div>
                </div>


                <div style={{height: 40}}/>
                <Title leftPadding={10}>연결된 API 키</Title>
                <div className={cx('table')}>
                    <div style={{width: 50}}>삭제</div>
                    <div style={{width: 200}}>API</div>
                    <div style={{width: 'calc((100% - 250px)/4)'}}>이름</div>
                    <div style={{width: 'calc((100% - 250px)/4)'}}>기본요금</div>
                    <div style={{width: 'calc((100% - 250px)/4)'}}>대리점제공</div>
                    <div style={{width: 'calc((100% - 250px)/4)'}}>시스템사용</div>
                </div>
                {permission.apiKeys.map((value, index) => {
                    return (
                        <div className={cx('table', 'table-apikey')} key={index}>
                            <div style={{width: 50}}>
                                <IconButton size='small' onClick={ev => {
                                    const copy = CopyUtil.copyAll(permission);
                                    copy.apiKeys = [...copy.apiKeys.slice(0, index), ...copy.apiKeys.slice(index + 1, copy.apiKeys.length)];
                                    setPermission(copy);
                                }}>
                                    <Close/>
                                </IconButton>
                            </div>
                            <div
                                style={{width: 200}}>
                                <UserPermissionInfo permission={props.userPermission} apiKey={value.apiKey}/>
                            </div>
                            <div style={{width: 'calc((100% - 250px)/4)'}}>
                                <input
                                    value={value.nm}
                                    className='form-control form-control-sm text-center'
                                    onChange={event => {
                                        onChangeApiValue(index, event.target.value);
                                    }}/>
                            </div>
                            <div style={{width: 'calc((100% - 250px)/4)'}}>
                                <NumberFormat
                                    className='form-control form-control-sm text-right'
                                    value={value.defaultUserSmsFee}
                                    decimalScale={1}
                                    allowNegative={false}
                                    suffix={' P'}
                                    onValueChange={values => {
                                        const copy = CopyUtil.copyAll(permission);
                                        copy.apiKeys[index].defaultUserSmsFee = values.floatValue || 0;
                                        setPermission(copy);
                                    }}/>
                            </div>
                            <div style={{width: 'calc((100% - 250px)/4)'}}>
                                <Switch
                                    color='primary'
                                    checked={value.isDefault}
                                    onChange={ev => {
                                        const copy = CopyUtil.copyAll(permission);
                                        copy.apiKeys[index].isDefault = ev.target.checked;
                                        setPermission(copy);
                                    }}/>
                            </div>
                            <div style={{width: 'calc((100% - 250px)/4)'}}>
                                <Switch
                                    color='primary'
                                    checked={value.systemDefault}
                                    onChange={ev => {
                                        if (ev.target.checked) {
                                            const copy = CopyUtil.copyAll(permission);
                                            for (let i = 0; i < copy.apiKeys.length; i++) {
                                                copy.apiKeys[i].systemDefault = false;
                                            }
                                            copy.apiKeys[index].systemDefault = true;
                                            setPermission(copy);
                                        }
                                    }}/>
                            </div>
                        </div>
                    )
                })}


                <div style={{height: 30}}/>
                <Title leftPadding={10}>제공가능 API 키</Title>
                <div className={cx('table')}>
                    <div style={{width: 150}}>회선이름</div>
                    <div style={{flexGrow: 1}}>건당가격</div>
                </div>

                {props.userPermission
                    .filter(value => {
                        // 회선에 추가되지 않는 API 리스트 표시
                        if (!permission) {
                            return true
                        }

                        const isAdded = permission.apiKeys.findIndex((apiKey) => {
                            return apiKey.apiKey === value.apiKey
                        });

                        return isAdded === -1
                    })
                    .map((value, index) => (
                        <div key={index} className={cx('table', 'hover')} onClick={event => {
                            const copy = CopyUtil.copyAll(permission);
                            copy.apiKeys.push({
                                apiKey: value.apiKey,
                                nm: value.nm,
                                defaultUserSmsFee: 20,
                                isDefault: false,
                                systemDefault: false,
                            });
                            setPermission(copy);
                        }}>
                            <div style={{width: 150}}>{value.nm || '-'}</div>
                            <div style={{flexGrow: 1}}>{value.smsFee} P</div>
                        </div>
                    ))}


                {permission.apiKeys.length === 0 &&
                <div style={{width: '100%'}}>등록된 API 키가 없습니다.</div>
                }

                <div className={cx('table')}>
                    <div className='text-right' style={{width: '100%'}}>
                        <button
                            className='btn btn-sm btn-success'
                            onClick={onUpdate}>저장
                        </button>
                    </div>
                </div>
            </>
            }
        </>
    );
}

const UserPermissionInfo = (props: {
    permission: UserPermission[],
    apiKey: string
}) => {
    const permission = props.permission.find(value => {
        return value.apiKey === props.apiKey
    });

    if (permission) {
        return <>{permission.nm} : ({permission.smsFee} P)</>
    } else {
        return <>없음</>
    }
}

const CreateModal = (props: {
    userPermission: UserPermission[],
    onCreateClientPermission: (input: CreateClientPermission) => void,
}) => {
    const [permission, setPermission] = useState<CreateClientPermission>({
        adminFb: "",
        apiKey: "",
        appNm: "",
        clientFb: "",
        env: "",
        nm: "",
        dbSchemaNm: ""
    })

    useEffect(() => {
        if (0 < props.userPermission.length) {
            setPermission({...permission, apiKey: props.userPermission[0].apiKey})
        }
    }, [props.userPermission]);

    const onCreateClientPermission = () => {
        if (!permission.adminFb) {
            SwalUtil.ok({
                msg: '관리자 FB를 입력하여 주십시오.',
                icon: 'warning'
            })
            return;
        }

        if (!permission.clientFb) {
            SwalUtil.ok({
                msg: '사용자 FB를 입력하여 주십시오.',
                icon: 'warning'
            })
            return;
        }

        if (!permission.appNm) {
            SwalUtil.ok({
                msg: '앱 이름을 입력하여 주십시오.',
                icon: 'warning'
            })
            return;
        }

        if (!permission.apiKey) {
            SwalUtil.ok({
                msg: 'API 키를 지정하여 주십시오.',
                icon: 'warning'
            })
            return;
        }

        props.onCreateClientPermission(permission);
    }

    return (
        <>
            <div className={cx('table')}>
                <div style={{width: 200}}>관리자 FB</div>
                <div style={{width: 'calc(100% - 200px)'}}>
                    <textarea
                        className='form-control'
                        style={{resize: 'none', height: 100}}
                        value={permission.adminFb || ''}
                        onChange={event => {
                            setPermission({...permission, adminFb: event.target.value})
                        }}/>

                </div>
            </div>
            <div className={cx('table')}>
                <div style={{width: 200}}>사용자 FB</div>
                <div style={{width: 'calc(100% - 200px)'}}>
                    <textarea
                        className='form-control'
                        style={{resize: 'none', height: 100}}
                        value={permission.clientFb || ''}
                        onChange={event => {
                            setPermission({...permission, clientFb: event.target.value})
                        }}/>

                </div>
            </div>
            <div className={cx('table')}>
                <div style={{width: 200}}>환경변수</div>
                <div style={{width: 'calc(100% - 200px)'}}>
                    <textarea
                        className='form-control'
                        style={{resize: 'none', height: 100}}
                        value={permission.env || ''}
                        onChange={event => {
                            setPermission({...permission, env: event.target.value})
                        }}/>
                </div>
            </div>
            <div className={cx('table')}>
                <div style={{width: 200}}>앱이름</div>
                <div style={{width: 'calc(100% - 200px)'}}>
                    <input
                        value={permission.appNm}
                        className='form-control form-control-sm'
                        onChange={event => {
                            setPermission({...permission, appNm: event.target.value})
                        }}/>
                </div>
            </div>

            <div className={cx('table')}>
                <div style={{width: 200}}>DB 스키마</div>
                <div style={{width: 'calc(100% - 200px)'}}>
                    <input
                        value={permission.dbSchemaNm}
                        className='form-control form-control-sm'
                        onChange={event => {
                            setPermission({...permission, dbSchemaNm: event.target.value})
                        }}/>
                </div>
            </div>

            <div className={cx('table')}>
                <div style={{width: 200}}>API 키</div>
                <div style={{width: 'calc(100% - 200px)'}}>
                    <select className='form-control form-control-sm'
                            value={permission.apiKey}
                            onChange={event => {
                                setPermission({...permission, apiKey: event.target.value});
                            }}>
                        <option disabled value={''}>API 키를 선택하여 주십시오.</option>
                        {props.userPermission.map((value, index) => {
                            return (
                                <option value={value.apiKey}>{value.nm || '이름없음'}</option>
                            )
                        })}
                    </select>
                </div>
            </div>

            <div className={cx('table')}>
                <div className='text-right' style={{width: '100%'}}>
                    <button
                        className='btn btn-sm btn-success'
                        onClick={onCreateClientPermission}>생성
                    </button>
                </div>
            </div>
        </>
    );
}
//
// const ApiColumn: GridColDef[] = [
//     {field: 'apiKey', headerName: 'ApiKey', width: 200},
//     {field: 'nm', headerName: 'Name', width: 150, editable: true, type: 'string'},
//     {field: 'smsFee', headerName: 'SmsFee', width: 150, editable: true, type: 'string'},
// ];
//
// type ApiRows = {
//     apiKey: string,
//     nm: string,
//     smsFee: number,
// };
