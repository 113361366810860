import React, {useEffect, useState} from "react";
import classNamesBind from "classnames/bind";
import styles from './MngUserViewCoupon.module.scss'
import {UpdateUserCoupon, UserCoupon, UserList} from "../../../../graphql/types";
import {BizTitle} from "../../../shares/title/Title";
import {Checkbox, IconButton, Tooltip} from "@material-ui/core";
import {CopyUtil} from "../../../../utils/copy/CopyUtil";
import NumberFormat from "react-number-format";
import Decimal from "decimal.js";
import {Pipe} from "../../../../utils/pipe/pipe";
import {Close} from "@material-ui/icons";
import {OnChangeValue} from "../../../shares/interfaces/interfaces";
import {useSelector} from "react-redux";
import {LayoutState} from "../../../../redux/Layout";
import ScrollArea from "react-scrollbar";

const cx = classNamesBind.bind(styles);

export const MngUserViewCoupon = (props: {
    list: UserCoupon[],
    onUpdateUserCoupon: OnChangeValue<UpdateUserCoupon[]>,
}) => {
    const [updateList, setUpdateList] = useState<UpdateUserCoupon[]>([]);

    const [userList, setUserList] = useState<UserList | undefined>();
    const [createUserModal, setCreateUserModal] = useState(false);

    const [scrollAreaHeight, setScrollAreaHeight] = useState<any>();
    const [scrollAreaWidth, setScrollAreaWidth] = useState<any>();
    const [scrollAreaContent, setScrollAreaContent] = useState<any>();

    const layout = useSelector<any, LayoutState>(state => state.layout);

    useEffect(() => {
        if (layout.viewMode === 0) {
            setScrollAreaHeight("auto");
            setScrollAreaWidth("auto");
            setScrollAreaContent("auto");
        } else {
            setScrollAreaHeight(285);
            setScrollAreaWidth(315);
            setScrollAreaContent(styles.scrollTableWidthForMobile);
        }
    }, [layout.viewMode]);

    useEffect(() => {
        const next: UpdateUserCoupon[] = props.list.map(value => ({
            isActivate: value.isActivate,
            smsCount: value.smsCount,
            smsFee: value.smsFee,
            taxRate: value.taxRate,
            taxAmount: value.taxAmount,
            amount: value.amount,
        }));
        setUpdateList(next);
    }, [props.list]);

    const handler = {
        onDelete: (idx: number) => {
            let copy = CopyUtil.copy(updateList);
            copy = [...copy.slice(0, idx), ...copy.slice(idx + 1, copy.length)];
            setUpdateList(copy);
        },
        onUpdate: () => {
            props.onUpdateUserCoupon(updateList);
        },
        onAdd: () => {
            let copy = CopyUtil.copy(updateList);
            copy.push({
                amount: 0,
                isActivate: false,
                smsCount: 0,
                smsFee: 0,
                taxAmount: 0,
                taxRate: 0
            })
            setUpdateList(copy);
        }
    }

    return (
        <>
            <BizTitle title={'이용 쿠폰'}/>
            <ScrollArea
                style={{height: scrollAreaHeight, width: scrollAreaWidth}}
                contentStyle={{width: scrollAreaContent}}
                smoothScrolling={true}
            >
                <div className={cx('table')}>
                    <div className={cx('header', "activateCell")}>활성화</div>
                    <div className={cx('header', 'smsFeeCell')}>건당가격</div>
                    <div className={cx('header', 'smsCountCell')}>건수</div>
                    <div className={cx('header', 'taxRateCell')}>수수료비율</div>
                    <div className={cx('header', 'taxCell')}>수수료</div>
                    <div className={cx('header', 'amountCell')}>쿠폰가격</div>
                    <div className={cx('header', 'funcCell')}>기능</div>
                </div>

                {updateList.map((value, index) => (
                    <div key={index} className={cx('table')}>
                        <div className={cx('col', "activateCell")}>
                            <Checkbox
                                color='primary'
                                checked={value.isActivate}
                                onChange={ev => {
                                    const copy = CopyUtil.copyAll(updateList);
                                    copy[index].isActivate = ev.target.checked;
                                    setUpdateList(copy);
                                }}/>
                        </div>
                        <div className={cx("col", "smsFeeCell", "textRight")}>
                            <NumberFormat
                                suffix={' 원'}
                                thousandSeparator
                                className='form-control form-control-sm text-right'
                                allowNegative={false}
                                decimalScale={1}
                                value={value.smsFee}
                                onValueChange={v => {
                                    const copy = CopyUtil.copyAll(updateList);
                                    copy[index].smsFee = v.floatValue || 0;
                                    copy[index].taxAmount = new Decimal(copy[index].smsFee)
                                        .mul(copy[index].smsCount)
                                        .mul(copy[index].taxRate)
                                        .toNumber();
                                    copy[index].amount = new Decimal(copy[index].smsFee)
                                        .mul(copy[index].smsCount)
                                        .add(copy[index].taxAmount)
                                        .toNumber();
                                    setUpdateList(copy);
                                }}/>
                        </div>
                        <div className={cx("textRight", "col", "smsCountCell")}>
                            <NumberFormat
                                suffix={' 개'}
                                thousandSeparator
                                className='form-control form-control-sm text-right'
                                allowNegative={false}
                                decimalScale={0}
                                value={value.smsCount}
                                onValueChange={v => {
                                    const copy = CopyUtil.copyAll(updateList);
                                    copy[index].smsCount = v.floatValue || 0;
                                    copy[index].taxAmount = new Decimal(copy[index].smsFee)
                                        .mul(copy[index].smsCount)
                                        .mul(copy[index].taxRate)
                                        .toNumber();
                                    copy[index].amount = new Decimal(copy[index].smsFee)
                                        .mul(copy[index].smsCount)
                                        .add(copy[index].taxAmount)
                                        .toNumber();
                                    setUpdateList(copy);
                                }}/>

                        </div>
                        <div className={cx("col", "taxRateCell", "textRight")}>
                            <NumberFormat
                                suffix={' %'}
                                thousandSeparator
                                className='form-control form-control-sm text-right'
                                allowNegative={false}
                                decimalScale={1}
                                value={new Decimal(value.taxRate).mul(100).toNumber()}
                                onValueChange={v => {
                                    const copy = CopyUtil.copyAll(updateList);
                                    copy[index].taxRate = new Decimal(v.floatValue || 0).div(100).toNumber();
                                    copy[index].taxAmount = new Decimal(copy[index].smsFee)
                                        .mul(copy[index].smsCount)
                                        .mul(copy[index].taxRate)
                                        .toNumber();
                                    copy[index].amount = new Decimal(copy[index].smsFee)
                                        .mul(copy[index].smsCount)
                                        .add(copy[index].taxAmount)
                                        .toNumber();
                                    setUpdateList(copy);
                                }}/>
                        </div>
                        <div className={cx("col", "taxCell", "textRight")}>
                            {Pipe.toSeparatorNumber(value.taxAmount)} 원
                        </div>
                        <div className={cx("col", "amountCell", "textRight")}>
                            {Pipe.toSeparatorNumber(value.amount)} 원
                        </div>
                        <div className={cx("col", "funcCell")}>
                            <Tooltip title={'삭제'}>
                                <IconButton onClick={() => handler.onDelete(index)}>
                                    <Close/>
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>
                ))}
            </ScrollArea>

            <div className={cx('table')}>
                <div className={cx('col-full', 'text-right')}>
                    <button
                        className='btn btn-sm btn-outline-success'
                        onClick={handler.onAdd}>
                        추가
                    </button>
                    <button
                        style={{marginLeft: 10}}
                        onClick={handler.onUpdate}
                        className='btn btn-sm btn-success'>
                        저장
                    </button>
                </div>
            </div>
        </>
    )
}
