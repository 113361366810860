import React, {Fragment, ReactNode} from 'react';
import {Modal} from "react-bootstrap";
import {Close} from "@material-ui/icons";
import classNamesBind from "classnames/bind";
import styles from './ModalWeb.module.scss';
import PerfectScrollbar from "react-perfect-scrollbar";

const cx = classNamesBind.bind(styles);


export const ModalWeb = (props: {
    title: ReactNode,
    children: ReactNode,
    isShow: boolean,
    onCancel: () => void,
    onOk: () => void,
    size?: 'sm' | 'lg' | 'xl';
}) => {
    return (
        <Fragment>
            <Modal
                show={props.isShow}
                onHide={props.onCancel}
                size={props.size ? props.size : 'lg'}
                centered>
                <div className={cx('header')}>
                    <div>{props.title}</div>
                    <Close onClick={event => {
                        props.onCancel();
                    }}/>
                </div>

                <PerfectScrollbar style={{maxHeight: '60%', overflow: 'hidden'}}>
                    {props.children}
                </PerfectScrollbar>
            </Modal>
        </Fragment>
    )
}
