import React from "react";
import classNamesBind from "classnames/bind";
import styles from './SettleMonthlyInput.module.scss'
import {OnChangeValue} from "../../../shares/interfaces/interfaces";
import NumberFormat from "react-number-format";

const cx = classNamesBind.bind(styles);

export const SettleMonthlyInput = (props: {
    value: number,
    onChangeValue: OnChangeValue<number>
}) => {
    return (
        <>
            <div className={cx('table')}>
                <div className={cx('col', 'col-label')}>회선원가</div>
                <div className={cx('col', 'col-value')}>
                    <NumberFormat
                        suffix={' 원'}
                        className='form-control form-control-sm text-right'
                        value={props.value || 11.5}
                        allowNegative={false}
                        min={1}
                        onValueChange={values =>  {
                            props.onChangeValue(values.floatValue || 11.5)
                        }}
                    />

                </div>
                <div className={cx('col', 'col-space')}></div>
            </div>
        </>
    )
}
