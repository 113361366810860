import React, {useEffect, useState} from "react";
import classNamesBind from "classnames/bind";
import styles from './SpeedTestConfig.module.scss'
import gql from "graphql-tag";
import {SpeedTestLine, SpeedTestNumber} from "../../../graphql/types";
import {useLazyQuery, useMutation} from "@apollo/client";
import {FadeInContainer} from "../../shares/fadeInContainer/FadeInContainer";
import {Title} from "../../shares/title/Title";
import {Switch} from "@material-ui/core";
import {ApolloCatch} from "../../../utils/apollo/apolloExec";
import {BizErrors} from "../../../graphql/errors";
import {SwalUtil} from "../../../utils/swal/swalUtil";
import {BadgeIsActivate} from "../../shares/badgeIsActivate/BadgeIsActivate";
import {CopyUtil} from "../../../utils/copy/CopyUtil";
import {BizCol12} from "../../shares/bizCol12/BizCol12";
import {useSelector} from "react-redux";
import {LayoutState} from "../../../redux/Layout";
import ScrollArea from "react-scrollbar";

const cx = classNamesBind.bind(styles);

export const SpeedTestConfig = () => {
    const [getData, resData] = useLazyQuery<ResData>(gqlData);
    const [data, setData] = useState<ResData | undefined>();
    const [mutToggleNumber] = useMutation<any, {
        testNumberId: number,
        swt: boolean
    }>(gqlToggleNumber);
    const [mutToggleLine] = useMutation<any, {
        smsLineId: number,
        swt: boolean,
    }>(gqlToggleLine);
    const [mutUpdateApiKey] = useMutation<any, {
        smsLineId: number,
        apiKey: string,
    }>(gqlUpdateApiKey);
    const [mutUpdateNumber] = useMutation<any, {
        testNumberId: number,
        number: string,
    }>(gqlUpdateNumber);

    const [scrollAreaHeight, setScrollAreaHeight] = useState<any>();
    const [scrollAreaWidth, setScrollAreaWidth] = useState<any>();
    const [scrollAreaContent, setScrollAreaContent] = useState<any>();

    const layout = useSelector<any, LayoutState>(state => state.layout);

    useEffect(() => {
        if (layout.viewMode === 0) {
            setScrollAreaHeight("auto");
            setScrollAreaWidth("auto");
            setScrollAreaContent("auto");
        } else {
            setScrollAreaHeight(500);
            setScrollAreaWidth(315);
            setScrollAreaContent(styles.scrollTableWidthForMobile);
        }
    }, [layout.viewMode]);

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        if (resData.data) {
            setData(resData.data);
        }
    }, [resData.data]);

    const onToggleSpeedTestNumber = (testNumberId: number, swt: boolean) => {
        mutToggleNumber({variables: {testNumberId, swt}})
            .then(res => {
                getData();
            })
            .catch(ApolloCatch({
                [BizErrors.default]: SwalUtil.bizError(),
            }));
    }

    const onToggleSpeedTestLine = (smsLineId: number, swt: boolean) => {
        mutToggleLine({variables: {smsLineId, swt}})
            .then(res => {
                getData();
            })
            .catch(ApolloCatch({
                [BizErrors.default]: SwalUtil.bizError(),
            }));
    }

    const onUpdateApiKey = (smsLineId: number, apiKey: string) => {
        SwalUtil.yn({
            msg: '변경 하시겠습니까?',
            icon: 'question',
            ok: () => {
                mutUpdateApiKey({variables: {smsLineId, apiKey}})
                    .then(res => {
                        SwalUtil.ok({
                            msg: "수정 되었습니다.",
                            icon: "success"
                        })
                        getData();
                    })
                    .catch(ApolloCatch({
                        [BizErrors.default]: SwalUtil.bizError(),
                    }));
            }
        });
    }

    const onUpdateNumber = (testNumberId: number, number: string) => {
        SwalUtil.yn({
            msg: '변경 하시겠습니까?',
            icon: 'question',
            ok: () => {
                mutUpdateNumber({variables: {testNumberId, number}})
                    .then(res => {
                        SwalUtil.ok({
                            msg: "수정 되었습니다.",
                            icon: "success"
                        })
                        getData();
                    })
                    .catch(ApolloCatch({
                        [BizErrors.default]: SwalUtil.bizError(),
                    }));
            }
        });
    }

    const onChangeApiKey = (index: number, apiKey: string) => {
        if (!data) {
            return;
        }

        let changedData: ResData = {
            speedTestLine: CopyUtil.copy(data.speedTestLine),
            speedTestNumber: CopyUtil.copy(data.speedTestNumber)
        }

        changedData.speedTestLine[index].apiKey = apiKey;
        setData(changedData);
    }

    const onChangeNumber = (index: number, numb: string) => {
        if (!data) {
            return;
        }

        let changedData: ResData = {
            speedTestLine: CopyUtil.copy(data.speedTestLine),
            speedTestNumber: CopyUtil.copy(data.speedTestNumber)
        }

        changedData.speedTestNumber[index].number = numb;
        setData(changedData);
    }

    return (
        <>
            {data &&
            <FadeInContainer>
                <BizCol12>
                    <Title>전화번호</Title>
                    {data.speedTestNumber.map((value, index) => {
                        return (
                            <div className={cx('table')} key={index}>
                                <div className={cx("col", "activateCell")}>
                                    <Switch
                                        color='primary'
                                        checked={value.isActivated}
                                        onChange={(event, checked) => {
                                            onToggleSpeedTestNumber(value.id, checked);
                                        }}/>
                                </div>
                                <div className={cx("col", "carrierCell")}>{value.company}</div>
                                <div className={cx("col", "numberCell")}>
                                    <input
                                        className='form-control form-control-sm'
                                        value={value.number}
                                        onChange={event => {
                                            onChangeNumber(index, event.target.value);
                                        }}/>
                                </div>
                                <div className={cx("col", "funcCell")}>
                                    <button
                                        style={{width: '100%'}}
                                        className='btn btn-sm btn-success'
                                        onClick={event => {
                                            onUpdateNumber(value.id, value.number);
                                        }}>
                                        수정
                                    </button>
                                </div>
                            </div>
                        )
                    })}
                </BizCol12>

                <BizCol12>
                    <Title>회선</Title>

                    <ScrollArea
                        style={{height: scrollAreaHeight, width: scrollAreaWidth}}
                        contentStyle={{width: scrollAreaContent}}
                        smoothScrolling={true}
                    >
                    {data.speedTestLine.map((value, index) => {
                        return (
                            <div className={cx('table', "scrollTable")} key={index}>
                                <div className={cx("col", "idCell")}>{value.smsLineId}</div>
                                <div className={cx("col", "activateCell")}>
                                    {value.isActivated &&
                                    <Switch
                                        color='primary'
                                        checked={value.hasTest}
                                        onChange={(event, checked) => {
                                            onToggleSpeedTestLine(value.smsLineId, checked);
                                        }}/>
                                    }
                                </div>
                                <div className={cx("col", "badgeCell")}>
                                    <BadgeIsActivate isActivate={value.isActivated}/>
                                </div>
                                <div className={cx("col", "nameCell", "textLeft")}>{value.smsLineNm}</div>

                                <div className={cx("col", "keyCell")}>
                                    <input
                                        value={value.apiKey}
                                        className='form-control form-control-sm'
                                        onChange={event => {
                                            onChangeApiKey(index, event.target.value);
                                        }}/>
                                </div>
                                <div className={cx("col", "funcCell")}>
                                    <button
                                        style={{width: '100%'}}
                                        className='btn btn-sm btn-success'
                                        onClick={event => {
                                            onUpdateApiKey(value.smsLineId, value.apiKey);
                                        }}>
                                        수정
                                    </button>
                                </div>
                            </div>
                        )
                    })}
                    </ScrollArea>
                </BizCol12>
            </FadeInContainer>
            }
        </>
    )
}

interface ResData {
    speedTestLine: SpeedTestLine[],
    speedTestNumber: SpeedTestNumber[],
}

const gqlData = gql`
    query data {
        speedTestLine {
            smsLineId
            smsLineNm
            hasTest
            isActivated
            apiKey
        }
        speedTestNumber {
            id
            isActivated
            company
            number
        }
    }
`;


const gqlToggleNumber = gql`
    mutation toggleSpeedTestNumber($testNumberId: Int!, $swt: Boolean!) {
        toggleSpeedTestNumber(testNumberId: $testNumberId, swt: $swt)
    }
`;

const gqlToggleLine = gql`
    mutation toggleSppedTestLine($smsLineId: Int!, $swt: Boolean!) {
        toggleSpeedTestLine(smsLineId: $smsLineId, swt: $swt)
    }
`;

const gqlUpdateApiKey = gql`
    mutation updateApiKey($smsLineId: Int!, $apiKey: String!) {
        updateSpeedTestLineApiKey(smsLineId: $smsLineId, apiKey: $apiKey)
    }
`;

const gqlUpdateNumber = gql`
    mutation updateNumber($testNumberId: Int!, $number: String!) {
        updateSpeedTestNumber(testNumberId: $testNumberId, number: $number)
    }
`
