import React, {Fragment} from "react";
import styles from './TelBookSummaryList.module.scss'
import classNames from "classnames";
import {IconButton, Tooltip} from "@material-ui/core";
import {CloudDownload} from "@material-ui/icons";
import {EsRequestStatus} from "../../../../graphql/types";
import moment from "moment";
import {BizNoDataTable} from "../../../shares/bizNoDataTable/BizNoDataTable";
import ReactJson from 'react-json-view'
import {Pipe} from "../../../../utils/pipe/pipe";


export const TelBookSummaryList = (props: {
    list: EsRequestStatus[],
}) => {

    return (
        <Fragment>
            <div className={classNames(styles.table, styles.tableList)}>
                <div className={classNames(styles.header, styles.colRequestId)}>요청키</div>
                <div className={classNames(styles.header, styles.colCreatedAt)}>요청시</div>
                <div className={classNames(styles.header, styles.colParams)}>파라미터</div>
                <div className={classNames(styles.header, styles.colDown)}>다운로드</div>
            </div>

            {props.list.map((value, index) => (
                <div key={index} className={classNames(styles.table, styles.hover, styles.tableList)}>
                    <div className={classNames(styles.col, styles.colRequestId)}>{value.requestId}</div>
                    <div className={classNames(styles.col, styles.colCreatedAt)}>{moment(value.requestAt).format('YY.MM.DD hh:mm a')}</div>
                    <div className={classNames(styles.colParams)}>
                        <ReactJson
                            collapsed={true}
                            displayObjectSize={false}
                            displayDataTypes={false}
                            src={JSON.parse(value.options)}/>
                    </div>
                    <div className={classNames(styles.col, styles.colDown)}>
                        {value.filePath &&
                        <Tooltip title={'다운로드'}>
                            <IconButton onClick={() => Pipe.downloadFile(value.filePath)}>
                                <CloudDownload/>
                            </IconButton>
                        </Tooltip>
                        }
                    </div>
                </div>
            ))}

            {props.list.length === 0 && <BizNoDataTable/>}
        </Fragment>
    )
}
