import React, { useState, useEffect } from "react";
import classNamesBind from "classnames/bind";
import styles from './DashboardDailySendReport.module.scss'
import {SmsDailySendReport} from "../../../graphql/types";
import {BizSmTitle} from "../../shares/bizSmTitle/BizSmTitle";
import {Pipe} from "../../../utils/pipe/pipe";
import Chart from "react-apexcharts";
import { useSelector } from "react-redux";
import { LayoutState } from "../../../redux/Layout";
import {ApexOptions} from "apexcharts";

const cx = classNamesBind.bind(styles);

export const DashboardDailySendReport = (props: {
    report: SmsDailySendReport
}) => {
    return (
        <div className={cx("fullContainer")}>
            <div className={cx("halfContainer")}>
                <SmsDailySendReportGraph
                    report={props.report}/>
            </div>
            <div className={cx("halfContainer")}>
                <BizSmTitle>시스템 전송 요약</BizSmTitle>
                <div className={cx('table')}>
                    <div className={cx("titleCell")}>전체전송</div>
                    <div className={cx("valueCell", "textRight")}>
                        {Pipe.toSeparatorNumber(props.report.requested)} 개
                    </div>
                </div>
                <div className={cx('table')}>
                    <div className={cx("titleCell")}>전송완료</div>
                    <div className={cx("valueCell", "textRight")}>
                        {Pipe.toSeparatorNumber(props.report.requested - props.report.fail)} 개
                    </div>
                </div>
                <div className={cx('table')}>
                    <div className={cx("titleCell")}>전송실패</div>
                    <div className={cx("valueCell", "textRight")}>
                        {Pipe.toSeparatorNumber(props.report.fail)} 개
                    </div>
                </div>


                <div style={{height: 30}}/>
                <BizSmTitle>DLR 수신 요약</BizSmTitle>
                <div className={cx('table')}>
                    <div className={cx("titleCell")}>총 수신량</div>
                    <div className={cx("valueCell", "textRight")}>
                        {Pipe.toSeparatorNumber(props.report.delivered + props.report.undelivered)} 개
                    </div>
                </div>
                <div className={cx('table')}>
                    <div className={cx("titleCell")}>수신완료</div>
                    <div className={cx("valueCell", "textRight")}>
                        {Pipe.toSeparatorNumber(props.report.delivered)} 개
                    </div>
                </div>
                <div className={cx('table')}>
                    <div className={cx("titleCell")}>수신실패</div>
                    <div className={cx("valueCell", "textRight")}>
                        {Pipe.toSeparatorNumber(props.report.undelivered)} 개
                    </div>
                </div>
            </div>
        </div>
    )
};

const SmsDailySendReportGraph = (props: {
    report: SmsDailySendReport
}) => {
    const cx = classNamesBind.bind(styles);

    const [chartHeight, setChartHeight] = useState<string>();
    const layout = useSelector<any, LayoutState>(state => state.layout);

    useEffect(() => {
        if (layout.viewMode === 0) {
            setChartHeight(styles.chartBrowserHeight);
        } else {
            setChartHeight(styles.chartMobileHeight);
        }
    }, [layout.viewMode]);

    const data: Data[] = [
        {label: '수신완료', value: props.report.delivered, color: '#ffffff'},
        {label: '미확인', value: props.report.requested - (props.report.delivered + props.report.undelivered), color: '#ffffff'},
        {label: '수신실패', value: props.report.undelivered, color: '#ffffff'},
        {label: '전송실패', value: props.report.fail, color: '#ffffff'},
    ];

    const options: ApexOptions = {
        chart: {
            type: "donut",
            toolbar: {
                show: false
            },
            animations: {
                enabled: false
            },
        },
        labels: data.map(value => {
            return value.label;
        }),
        legend: {
            show: true,
            position: "bottom"
        }
    };

    const series = data.map(value => {
        return value.value;
    });

    return (
        <>
            <Chart
                className={cx("chart")}
                type="donut"
                options={options}
                series={series}
                width={styles.chartWidth}
                height={chartHeight}/>
        </>
    )
}

interface Data {
    label: string;
    value: number;
    color: string;
}
