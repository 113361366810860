export class NumbUtil {
    static countNumb(numb: string): NumbUtilResult {
        const list = numb.trim().split("\n");
        let res = "";
        let count = 0;

        for (let numbString of list) {
            if (numbString) {
                res += `${numbString}\n`;
                count += 1;
            }
        }

        return {count: count, numb: res.trim()};
    }

    static onlyNumber(numb: string): NumbUtilResult {
        const list = numb.split("\n");
        let res = "";
        let count = 0;

        for (let numbString of list) {
            res += `${numbString.replace(/[^0-9]/g, '')}\n`;
            count += 1;
        }

        return {count: count, numb: res.trim()};
    }

    static wrongContext(numb: string): NumbUtilResult {
        const list = numb.split("\n");
        let res = "";
        let count = 0;


        for (let numbString of list) {
            const regEx = /^(010|10)([0-9]{8})$/g;
            if (regEx.exec(numbString)) {
                res += `${numbString}\n`;
                count += 1;
            }
        }

        return {count: count, numb: res.trim()};
    }

    static duplicated(numb: string): NumbUtilResult {
        const list = numb.split("\n");
        let res = "";
        let count = 0;
        const map: { [numb: string]: string } = {};

        for (let numbString of list) {
            if (!map.hasOwnProperty(numbString)) {
                map[numbString] = '';
                res += `${numbString}\n`;
                count += 1;
            }
        }

        return {count: count, numb: res.trim()};
    }


    static clearAndAppendNationCode(numb: string): string[] {
        const cleared = this.countNumb(numb);
        const numbList = cleared.numb.split('\n');

        const res: string[] = [];

        for (let numb of numbList) {
            if (numb.startsWith("01", 0)) {
                numb = "82" + numb.substr(1, numb.length);
                res.push(numb);
            } else if (numb.startsWith("10", 0)) {
                numb = "82" + numb;
                res.push(numb);
            }
        }

        return res;
    }
}

export class TemplateNumbUtil {
    static countNumber(msg: string, numb: string): TemplateNumberResult {
        const list = numb.trim().split('\n');
        const resList: TemplateSms[] = [];

        for (let data of list) {
            const splitData = data.split(',');

            if (splitData.length === 0) {
                continue;
            }

            const sms: TemplateSms = {
                number: splitData[0],
                data: splitData.slice(1, splitData.length),
                msg: msg,
                isAvailable: false,
            }

            resList.push(sms);
        }


        return {
            count: resList.length,
            values: resList,
        };
    }

    static parsingMsg(msg: string, data: TemplateNumberResult): TemplateNumberResult {
        const splitMsg = msg.split('###');

        const res: TemplateSms[] = [];

        for (let value of data.values) {
            if ((splitMsg.length - 1) !== value.data.length) {
                res.push({
                    number: value.number,
                    data: value.data,
                    msg: '데이터를 확인하여 주십시오.',
                    isAvailable: false
                });
                continue;
            }

            let resMsg = "";
            for (let i = 0; i < splitMsg.length; i++) {
                resMsg += `${splitMsg[i]}${value.data[i] || ''}`
            }

            res.push({
                number: value.number,
                data: value.data,
                msg: resMsg,
                isAvailable: true
            })
        }

        return {
            count: res.length,
            values: res,
        };
    }

    static extractDuplicatedNumber(data: TemplateNumberResult): TemplateNumberResult {
        const map: { [numb: string]: string } = {};

        const res: TemplateSms[] = [];

        for (let value of data.values) {
            if (!map.hasOwnProperty(value.number)) {
                res.push(value);
                map[value.number] = "";
            }
        }


        return {
            count: res.length,
            values: res
        }
    }

    static extractWrongNumber(data: TemplateNumberResult): TemplateNumberResult {
        const res: TemplateSms[] = [];

        for (let value of data.values) {
            const regEx = /^(010|10)([0-9]{8})$/g;
            if (regEx.exec(value.number)) {
                res.push(value);
            }
        }

        return {
            count: res.length,
            values: res
        }
    }

    static appendNationCode(data: TemplateNumberResult): TemplateNumberResult {
        const res: TemplateSms[] = [];

        for (let value of data.values) {
            if (value.number.startsWith("01", 0)) {
                value.number = "82" + value.number.substr(1, value.number.length);
                res.push(value);
            } else if (value.number.startsWith("10", 0)) {
                value.number = "82" + value.number;
                res.push(value);
            }
        }

        return {
            count: res.length,
            values: res
        }
    }
}

export interface TemplateNumberResult {
    count: number;
    values: TemplateSms[];
}

export interface TemplateSms {
    number: string,
    data: string[],
    msg: string,
    isAvailable: boolean,
}

export interface NumbUtilResult {
    count: number;
    numb: string;
}
