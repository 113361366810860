import React, {useEffect, useState} from "react";
import classNamesBind from "classnames/bind";
import styles from './MngUserViewApiKey.module.scss'
import {IconButton, Switch, Tooltip} from "@material-ui/core";
import {UpdateUserPermission, UserPermission} from "../../../../graphql/types";
import {Edit, FileCopyOutlined} from "@material-ui/icons";
import NumberFormat from "react-number-format";
import {CopyUtil} from "../../../../utils/copy/CopyUtil";
import copy from 'copy-to-clipboard';
import {OnVoidFunction} from "../../../shares/interfaces/interfaces";
import {useSelector} from "react-redux";
import {LayoutState} from "../../../../redux/Layout";
import ScrollArea from "react-scrollbar";

const cx = classNamesBind.bind(styles);

export const MngUserViewApiKey = (props: {
    userPermission: UserPermission[],
    onCreateApiKey: OnVoidFunction,
    onUpdateUserPermissionActivate: (apiKey: string, swt: boolean, test: boolean) => void,
    onUpdateUserPermission: (input: UpdateUserPermission) => void,
    onClickApiPermission: (value: UserPermission) => void,
}) => {
    const [updateApiKeyList, setUpdateApiKeyList] = useState<UpdateUserPermission[]>([]);

    const [scrollAreaHeight, setScrollAreaHeight] = useState<any>();
    const [scrollAreaWidth, setScrollAreaWidth] = useState<any>();
    const [scrollAreaContent, setScrollAreaContent] = useState<any>();

    const layout = useSelector<any, LayoutState>(state => state.layout);

    useEffect(() => {
        if (layout.viewMode === 0) {
            setScrollAreaHeight("auto");
            setScrollAreaWidth("auto");
            setScrollAreaContent("auto");
        } else {
            setScrollAreaHeight(285);
            setScrollAreaWidth(315);
            setScrollAreaContent(styles.scrollTableWidthForMobile);
        }
    }, [layout.viewMode]);

    useEffect(() => {
        const list: UpdateUserPermission[] = [];
        for (let userPermission of props.userPermission) {
            list.push({
                apiKey: userPermission.apiKey,
                nm: userPermission.nm,
                smsFee: userPermission.smsFee,
            })
        }
        setUpdateApiKeyList(list);
    }, [props.userPermission])

    const onUpdateUserPermission = (index: number) => {
        props.onUpdateUserPermission(updateApiKeyList[index]);
    }

    return (
        <>
            <ScrollArea
                style={{height: scrollAreaHeight, width: scrollAreaWidth}}
                contentStyle={{width: scrollAreaContent}}
                smoothScrolling={true}
            >
                <div className={cx('table')}>
                    <div className={cx('header', "activateCell")}>활성화</div>
                    <div className={cx('header', "testCell", "hiddenCell")} style={{width: 100}}>테스트</div>
                    <div className={cx('header', "apiCell", "hiddenCell")}>API Key</div>
                    <div className={cx('header', "distributionCell")}>분배</div>
                    <div className={cx('header', "nameCell")}>이름</div>
                    <div className={cx('header', "priceCell")}>가격</div>
                    <div className={cx('header', "funcCell")}>기능</div>
                </div>

                {props.userPermission.length === 0 &&
                <div className={cx('table')}>
                    <div style={{width: '100%'}}>API 키가 없습니다.</div>
                </div>
                }

                {updateApiKeyList.length !== 0 && props.userPermission.map((value, index) => {
                    return (
                        <div key={index} className={cx('table', 'hover')}>
                            <div className={cx("col", "activateCell")}>
                                <Switch
                                    checked={value.isActivate}
                                    onChange={(event, checked) => {
                                        event.preventDefault();
                                        props.onUpdateUserPermissionActivate(value.apiKey, checked, value.testSend);
                                    }}
                                    color={value.isActivate ? 'primary' : 'secondary'}
                                />
                            </div>
                            <div className={cx("col", "testCell", "hiddenCell")}>
                                <Switch
                                    checked={value.testSend}
                                    onChange={(event, checked) => {
                                        event.preventDefault();
                                        props.onUpdateUserPermissionActivate(value.apiKey, value.isActivate, checked);
                                    }}
                                    color={value.isActivate ? 'primary' : 'secondary'}
                                />
                            </div>
                            <div className={cx("col", "apiCell", "hiddenCell")}>
                                {/*{value.apiKey}*/}
                                <IconButton
                                    color={'secondary'}
                                    style={{marginLeft: 10}}
                                    size='small'
                                    onClick={() => {
                                        copy(value.apiKey);
                                    }}>
                                    <FileCopyOutlined
                                        style={{marginLeft: 10}}/> 클립보드복사
                                </IconButton>
                            </div>
                            <div className={cx("col", "distributionCell")}>
                                {value.smsLine.length} 개

                                <Tooltip title={'수정하기'}>
                                    <IconButton
                                        color={'secondary'}
                                        style={{marginLeft: 10}}
                                        size='small'
                                        onClick={() => {
                                            props.onClickApiPermission(value)
                                        }}>
                                        <Edit/>
                                    </IconButton>
                                </Tooltip>
                            </div>

                            <div className={cx("col", "nameCell")}>
                                <input
                                    value={updateApiKeyList[index].nm}
                                    className='form-control form-control-sm'
                                    onChange={event => {
                                        const copy = CopyUtil.copyAll(updateApiKeyList);
                                        copy[index].nm = event.target.value;
                                        setUpdateApiKeyList(copy);
                                    }}/>
                            </div>
                            <div className={cx("col", "priceCell")}>
                                <NumberFormat
                                    displayType='input'
                                    decimalScale={1}
                                    allowNegative={false}
                                    value={updateApiKeyList[index].smsFee}
                                    className='form-control form-control-sm text-right'
                                    onValueChange={value => {
                                        const copy = CopyUtil.copyAll(updateApiKeyList);
                                        copy[index].smsFee = value.floatValue || 0;
                                        setUpdateApiKeyList(copy);
                                    }}/>
                            </div>

                            <div className={cx("col", "funcCell")}>
                                <button
                                    className='btn btn-sm btn-outline-success'
                                    style={{width: '100%'}}
                                    onClick={() => {
                                        onUpdateUserPermission(index);
                                    }}>
                                    저장
                                </button>
                            </div>

                        </div>
                    )
                })}
            </ScrollArea>

            <div className={cx('table')}>
                <div className='text-right' style={{width: '100%'}}>
                    <button
                        className='btn btn-sm btn-success'
                        onClick={props.onCreateApiKey}>생성
                    </button>
                </div>
            </div>
        </>
    )
}

