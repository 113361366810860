import {createActionCreators, createReducerFunction, ImmerReducer} from "immer-reducer";
import {LocalStorage} from "../utils/localstorage/LocalStorage";
import {I18nUtil} from "../utils/i18n/i18n";


export interface I18nState {
    langCode: string,
    langList: {
        code: string,
        nm: string,
        flag: string
    }[]
}

const initState: I18nState = {
    langCode: LocalStorage.getLangCode(),
    langList: [
        {code: 'en', nm: 'English', flag: 'flag-icon-us'},
        {code: 'ko', nm: 'Korean', flag: 'flag-icon-kr'},

    ]
};

class I18n extends ImmerReducer<typeof initState> {
    setLangCode(langCode: string) {
        this.draftState.langCode = langCode;
        LocalStorage.setLangCode(langCode);
        I18nUtil.chgLang(langCode);
    }
}


export const I18nAction = createActionCreators(I18n);
export const I18nReducer = createReducerFunction(I18n, initState);

