import withReactContent from "sweetalert2-react-content";
import Swal, {SweetAlertIcon} from "sweetalert2";
import {CryptoUtil} from "../crypto/cryptoUtil";
import {GraphQLError} from "graphql";
import {BizErrors} from "../../graphql/errors";

export class SwalUtil {
    private static swal = withReactContent(Swal);

    static yn(param: {
        msg: string, icon: SweetAlertIcon, ok?: () => void, cancel?: () => void,
        text?: string,
    }) {
        this.swal.fire({
            title: param.msg,
            text: param.text,
            icon: param.icon,
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: '확인',
            cancelButtonText: '취소'
        }).then(value => {
            if (value.value) {
                if (param.ok) {
                    param.ok();
                }
            } else {
                if (param.cancel) {
                    param.cancel();
                }
            }
        });
    }

    static ok(param: {
        msg: string,
        icon: SweetAlertIcon,
        ok?: () => void
    }) {
        this.swal.fire({
            title: param.msg,
            icon: param.icon,
            confirmButtonText: '확인',
        }).then(value => {
            if (value.value) {
                if (param.ok) {
                    param.ok();
                }
            }
        });
    }

    static pw(params: {
        ok: (pw: string) => void
    }) {
        this.swal.fire({
            title: '2차 비밀번호를 입력하여 주십시오',
            input: 'password',
            inputPlaceholder: '비밀번호',
            inputAttributes: {
                autocapitalize: 'off',
                autocorrect: 'off'
            },
            backdrop: false,
            showCancelButton: true
        }).then(value => {
            if (value.dismiss !== Swal.DismissReason.cancel) {
                const password = value.value;
                if (password) {
                    const pw = CryptoUtil.encrypt(String(password));
                    params.ok(pw);
                } else {
                    this.ok({msg: '비밀번호를 입력하여 주십시오.', icon: 'error'});
                }
            }
        });
    }

    static error(
        ok?: () => void
    ) {
        this.swal.fire({
            title: '실패하였습니다. 다시 시도하여 주십시오.',
            icon: 'error',
            confirmButtonText: '확인',
        }).then(value => {
            if (value.value) {
                if (ok) {
                    ok();
                }
            }
        });
    }

    static bizError(): () => void {
        return () => {
            this.swal.fire({
                text: "다시 시도 하여 주십시오.",
                icon: "error"
            }).then();
        }
    }

    static bizErrorMsg(params: {
        msg: string,
        icon: SweetAlertIcon
    }): () => void {
        return () => {
            this.ok(params);
        }
    }

    static otp(ok: (otp: string) => void) {
        this.swal.fire({
            title: 'OTP 번호를 입력하여 주십시오.',
            input: 'text',
            inputPlaceholder: 'OTP',
            inputAttributes: {
                autocapitalize: 'off',
                autocorrect: 'off'
            },
            backdrop: false,
            showCancelButton: true
        }).then(value => {
            if (value.dismiss !== Swal.DismissReason.cancel) {
                const password = value.value as string;
                if (password) {
                    ok(password);
                } else {
                    this.ok({msg: 'OTP 를 입력하여 주십시오.', icon: 'error'});
                }
            }
        });
    }

    static ynPromise<T>(param: {
        msg?: string,
        text?: string,
        value?: T
    }): Promise<T | undefined> {
        return this.swal.fire({
            title: param.msg,
            text: param.text,
            icon: "question",
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: '확인',
            cancelButtonText: '취소'
        }).then(res => {
            if (res.isConfirmed) {
                return param.value;
            } else {
                throw new GraphQLError(BizErrors.swalCancel)
            }
        })
    }

}
