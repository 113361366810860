import React, {useState} from 'react';
import {gql, useMutation, useQuery} from "@apollo/client";
import {BizTitle, Title} from "../../shares/title/Title";
import {FadeInContainer} from '../../shares/fadeInContainer/FadeInContainer';
import {BizCol12} from '../../shares/bizCol12/BizCol12';
import {TelBookSummaryInputUserInfo} from "./inputUserInfo/TelBookSummaryInputUserInfo";
import {TelBookSummaryExtractStatus} from "./extractStatus/TelBookSummaryExtractStatus";
import {TelBookSummaryList} from "./list/TelBookSummaryList";
import {
    Mutation,
    MutationEsFindUserIdWithEmailArgs,
    MutationRequestTelBookSummaryWithUserIdAndPeriodArgs, Period,
    Query,
    QueryEsApiRequestListArgs
} from "../../../graphql/types";
import moment from "moment";
import {SwalUtil} from "../../../utils/swal/swalUtil";
import {ApolloCatch} from "../../../utils/apollo/apolloExec";
import {BizErrors} from "../../../graphql/errors";
import {Pipe} from "../../../utils/pipe/pipe";
import {Pager} from "../../shares/pager/Pager";
import {navigate} from "hookrouter";
import {ReceiverApiTransferStatus} from "./receiverApiTransferStatus/ReceiverApiTransferStatus";

export const TelBookSummary = (props: {
    page: number
}) => {
    const {data, loading, error, refetch} = useQuery<Query, QueryEsApiRequestListArgs>(gqlData, {
        pollInterval: 5000,
        variables: {
            page: props.page,
            size: 10,
        }
    });

    const [requestWithUserIdAndPeriodInput, setRequestWithUserIdAndPeriodInput] = useState<RequestInputValue>({
        email: "",
        period: {
            stDate: new Date(),
            edDate: new Date(),
        },
        userId: 0
    })

    const [mutFindUserIdWithEamil]
        = useMutation<Mutation, MutationEsFindUserIdWithEmailArgs>(gqlFindUserEmail);
    const [mutRequestUserIdAndPeriod]
        = useMutation<Mutation, MutationRequestTelBookSummaryWithUserIdAndPeriodArgs>(gqlRequestWithUserIdAndPeriod);

    const handler = {
        onChangeRequestWithUserIdAndPeriodInput: (value: RequestInputValue) => {
            if (requestWithUserIdAndPeriodInput.email !== value.email) {
                value.userId = undefined;
            }
            setRequestWithUserIdAndPeriodInput(value);
        },
        onFindUserId: () => {
            if (requestWithUserIdAndPeriodInput.email == "") {
                SwalUtil.ok({
                    msg: '이메일을 입력하여 주십시오.',
                    icon: 'warning'
                });
                return;
            }

            mutFindUserIdWithEamil({
                variables: {
                    email: requestWithUserIdAndPeriodInput.email
                }
            }).then(res => {
                if (res.data?.esFindUserIdWithEmail) {
                    setRequestWithUserIdAndPeriodInput({
                        ...requestWithUserIdAndPeriodInput,
                        userId: res.data.esFindUserIdWithEmail
                    })
                }
            }).catch(ApolloCatch({
                [BizErrors.notFoundUser]: () => {
                    SwalUtil.ok({
                        msg: '유저가 없습니다.',
                        icon: 'warning'
                    });
                    setRequestWithUserIdAndPeriodInput({
                        ...requestWithUserIdAndPeriodInput,
                        userId: undefined,
                    })
                }
            }))
        },
        onRequestWithUserIdAndPeriod: () => {
            if (!requestWithUserIdAndPeriodInput.userId) {
                SwalUtil.ok({
                    msg: '이메일 찾기를 실행하여 주십시오.',
                    icon: 'warning'
                });
                return;
            }

            SwalUtil.ynPromise({
                msg: '추출 하시겠습니까?',
            }).then(() => {
                return mutRequestUserIdAndPeriod({
                    variables: {
                        userId: requestWithUserIdAndPeriodInput.userId!,
                        stDate: Number(Pipe.toDayString(requestWithUserIdAndPeriodInput.period.stDate)),
                        edDate: Number(Pipe.toDayString(requestWithUserIdAndPeriodInput.period.edDate)),
                    }
                })
            }).then(() => {
                SwalUtil.ok({
                    msg: '요청 되었습니다.',
                    icon: 'success'
                });
                return refetch()
            }).catch(ApolloCatch({}));

        },
        onChangePage: (p: number) =>{
            navigate(`/telbook/summary/${p}`)
        }

    };

    return (
        <>
            {data &&
            <FadeInContainer>
                <BizCol12>
                    <Title>추출요청</Title>
                    <TelBookSummaryInputUserInfo
                        {...handler}
                        value={requestWithUserIdAndPeriodInput}
                        onChangeValue={handler.onChangeRequestWithUserIdAndPeriodInput}
                    />
                </BizCol12>

                <BizCol12>
                    <BizTitle title='파일저장소로 전화번호 백업'/>
                    <ReceiverApiTransferStatus
                        status={data.receiverTransferStatus}/>
                </BizCol12>

                {data.esApiStatus.requestStatus &&
                <BizCol12>
                    <Title>추출상태</Title>
                    <TelBookSummaryExtractStatus
                        requestStatus={data.esApiStatus.requestStatus}
                    />
                </BizCol12>
                }

                <BizCol12>
                    <Title>전화번호부</Title>
                    <TelBookSummaryList
                        list={data.esApiRequestList.list}
                    />
                    <Pager
                        page={data.esApiRequestList.page}
                        size={data.esApiRequestList.size}
                        total={data.esApiRequestList.total}
                        onChange={handler.onChangePage}/>
                </BizCol12>
            </FadeInContainer>
            }
        </>
    )
}

const gqlData = gql`
    query DATA($page: Int!, $size: Int!) {
        esApiStatus {
            isProcessing
            requestStatus {
                requestId
                requestAt
                deduplicating {
                    total
                    done
                }
                sorting {
                    total
                    done
                }
                extracting {
                    total
                    done
                }
            }
        }
        esApiRequestList(page: $page, size: $size) {
            page
            size
            total
            list {
                requestId
                requestAt
                options
                filePath
            }
        }
        receiverTransferStatus {
            requestTimeDay
            doneSmsRequest
            totalSmsRequest
            isProcessing
            lastUpdatedTimeDay
        }
    }
`;

export interface RequestInputValue {
    userId?: number;
    email: string;
    period: Period;
}

const gqlFindUserEmail = gql`
    mutation FindUserId($email: String!) {
        esFindUserIdWithEmail(email: $email)
    }
`;

const gqlRequestWithUserIdAndPeriod = gql`
    mutation Request($userId: Int!, $stDate: Int!, $edDate: Int!) {
        requestTelBookSummaryWithUserIdAndPeriod(userId: $userId, stDate: $stDate, edDate: $edDate)
    }
`;
