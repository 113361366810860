import React, {Fragment, useEffect, useState} from 'react';
import classNamesBind from "classnames/bind";
import styles from './LayoutNavTop.module.scss';
import {DirectionsRun, Menu, Notifications, PowerSettingsNew} from "@material-ui/icons";
import {AppBar, Badge, Button, IconButton, Menu as MenuButton, MenuItem, Toolbar, Tooltip} from "@material-ui/core";
import {SwalUtil} from "../../../../utils/swal/swalUtil";
import {useDispatch, useSelector} from "react-redux";
import {LayoutAction, LayoutState, ViewMode} from "../../../../redux/Layout";
import {navigate} from "hookrouter";
import {Sidebar} from "../../../App";
import {useTranslation} from "react-i18next";
import {FB} from "../../../../utils/fb/FB";
import {AlertState} from "../../../../redux/Alert";
import soundAlert from '../../../../assets/mp3/alert.mp3';


const cx = classNamesBind.bind(styles);

export const LayoutNavTop = (props: {
    navWidth: number | string;
}) => {
    const [btnAnchor, setBtnAnchor] = useState<any>(null);
    const [btnLangShow, setBtnLangShow] = useState(false);
    const [t] = useTranslation('common');
    const layout = useSelector<any, LayoutState>(state => state.layout);
    const alert = useSelector<any, AlertState>(state => state.alert);
    const [prevAlert, setPrevAlert] = useState<AlertState>(alert);
    const menu = Sidebar[layout.sidebarIndex].topNav;
    const dispatch = useDispatch();

    const logout = () => {
        SwalUtil.yn({
            msg: '로그아웃 하시겠습니까?',
            icon: "question",
            ok: () => {
                FB.logout().then();
            }
        })
    }

    useEffect(() => {
        let hasNewAlert = false;
        for (let delayedSmsLine of alert.delayedSmsLine) {
            const existAlert = prevAlert.delayedSmsLine.find(value => {
                return delayedSmsLine.reportId === value.reportId
            });

            if (existAlert) {
                continue;
            }

            // 신규 알림 이므로 알림음을 울린다.
            hasNewAlert = true;
            break;
        }
        setPrevAlert(alert);

        if (hasNewAlert) {
            const beep = new Audio(soundAlert);
            beep.play().then();

        }
    }, [alert])

    return (
        <Fragment>
            <AppBar position="sticky" color='default'>
                <Toolbar>
                    {menu.map((value, index) => {
                        return (
                            <Tooltip key={index} title={value.title}>
                                <Button
                                    // className={cx('nav-icon', value.router === path ? 'activated' : '')}
                                    onClick={event => {
                                        navigate(value.router);
                                    }}>{value.icon}</Button>
                            </Tooltip>
                        )
                    })}

                    <div className={cx('spacer')}/>
                    <IconButton
                        onClick={event => {
                            setBtnLangShow(true);
                            setBtnAnchor(event.currentTarget);
                        }}>
                        <Badge color='secondary' variant='dot' invisible={alert.delayedSmsLine.length === 0}>
                            <Notifications/>
                        </Badge>

                    </IconButton>
                    <MenuButton
                        keepMounted
                        anchorEl={btnAnchor}
                        open={btnLangShow}
                        onClose={() => {
                            setBtnLangShow(false);
                        }}>
                        <MenuItem disabled>
                            <DirectionsRun/>SMS 통신사 지연시간 알림
                        </MenuItem>

                        {alert.delayedSmsLine.map((value, index) => {
                            return (
                                <MenuItem key={index} onClick={() => {
                                    setBtnLangShow(false);
                                    navigate('/speedTest');
                                }}>
                                    {value.smsLineNm}
                                </MenuItem>
                            )
                        })}
                    </MenuButton>


                    {layout.viewMode === ViewMode.Mobile &&
                    <IconButton edge="end" color="inherit" aria-label="menu"
                                onClick={event => {
                                    dispatch(LayoutAction.setSidebar(true));
                                }}>
                        <Menu/>
                    </IconButton>
                    }

                    {layout.viewMode === ViewMode.Web &&
                    <IconButton edge="end" color="inherit" aria-label="menu"
                                onClick={event => {
                                    logout();
                                }}>
                        <Tooltip title={t("layout.logout") || 'logout'}>
                            <PowerSettingsNew/>
                        </Tooltip>
                    </IconButton>
                    }

                </Toolbar>
            </AppBar>
        </Fragment>
    )
}
